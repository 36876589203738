import {
    Button,
    DialogActions,
    Grid,
    Typography,
    makeStyles,
    useMediaQuery
} from '@material-ui/core'
import React from 'react'

import copyToClipboard from 'copy-to-clipboard'

import AdminModal from '../admin/AdminModal'

import {
    EmailOutlined as SendToIcon,
    FileCopyOutlined as CopyIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ebe8e8',
        minHeight: 300,
        padding: 30,
        [theme.breakpoints.down('xs')]: {
            padding: 15
        }
    },
    pre: {
        whiteSpace: 'pre-wrap'
    },
    emailText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
    buttonContainer: {
        padding: '40px 0px 10px 0px'
    },
    copyButtonContainer: {
        textAlign: 'end'
    },
    copyButton: {
        background: 'linear-gradient(90deg, #eabb35 0%, #f3c74d 60%)',
        color: '#fff',
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '5px 10px'
        }
    },
    emailButtonContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 8
        }
    },
    openEmailButton: {
        background: 'linear-gradient(90deg, #2bbf91 0%, #18e7a6 60%)',
        color: '#fff',
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '5px 10px'
        }
    },
    closeButtonContainer: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 8
        }
    },
    closeButton: {
        background: 'linear-gradient(90deg, #f1902d 0%, #faa248 60%)',
        color: '#fff',
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '5px 10px'
        }
    },
    icon: {
        marginRight: 8
    }
}))

function ActionButtons({ close, emailTextBody }) {
    const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)

    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const onCopyToClipboard = React.useCallback(
        e => {
            e.preventDefault()
            copyToClipboard(emailTextBody)
            setCopiedToClipboard(true)
        },
        [emailTextBody]
    )

    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.copyButtonContainer}
                >
                    <Button
                        onClick={onCopyToClipboard}
                        className={classes.copyButton}
                        fullWidth={isSmallDevice}
                    >
                        <CopyIcon className={classes.icon} fontSize="small" />
                        Copy
                    </Button>
                    {copiedToClipboard && (
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Copied to clipboard!
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.emailButtonContainer}
                >
                    <Button
                        href="mailto://?subject=Your Survey&body=+ encodeURI(emailTextBody)"
                        className={classes.openEmailButton}
                        fullWidth={isSmallDevice}
                    >
                        <SendToIcon className={classes.icon} fontSize="small" />
                        Open My Email
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.closeButtonContainer}
                >
                    <Button
                        onClick={close}
                        className={classes.closeButton}
                        fullWidth={isSmallDevice}
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function SendLinkModal(props, ref) {
    const [open, setOpen] = React.useState(false)
    const [spid, setSpid] = React.useState('')
    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: id => {
            setSpid(id)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    const emailTextBody = `
Welcome,

Please take some time to complete the diversity 
and inclusion survey found here:

${window.origin}/?id=${spid}

Thanks for your time.`

    return (
        <AdminModal
            open={open}
            onClose={close}
            title="You can use this template to send the link to your employee"
        >
            <div className={classes.root}>
                <pre className={classes.pre}>
                    <Typography variant="body1" className={classes.emailText}>
                        {emailTextBody.trim()}
                    </Typography>
                </pre>
            </div>

            <ActionButtons emailTextBody={emailTextBody} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(SendLinkModal)
