import React from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

// Resolves charts dependency
charts(FusionCharts);

const ColumnChart = ({ data }) => {
  const response = { ...data[0] };

  const transformedData = response?.labels.map((label, index) => ({
    label: label,
    value: response?.data[index].toFixed(2), // Rounding to 2 decimal places
    color: response?.colorPallete[0]
  }));

  const dataSource = {
    chart: {
      theme: "fusion",
      showvalues: "0",
      plottooltext: "",
      showlabels: "1",
      xaxisname: response.xAxisName,
      showplotborder: "0",
      maxvalue: "50",
    },
    data: transformedData,
    trendlines: [
      {
        line: response?.trendLines?.map((t) => ({
          startvalue: t.value,
          color: t.color,
          thickness: "1.5",
        })),
      },
    ],
    annotations: {
      groups: response?.trendLines?.map((t, index) => (
        {

          items: [
            {
              type: "text",
              id: `text-annotation-${index}`,
              text: `${t.title}: ${t.value}`,
              x: "$canvasStartX + 50", // Adjust the x-coordinate as needed
              y: `$canvasStartY + ${index * t.value + 13}`, // Adjust the y-coordinate as needed
              fontsize: "16",
              fontcolor: "black",
              align: "center",
            },
          ],
        })),
    },
  };

  return (
    <div style={{ width: '100%', height: '280px' }}>
      <ReactFusioncharts
        type="column2d"
        width="100%"
        height="280"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default ColumnChart;
