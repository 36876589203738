import { all, fork } from "redux-saga/effects";
import { watchFetchBranding } from './brandingSaga'
import { watchFetchTenantStats } from './tenantStatsSaga'

const rootSaga = function* () {
    yield all([
        fork(watchFetchBranding),
        fork(watchFetchTenantStats),
    ]);
};

export default rootSaga;
