import {
    Grid,
    Button,
    Switch,
    makeStyles,
    CircularProgress,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { send_command } from '../../components/api'
import {
    AddUserModal,
    EditUserModal,
    UserInfoModal
} from '../../components/admin'

import {
    useDeleteUserMutation,
    useEnableUserMutation,
} from 'components/api'


import {
    CreateOutlined as EditIcon,
    DeleteOutlined as DeleteIcon,
    PersonAddOutlined as CreateNewIcon,
    VisibilityOutlined as ViewIcon,
    Check
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    titleContainer: {
        paddingBottom: 24
    },
    addButtonContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'start',
            marginTop: 8
        }
    },
    button: {
        marginRight: 4,
        color: '#fff'
    },
    icon: {
        marginRight: 8
    }
}))


function setUserEnabledInQueryData(queryClient, user, enabled) {
    const userData = queryClient.getQueryData(['user-data'])

    const record = userData.find(x => x.sub === user.sub)

    if (record) {
        record.Enabled = enabled
    }

    queryClient.setQueryData(['user-data'], userData)
}


function ActionFormatter({ value }) {
    const editUserDialogRef = React.useRef()
    const { mutateAsync: deleteUserAsync } = useDeleteUserMutation()
    const queryClient = useQueryClient()

    const onDeleteUser = React.useCallback(
        async e => {
            e.preventDefault()

            if (!window.confirm('Are you sure you want to delete?')) {
                return
            }
            try {
                await deleteUserAsync({ email: value.email })

                await queryClient.invalidateQueries('user-data')
            } catch (error) {
                throw error
            }
        },
        [value, deleteUserAsync, queryClient]
    )

    const classes = useStyles()

    return (
        <div>
            <Button
                onClick={() => {
                    editUserDialogRef.current.open(value)
                }}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <EditIcon fontSize="small" />
            </Button>
            <Button
                onClick={onDeleteUser}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={value.Enabled}
            >
                <DeleteIcon fontSize="small" />
            </Button>

            <EditUserModal ref={editUserDialogRef} />
        </div>
    )
}

function UserInfo({ user }) {
    const viewUserDialogRef = React.useRef()
    const classes = useStyles()
    return (
        <div>
            <Button
                onClick={() => viewUserDialogRef.current.open(user)}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <ViewIcon fontSize='small' />
            </Button>
            <UserInfoModal ref={viewUserDialogRef} />
        </div>
    )
}

export default function ManageUsers({ tenant_id }) {
    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([])

    //const { data: tenantData, isLoading: tenantLoading } = useTenantQuery()
    const addUserDialogRef = React.useRef()
    const SwitchFormatter = ({ value }) => {
        const queryClient = useQueryClient()
    
        const { mutateAsync: enableUserAsync, isLoading } = useEnableUserMutation()
    
        const onChangeEnabled = React.useCallback(
            async e => {
                e.stopPropagation()
                e.preventDefault()
    
                const enabled = !e.target.checked
    
                enableUserAsync({
                    email: value.email,
                    enabled
                }).then(() => {
                    queryClient.invalidateQueries('user-data')
                    fetch()
                }).catch((er) => alert("failed to set user enabled/disabled"))
            },
            [queryClient, value, enableUserAsync]
        )
    
        return (
            <Grid container>
                <Switch
                    checked={value.Enabled}
                    onClick={onChangeEnabled}
                    size="small"
                    disabled={isLoading}
                />
            </Grid>
        )
    }
    const columnData = [
        {
            field: 'given_name', headerName: 'Name', flex: 8, sortable: true,
            renderCell: ({ row }) => <>{row['family_name']}, {row['given_name']}</>
        },
        { field: 'email', headerName: 'Email', flex: 15, sortable: true },
        { field: 'tenant_name', headerName: 'Tenant', flex: 10, sortle: true },
        {
            field: 'isAdmin', headerName: 'Admin', flex: 5, sortable: true,
            renderCell: ({ row }) => <>{row['custom:is_admin'] && <Check />}</>
        },
        {
            field: 'isSuperAdmin', headerName: 'Super Admin', flex: 5, sortable: true,
            renderCell: ({ row }) => <>{row['custom:is_super_admin'] && <Check />}</>
        },
        {
            field: 'user_info', headerName: 'User Info', flex: 5, sortable: true,
            renderCell: ({ row }) => <UserInfo user={row} />
        },
        {
            field: 'Enabled', headerName: 'Enabled', flex: 5, sortable: true,
            renderCell: ({ row }) => <SwitchFormatter value={row} />
        },
        {
            field: 'actions', headerName: 'Actions', flex: 10, sort: false,
            renderCell: ({ row }) => <ActionFormatter value={row} />
        }
    ]

    const classes = useStyles()

    const fetch = () => {
        setIsLoading(true)
        if (tenant_id) {
            send_command('ADMIN_LIST_TENANT_USERS', { "tenant_id": tenant_id })
                .then(re => { setUsers(re); setIsLoading(false) })
                .catch(er => { console.log({ er }); setIsLoading(false) })
        } else {
            send_command('ADMIN_LIST_ALL_USERS', {})
                .then(re => { setUsers(re); setIsLoading(false) })
                .catch(er => { console.log({ er }); setIsLoading(false) })
        }
    }
    useEffect(fetch, [])
    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.addButtonContainer}>
                    {isLoading && <CircularProgress />}
                    {!isLoading &&
                        <Button
                            onClick={() => {
                                addUserDialogRef.current.open()
                            }}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                        >
                            <CreateNewIcon fontSize="small" className={classes.icon} />
                            New User
                        </Button>}
                </Grid>
                <Grid item xs={12}>
                    <div>                        
                        <DataGrid
                            autoHeight={true}
                            rows={users}
                            columns={columnData}
                            hideFooterSelectedRowCount={true}
                            getRowId={row => row.Username}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                        />
                    </div>
                </Grid>
            </Grid>
            <AddUserModal ref={addUserDialogRef} />
        </>
    )
}
