import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import {
    useSpidTableQuery,
    useUserTableQuery,
    useVendorTableQuery
} from 'components/api'

import { BackdropProgress } from '../../components'

import {
    ShoppingCartOutlined as VendorIcon,
    PersonOutlineOutlined as UserIcon,
    Extension as IdIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    statsContainer: {
        alignSelf: 'center'
    },
    iconContainer: {
        alignSelf: 'center'
    }
}))

export default function Statistics() {
    const { data: userData, isLoading: userLoading } = useUserTableQuery()
    const { data: spidData, isLoading: spidLoading } = useSpidTableQuery()
    const { data: vendorData, isLoading: vendorLoading } = useVendorTableQuery()
    const classes = useStyles()

    if (userLoading || spidLoading || vendorLoading) {
        return <BackdropProgress />
    }

    return (
        <Grid container>
            <Grid item container xs={6}>
                <Grid item container xs={12} md={4} alignContent="flex-start">
                    <Grid item xs={6} className={classes.iconContainer}>
                        <VendorIcon fontSize="large" color="primary" />
                    </Grid>
                    <Grid item xs={6} className={classes.statsContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {vendorData.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            Vendors
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={4} alignContent="flex-start">
                    <Grid item xs={6} className={classes.iconContainer}>
                        <IdIcon fontSize="large" color="secondary" />
                    </Grid>
                    <Grid item xs={6} className={classes.statsContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {spidData.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            IDs
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={4} alignContent="flex-start">
                    <Grid item xs={6} className={classes.iconContainer}>
                        <UserIcon fontSize="large" color="secondary" />
                    </Grid>
                    <Grid item xs={6} className={classes.statsContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {userData.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            Users
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
