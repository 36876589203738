import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import logo from './assets/logo.png'
import logoCondensed from './assets/logo-condensed.png'

const useStyles = makeStyles({
    logo: {
        maxWidth: '100%'
    },
    logoCondensed: {
        maxWidth: 112
    }
})

export default function Logo({ brand, condensed = false }) {
    const classes = useStyles()

    if (brand) {
        return (
            <img
                src={condensed ? brand.logo_small : brand.logo}
                alt={brand.alt}
                className={clsx({
                    [classes.logo]: true,
                    [classes.logoCondensed]: condensed
                })}
            />
        )
    }

    return (
        <img
            src={condensed ? logoCondensed : logo}
            alt="DataProtected by SafePorter"
            className={clsx({
                [classes.logo]: true,
                [classes.logoCondensed]: condensed
            })}
        />
    )
}
