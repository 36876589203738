import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    brandingData: {},
    brandingError: null,
    isLoadingBranding: false,
}

export const brandingSlice = createSlice({
    name: 'branding',
    initialState,
    reducers: {
        fetchBranding: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoadingBranding = true;
        },
        fetchBrandingSuccess: (state, action) => {
            return {
                ...state,
                isLoadingBranding: false,
                brandingData: action.payload,
            }
        },
        fetchBrandingFailed: (state, action) => {
            return {
                ...state,
                isLoadingBranding: false,
                brandingError: action.error,
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { fetchBranding, fetchBrandingSuccess, fetchBrandingFailed } = brandingSlice.actions

export default brandingSlice.reducer
