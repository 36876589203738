import React from 'react'
import ChartLabels from './ChartLables'
import styles from '../../assets/scss/app.module.scss'
import { GridChart } from 'assets/diversityStyle'
import { Grid } from '@material-ui/core'
import FusionChart from './FusionChart'

// Resolves charts dependency

const StackBarChart = ({
    data,
    dataKey,
    setPosition,
    position,
    drilldownsHierarchy
}) => {
    const dataArray = []
    const response = data
    const graphData = Object.values(response).map(item => {
        return item
    })

    graphData.map(item => {
        Object.keys(item).map(d => {
            if (dataKey === d) {
                dataArray.push(item[d])

                if (!position[dataKey] && item[d]?.insights?.length > 0) {
                    setPosition({
                        ...position,
                        [dataKey]: 4
                    })
                }
            }
        })
        return item
    })

    // Transform data to FusionCharts format
    const seriesData = []
    const seriesDataCopy = []

    let maxLabels;
    let combinedLabels = [];
    let maxLabelsLength = 0;

    graphData.forEach(element => {
        Object.keys(element).map(key => {
            if (key === dataKey) {
                combinedLabels = [...combinedLabels, ...element[key].labels];
                if (element[key].labels.length > maxLabelsLength) {
                    maxLabelsLength = element[key].labels.length;
                    maxLabels = element[key].labels;
                }
            }
        })
        let additionalItems = combinedLabels.filter(item => !maxLabels.includes(item));
        additionalItems = [...new Set(additionalItems)]
        combinedLabels = [...maxLabels, ...additionalItems]
    })

    for (let i = 0; i < graphData.length; i++) {
        for (let j = 0; j < combinedLabels.length; j++) {
            const ind = graphData[i][dataKey].labels.findIndex(l => l === combinedLabels[j])

            const itemIndex = seriesDataCopy.findIndex(e => e.seriesname === combinedLabels[j])
            if (itemIndex > -1) {
                seriesDataCopy[itemIndex].data.push({
                    value: ind === -1 ? 0 : graphData[i][dataKey]?.data[ind],
                })
                if (!seriesDataCopy[itemIndex].color) {
                    seriesDataCopy[itemIndex].color = graphData[i][dataKey]?.palette[ind];
                }
            } else {
                seriesDataCopy.push({
                    seriesname: combinedLabels[j],
                    color: graphData[i][dataKey]?.palette[ind],
                    data: [{ value: ind === -1 ? 0 : graphData[i][dataKey]?.data[ind] }],
                    label: combinedLabels[j]
                })
            }
        }
    }


    graphData.forEach(element => {
        Object.keys(element).map(key => {
            if (key === dataKey) {
                element[key].labels.map((label, index) => {
                    const itemIndex = seriesData.findIndex(
                        e => e.seriesname === label
                    )
                    if (itemIndex > -1) {
                        seriesData[itemIndex].data.push({
                            value: element[key].data[index]
                        })
                    } else {
                        seriesData.push({
                            seriesname: label,
                            color: element[key].palette[index],
                            data: [{ value: element[key].data[index] }],
                            label: label
                        })
                    }
                })
            }
        })
    })

    const categories = {
        category: Object.keys(data).map(key => {
            const region = drilldownsHierarchy.find(item => item.key === key)
            if (region) {
                return { label: region.label.toUpperCase() }
            } else {
                const country = drilldownsHierarchy
                    .flatMap(region => region.children)
                    .find(country => country.key === key)
                if (country) {
                    return { label: country.label.toUpperCase() }
                }
            }
        })
    }

    // Create the dataSource object for the grouped column chart
    const dataSource = {
        chart: {
            numvisibleplot: '8',
            plottooltext: '<b>$dataValue%</b>  $seriesName',
            theme: 'candy',
            showvalues: '0',
            theme: 'fusion',
            chartLeftMargin: '20',
            chartRightMargin: '20',
            chartTopMargin: '20',
            chartBottomMargin: '20',
            showBorder: '0',
            showCanvasBorder: '0',
            labelWidth: '100',
            seriesname: '0',
            showLegend: '0',
            labelFontBold: '1'
        },
        categories: [categories],
        dataset: seriesDataCopy
    }

    return (
        <GridChart container alignItems="center" spacing={3}>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                // lg={position[dataKey] ? 12 : 6}
                lg={6}
            >
                <FusionChart
                    type="scrollstackedbar2d"
                    width="100%" // Adjust the width of the chart
                    height="200"
                    dataFormat="JSON"
                    dataSource={dataSource}
                />
            </Grid>

            <ChartLabels
                key={1}
                response={seriesDataCopy}
                styles={styles}
                page="stack_bar"
                position={position}
                dataKey={dataKey}
            />
        </GridChart>
    )
}

export default StackBarChart
