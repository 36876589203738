import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Chart from './Chart'
import ChartCard from './ChartCard'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    divider: {
        height: 500,
        margin: '50px 0',
        borderLeft: ({ verticalDividerColor }) =>
            `6px solid ${verticalDividerColor}`,
        [theme.breakpoints.down('sm')]: {
            borderLeft: 'none'
        }
    },
    card: {
        marginLeft: 18,
        height: 700,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    }
}))

export default function InclusionInsightsChart({ data }) {
    const classes = useStyles({ verticalDividerColor: data.verticalDivider })
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                decimals: '0',
                paletteColors: data.palette
            },
            categories: [
                {
                    category: data.labels.map(label => ({ label }))
                }
            ],
            dataset: data.data.map(x => ({
                seriesname: x.name,
                data: x.data.map(value => ({ value }))
            }))
        }
        return (
            <div className={classes.root}>
                <div className={classes.divider} />
                <ChartCard classes={{ root: classes.card }}>
                    <Chart type="stackedbar2d" dataSource={dataSource} />
                </ChartCard>
            </div>
        )
    }, [data])
}
