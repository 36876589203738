import { List, ListItem, makeStyles, Button, Box, Card, CardActions, CardContent, CardMedia, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, IconButton, Typography, Snackbar, CardHeader } from '@material-ui/core'
import { TabPanel, TabContext } from '@material-ui/lab'

import { Alert, AlertTitle } from "@material-ui/lab"
import { adminListTenants, adminWorkWithTenant, ADMIN_TENANT_GET, send_command } from '../../components/api'
import { CloseCircleOutlined } from "@ant-design/icons"
import { useAuthentication } from '../../components/AuthenticationContext'
import { BackdropProgress } from '../../components'
import React from 'react'
import { useState } from 'react'
import SpidsPage from './Spids'
import { TenantDashboard } from './TenantDashboard'
import { SendCommand } from './SendCommand'
import { Receivers } from './Receivers'
import { Field, Form, Formik } from 'formik'
import { Stack } from '@mui/system'
import { UserManager } from './UserManager'
import ManageUsers from './ManageUsers'
const useStyles = makeStyles(theme => ({
    tab: {
        padding: 0
    },
    tabContainer: {
        paddingRight: 24
    },
    hoverTab: {
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    activeTab: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}))
const menuItems = [
    'Overview',
    'Receivers',
    'Tenants',
    'Users'
]


const Overviewx = () => {
    const authState = useAuthentication()
    const [loading, setLoading] = useState(false)
    const [showSpids, setShowSpids] = useState(false)
    const [showCommands, setShowCommands] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()

    const [tenants, setTenants] = useState([])
    const [environment, setEnvironment] = useState([])
    const subjectTenantid = authState.attributes['custom:tenantid']
    const [tenantId, setTenantId] = useState(null)


    React.useEffect(() => {
        setLoading(true)
        adminListTenants().then(tenants => {
            setTenants(tenants.tenants)
            setLoading(false)
        }).catch(() => setLoading(false))
        send_command("ADMIN_GET_ENVIRONMENT_DETAILS", {}).then(response =>
            setEnvironment(response)
        ).catch(er =>
            alert(er.message)
        )
    }, [])

    if (loading) {
        return <BackdropProgress />
    } else if (tenantId) {
        return (
            <>
                <TenantDashboard close={() => setTenantId(null)} tenant_id={tenantId} />
            </>
        )
    }


    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setError(null)}
            >
                <CloseCircleOutlined fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const triggerEngagement = () => {
        send_command('RENDER_ENGAGEMENT_TEMP', { 'tenant_id': "3d10c5ec-a743-4308-acde-ef08b720fe1a", 'bucket_name': "signoffrec-surveybucket-1d0x0erhuwx1d" })
            .then(res => setSuccess("That worked"))
            .catch(err => setError(`Nope! ${err}`))
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Typography variant='h5'>Superadmin Dashboard</Typography>
            </Grid>

            <Grid container >
                <Grid item xs={12} style={{ backgroundcolor: "red" }}>
                    <Grid container style={{ padding: "1em" }} >
                        <Card xs={12} elevation={4} style={{ padding: '1em', width: '100%' }} >
                            <Button color="secondary" variant="contained" onClick={() => setShowSpids(true)}>Lookup SPID</Button>&nbsp;
                            <Button color="secondary" variant="contained" onClick={() => setShowCommands(!showCommands)}>Issue Command</Button>&nbsp;
                            <Button color="secondary" variant="contained" onClick={triggerEngagement}>Trigger Engagement</Button>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "1em" }} spacing={2}>
                <Grid item xs={12} md={3}>
                    <Card elevation={2} style={{ padding: "1em" }}>
                        <Grid container>
                            <Grid item sm={12}>
                                <Typography variant="h6" color="textSecondary">                                    
                                    CoreAPI
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CardContent>
                                    <Stack spacing={0.5}>
                                        <Typography>Version: <Chip variant="combined" label={environment['api-version']} size="small" /></Typography>
                                        <Typography>Table Name: <Chip variant="combined" label={environment['db-name']} size="small" /></Typography>
                                        <Typography>PoolID: <Chip variant="combined" label={environment['user-pool-name']} size="small" /></Typography>
                                    </Stack>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            {showCommands && <Box>
                <SendCommand closeHandler={() => setShowCommands(false)} />
            </Box>}
            {/* </Paper> */}
            <Dialog onClose={() => setShowSpids(false)} open={showSpids}>
                <DialogTitle>
                    SPIDs
                </DialogTitle>
                <DialogContent>
                    <SpidsPage></SpidsPage>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => setShowSpids(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={error != null}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setError(null)}
                action={action}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Whoa!</AlertTitle>
                    {error}
                </Alert>

            </Snackbar>
            <Snackbar
                open={success != null}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setSuccess(null)}
                action={action}
            >
                <Alert
                    onClose={() => setSuccess(null)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Yay!</AlertTitle>
                    {error}
                </Alert>

            </Snackbar>
        </>
    )
}


const TenantCard = ({ tenant, current_tenant, user, setTenantId }) => {
    const [showSelect, setShowSelect] = useState(false)
    const hideSelect = () => setShowSelect(false)

    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const selectTenant = () => {
        setShowSelect(true)
    }

    const workWithTenant = () => {
        console.log({ user })
        console.log({ tenant })
        adminWorkWithTenant(tenant.tenant_id, user.username).then(() => {
            user.signOut()
            window.location = "/"
        })

    }

    return (

        <Card style={{
            margin: "1em", padding: "1em", height: "90%",
            display: "flex",
            flexDirection: "column"
        }} elevation={4}>
            <CardActions>
                <Box sx={{ width: "100%" }}>
                    {current_tenant !== tenant.tenant_id &&
                        <Button variant="contained" color="primary" size="small" onClick={() => selectTenant('hi')}>Use</Button>
                    }
                    {current_tenant === tenant.tenant_id &&
                        <Chip label="Current" color="secondary" />
                    }
                    &nbsp;<Button variant="contained" color="primary" size="small" onClick={() => setTenantId(tenant.tenant_id)}>Details</Button>
                    <hr />
                </Box>
            </CardActions>
            <CardMedia
                component="img"
                image={tenant.branding.logo}
                title={tenant.tenant_name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {tenant.tenant_name}
                </Typography>

            </CardContent>

            <Dialog onClose={hideSelect} open={showSelect}>
                <DialogTitle>
                    Change Tenant
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6">Use tenant {tenant.tenant_name}?</Typography>
                        <Typography color="secondary">
                            Warning: You will be logged out so the new tenant claim can take effect.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={hideSelect}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={workWithTenant}>Use</Button>
                </DialogActions>
            </Dialog>
        </Card>

    )
}



export default Overviewx