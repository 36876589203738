import { Grid, Button, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { useQueryClient } from 'react-query'

import {
    AdminTable,
    AddNewSpidModal,
    DeactivateIdModal,
    SendLinkModal
} from '../../components/admin'

import {
    useAddSpidMutation,
    useMarkInvitedSpidMutation,
    useSpidTableQuery
} from 'components/api'
import { BackdropProgress, dateFormatter } from '../../components'

import {
    AddCircleOutline as AddSpidIcon,
    MailOutline as SendToIcon
} from '@material-ui/icons'

const useStyles = makeStyles({
    titleContainer: {
        paddingBottom: 24
    },
    button: {
        color: '#fff'
    },
    icon: {
        marginRight: 8
    }
})

function ActionFormatter({ value }) {
    const { mutateAsync: markInvitedAsync } = useMarkInvitedSpidMutation()
    const queryClient = useQueryClient()
    const sendLinkDialogRef = React.useRef()
    const deactivateDialogRef = React.useRef()

    const onMarkInvited = React.useCallback(
        async e => {
            e.preventDefault()
            try {
                await markInvitedAsync({ sp_id: value.id })

                await queryClient.invalidateQueries('spid-data')
            } catch (error) {
                throw error
            }
        },
        [markInvitedAsync, value, queryClient]
    )

    const classes = useStyles()

    return (
        <Grid container>
            <Grid item xs={4}>
                <Button
                    onClick={() => {
                        sendLinkDialogRef.current.open(value.sp_id)
                    }}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                >
                    <SendToIcon className={classes.icon} fontSize="small" />
                    Send
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={() => {
                        deactivateDialogRef.current.open(value.sp_id)
                    }}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                >
                    Disable
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={onMarkInvited}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                >
                    Mark Invited
                </Button>
            </Grid>
            <SendLinkModal ref={sendLinkDialogRef} />
            <DeactivateIdModal ref={deactivateDialogRef} />
        </Grid>
    )
}

export default function SPID() {
    const { data, isLoading } = useSpidTableQuery()
    const queryClient = useQueryClient()
    const { mutateAsync: newSpidAsync } = useAddSpidMutation()

    const newSpidDialogRef = React.useRef()

    const classes = useStyles()

    const onNewSpid = React.useCallback(
        async e => {
            e.preventDefault()
            try {
                const spid = await newSpidAsync()

                await queryClient.invalidateQueries('spid-data')

                newSpidDialogRef.current.open(spid.sp_id)
            } catch (error) {
                throw error
            }
        },
        [newSpidAsync, queryClient]
    )

    const columnData = [
        { field: 'sp_id', headerName: 'IDs', flex: 1, sortable: false },
        { field: 'state', headerName: 'State', width: 100, sortable: true },
        {
            field: 'created_time',
            headerName: 'Created',
            width: 120,
            sortable: true,
            valueFormatter: params => dateFormatter(params.value)
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 350,
            sortable: false,
            renderCell: ({ row }) => <ActionFormatter value={row} />
        }
    ]

    const defaultSortedBy = [
        {
            field: 'created_time',
            sort: 'desc'
        }
    ]

    if (isLoading) {
        return <BackdropProgress />
    }

    return (
        <Grid container>
            <Grid item container xs={12} className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Data Protected IDs
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={onNewSpid}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                    >
                        <AddSpidIcon
                            fontSize="small"
                            className={classes.icon}
                        />
                        New
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>                
                <AdminTable
                    columnData={columnData}
                    rowData={data}
                    defaultSortedBy={defaultSortedBy}
                />
            </Grid>
            <AddNewSpidModal ref={newSpidDialogRef} />
        </Grid>
    )
}
