import { useEffect, useState } from "react"
import { send_command } from '../../components/api'
import { BackdropProgress } from 'components';

import { Button, Card, CardContent, Typography, Tooltip, CardHeader } from "@material-ui/core"


export const UserPoolClients = () => {
    const [clients, setClients] = useState([])
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const getUserPoolClients = () => {
        setLoading(true)
        setError(null)
        send_command('ADMIN_LIST_USER_POOL_CLIENTS', {})
            .then(res => {
                setClients(res)
                setLoading(false)
            })
            .catch(ex => {
                setError(ex.message)
                console.error({ 'jonah': ex })
                setLoading(false)
            })
    }

    const Client = ({ idp_record }) => {
        const [showSecret, setShowSecret] = useState(false)
        return (<>
            <Card style={{margin: "1em"}}>
                <CardHeader title={idp_record.ClientName}></CardHeader>
                <CardContent>
                    <Typography>
                        UserPoolId: {idp_record?.UserPoolId}
                    </Typography>
                    <Tooltip title={idp_record.UserPoolId} placement="top">
                        <Typography>
                            ClientId: {idp_record.ClientId}
                        </Typography>
                    </Tooltip>
                    {idp_record?.ClientSecret&&
                    <Typography >
                        Client Secret: {showSecret?<span>{idp_record?.ClientSecret}</span>:<span id="secret">************</span>}<button onClick={() => setShowSecret(!showSecret)}>show</button>
                    </Typography>
    }
                </CardContent>
            </Card>


        </>)
    }
    useEffect(()=> {
        getUserPoolClients()
    }, [])
    return (<>
        <div>
            {loading && <BackdropProgress />}
            <Button variant="contained" onClick={getUserPoolClients} >Fetch</Button>
            {clients?.map(client => {
                return (<Client idp_record={client} />)
            })}
            {/* <pre>
                {JSON.stringify(clients, null, 3)}
            </pre> */}
            <pre>
                {error && JSON.stringify(error, null, 3)}
            </pre>
        </div>
    </>)
}