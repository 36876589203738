import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    root: {},
    progress: {
        marginLeft: 8,
        color: theme.palette.info.main
    },
    fullWidth: {
        width: '100%'
    }
}))

export default function ProgressButton({
    type,
    isLoading,
    disabled = false,
    children,
    classes: extraClasses = {},
    className,
    fullWidth,
    onClick,
    color,
    variant
}) {
    const classes = useStyles()
    return (
        <div
            className={clsx(
                classes.root,
                extraClasses.root,
                fullWidth && classes.fullWidth
            )}
        >
            <Button
                type={type}
                fullWidth={fullWidth}
                onClick={onClick}
                className={clsx(extraClasses.button, className)}
                disabled={isLoading || disabled}
                color={color}
                variant={variant}
            >
                {children}
                {isLoading && (
                    <CircularProgress
                        size={24}
                        className={clsx(
                            classes.progress,
                            extraClasses.progress
                        )}
                    />
                )}
            </Button>
        </div>
    )
}

ProgressButton.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
}
