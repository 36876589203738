import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './slices'
import createSagaMiddleware from "@redux-saga/core";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'branding.isLoadingBranding',
        'branding.brandingError',
        'tenantStats.isLoadingTenantStats',
        'tenantStats.tenantStatsError'
    ]
}

const persistedRootReducer = persistReducer(persistConfig, rootReducer)

const storeInstance = configureStore({
    reducer: persistedRootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

export const store = storeInstance
export const persistor = persistStore(storeInstance)
