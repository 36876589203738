import { makeStyles } from '@material-ui/styles'
import React from 'react'
import ReactFC from 'react-fusioncharts'

import { usePrintLayout } from 'components'

function getDimension(key, element) {
    let value

    try {
        value = window.getComputedStyle(element, null).getPropertyValue(key)
    } catch (e) {
        value = element[key]
    }

    return Number(value.replace('px', ''))
}

function getHeight(element) {
    return (
        getDimension('height', element) ||
        getDimension('height', element.parentElement)
    )
}

function getChartDefaultsByType(type) {
    if (type === 'pie2d') {
        return {
            showLegend: 0,
            useDataPlotColorForLabels: 1
        }
    }

    return {}
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
})

export default function Chart({ type, dataSource, onConfigureLink }) {
    const box = React.useRef()
    const { isPrinting, onRenderComplete } = usePrintLayout()
    const [dimensions, setDimensions] = React.useState(null)

    const onRender = React.useCallback(
        chart => {
            if (dataSource.linkeddata && onConfigureLink) {
                onConfigureLink(chart)
            }
        },
        [dataSource, onConfigureLink]
    )

    React.useEffect(() => {
        const height = getHeight(box.current)

        if (!height) {
            console.warn(
                'Chart will not show up because container has no height',
                box.current
            )
        }

        setDimensions({
            height
        })
    }, [])

    const composedDataSource = React.useMemo(
        () => ({
            ...dataSource,
            chart: {
                theme: 'fusion',
                ...getChartDefaultsByType(type),
                ...dataSource.chart,
                exportEnabled: !isPrinting ? '1' : '0',
                animation: !isPrinting ? '1' : '0'
            }
        }),
        [type, dataSource, isPrinting]
    )

    const classes = useStyles()
    return (
        <div className={classes.root} ref={box}>
            {dimensions ? (
                <ReactFC
                    type={type}
                    width="100%"
                    height={dimensions.height}
                    dataFormat="json"
                    dataSource={composedDataSource}
                    onRender={onRender}
                    fcEvent-renderComplete={onRenderComplete}
                />
            ) : null}
        </div>
    )
}
