import { List, ListItem, makeStyles, Grid, Typography, Snackbar } from '@material-ui/core'
import { TabPanel, TabContext } from '@material-ui/lab'
import Tenants from './Tenants'
import Overviewx from './Overview'
import React from 'react'
import { Receivers } from './Receivers'
import ManageUsers from './ManageUsers'
import SpidsPage from './Spids'
const useStyles = makeStyles(theme => ({
    tab: {
        padding: 0
    },
    tabContainer: {
        paddingRight: 24
    },
    hoverTab: {
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    activeTab: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}))
const menuItems = [
    'Overview',
    'Receivers',
    'Tenants',
    'Users',
    'SPIDs'
]

function Overview() {
    const [activeTab, setActiveTab] = React.useState('Overview')
    const classes = useStyles()
    return (
        <Grid container>
            <Grid item md={12} xs={12}>
                <Typography variant="h5">SuperAdmin</Typography>
            </Grid>
            <Grid item xs={12} md={2} className={classes.tabContainer}>
                <List>
                    {menuItems.map((item, key) => {
                        return (
                            <ListItem
                                key={key}
                                className={
                                    item === activeTab
                                        ? classes.activeTab
                                        : classes.hoverTab
                                }
                                onClick={() => {
                                    setActiveTab(item)
                                }}
                            >
                                {item}
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>

            <Grid item xs={12} md={10}>
                <Pages activeTab={activeTab} />
            </Grid>
        </Grid>
    )
}


function Pages({ activeTab }) {
    const classes = useStyles()
    return (
        <div>
            <TabContext value={activeTab}>
                <TabPanel value="Overview" className={classes.tab}>
                    <Overviewx />
                </TabPanel>
                <TabPanel value="Receivers" className={classes.tab}>
                    <Receivers />
                </TabPanel>
                <TabPanel value="Tenants" className={classes.tab}>
                    <Tenants />
                </TabPanel>
                <TabPanel value="Users" className={classes.tab}>
                    <ManageUsers />
                </TabPanel>
                <TabPanel value="SPIDs" className={classes.tab}>
                    <SpidsPage />
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default Overview