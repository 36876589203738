const drawerWidth = 240

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        backgroundColor: '#ffffff',
        paddingRight: 24, // keep right padding when drawer closed
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24,
        },
    },
    toolbarIcon: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 130,
        padding: '0 8px',
        img: {
            maxWidth: '112px',
        }
    },
    sidebarBottom: {
        height: '100%',
        padding: '0 16px',
    },
    appBar: {
        boxShadow: 'none',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    userActionButton: {
        marginLeft: 'auto !important'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: 0
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        paddingTop: 0,
        paddingBottom: theme.spacing(4),
        paddingLeft: 24,
        paddingRight: 24,
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    linkButton: {
        color: '#000000',
        padding: '0',
        '&:hover': {
            backgroundColor: 'none'
        }
    },
    selected: {
        color: '#ebbf44'
    },
    linkContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        alignSelf: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    userContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        padding: 2
    },
    userName: {
        marginLeft: theme.spacing(1),
    },
    adminLogin: {
        marginTop: theme.spacing(2),
    },
    userFooter: {
        position: 'relative',
    }
})

export default styles
