import { makeStyles } from '@material-ui/core'
import React from 'react'

import Chart from './Chart'
import { multiplex } from '../formatting'

const useStyles = makeStyles({
    root: {
        height: '100%',
        padding: '0 8px 8px 0'
    }
})

export default function VendorAssignedSurveyResponseTimeChart({ data }) {
    const classes = useStyles()
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                decimals: '0',
                yAxisValuesStep: data.yAxisValuesStep,
                paletteColors: data.palette,
                plotSpacePercent: data.plotSpacePercent
            },
            data: multiplex(data)
        }
        return (
            <div className={classes.root}>
                <Chart type="bar2d" dataSource={dataSource} />
            </div>
        )
    }, [data])
}
