import {
    Container,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    makeStyles
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import React from 'react'

import {
    ForgotPasswordModal,
    Logo,
    NewPasswordModal,
    ProgressButton,
    useValidation
} from 'components'
import { useDispatch } from 'react-redux'
import { fetchBranding } from 'redux/slices/brandingSlice'
import { fetchTenantStats } from 'redux/slices/tenantStatsSlice'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh'
    },
    edgeContainers: {
        backgroundColor: theme.palette.info.main
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logo: {
        maxWidth: '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%'
    },
    forgotPasswordContainer: {
        textAlign: 'center',
        paddingTop: 24
    },
    forgotPassword: {
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'center'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    title: {
        color: theme.palette.primary.dark
    }
}))

export default function Login() {
    const forgotPasswordDialogRef = React.useRef()
    const newPasswordModalRef = React.useRef()
    const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'))

    const [isLoading, setIsLoading] = React.useState(false)
    const [values, setValues] = React.useState({
        email: '',
        password: ''
    })
    const [errorMessage, setErrorMessage] = React.useState()

    const { validate, errors, ifError, reset } = useValidation({
        email: ['required', 'email'],
        password: ['required']
    })

    const dispatch = useDispatch();

    const loginAsync = React.useCallback(async () => {
        setIsLoading(true)

        try {
            await Auth.signIn(values.email, values.password).then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    newPasswordModalRef.current.open(user)
                }
            })
            dispatch(fetchBranding())
            dispatch(fetchTenantStats())
        } catch (error) {
            setErrorMessage('Please check your credentials and try again')
            console.log({ error: error.code })
            if (error.code === 'UserNotConfirmedException') {
                Auth.resendSignUp(values.email)
            } else if (error.code === 'NotAuthorizedException') {
                setErrorMessage(error.message)
            } else if (error.code === 'UserNotFoundException') {
                setErrorMessage(error.message)
            } else {
                setErrorMessage('Login failed')
            }
        } finally {
            setIsLoading(false)
        }
    }, [values])

    const onChange = React.useCallback(
        e => {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })

            reset()
        },
        [values, reset]
    )

    const onSubmit = React.useCallback(
        e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }

            loginAsync(values)
        },
        [values, validate, loginAsync]
    )

    const onFederatedLogin = async () => {
        const user = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito })
        console.log('USER', user);
    }

    const classes = useStyles()
    return (
        <Grid container justifyContent="center">
            {isLargeScreen && (
                <Grid item xs={3} className={classes.edgeContainers} />
            )}
            <Grid item xs={6}>
                <Container
                    component="main"
                    maxWidth="xs"
                    className={classes.root}
                >
                    <div className={classes.paper}>
                        <Logo />
                        <Typography
                            component="h1"
                            variant="h5"
                            gutterBottom
                            className={classes.title}
                        >
                            Client Login
                        </Typography>
                        {errorMessage && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={onSubmit}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={values.email}
                                onChange={onChange}
                                error={errors.email}
                                helperText={ifError(
                                    'email',
                                    'Please enter your email'
                                )}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                values={values.password}
                                onChange={onChange}
                                error={errors.password}
                                helperText={ifError(
                                    'password',
                                    'Please enter your password'
                                )}
                            />
                            <div className={classes.forgotPasswordContainer}>
                                <a
                                    onClick={() => {
                                        forgotPasswordDialogRef.current.open()
                                    }}
                                    className={classes.forgotPassword}
                                >
                                    Forgot Password
                                </a>
                            </div>
                            <div className={classes.actions}>
                                <ProgressButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={onSubmit}
                                    isLoading={isLoading}
                                >
                                    Login
                                </ProgressButton>
                            </div>
                        </form>
                        {/* <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}>Open Facebook</button> */}
                        {/* <button onClick={onFederatedLogin}>Corporate Login</button> */}
                        {/* <ProgressButton
                            type="default"
                            variant="contained"
                            color="warning"
                            className={classes.submit}
                            onClick={onFederatedLogin}
                            isLoading={isLoading}
                        >
                            Corporate Login
                        </ProgressButton> */}
                        {/* <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Amazon })}>Open Amazon</button> */}
                        {/* <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })}>Open Apple</button> */}
                    </div>
                </Container>
            </Grid>
            {isLargeScreen && (
                <Grid item xs={3} className={classes.edgeContainers} />
            )}
            <NewPasswordModal ref={newPasswordModalRef} />
            <ForgotPasswordModal ref={forgotPasswordDialogRef} />
        </Grid>
    )
}
