import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core'
import { without } from 'lodash'
import React from 'react'

import { useUserOptions } from './UserOptionsContext'

const useStyles = makeStyles({
    root: {
        //color: 'white'
    },
    filterTopicDashboard: {
        // color: 'white',
        fontWeight: 'bold',
        padding: '10px 0',
        textAlign: 'left',
        whiteSpace: 'normal',
        flexDirection: 'column',
        '& .MuiFormControlLabel-root': {
            margin: '0 0 10px',
        }
    },
    hrShortRuler: {
        width: '60%'
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20
    },
    customLabel: {
        color: '#000000'
    },
})

function FilterLayout({ children, title }) {
    const classes = useStyles()
    return (
        <Grid container className={classes.filterTopicDashboard}>
            <Typography variant="body1">{title}</Typography>
            <hr className={classes.hrShortRuler} />
            {children}
        </Grid>
    )
}

export default function FilterSelect({ options, data }) {
    const { update: updateOptions } = useUserOptions()

    const onChange = React.useCallback(
        (checked, name, key) => {
            updateOptions(options => ({
                ...options,
                [key]: checked
                    ? [...options[key], name]
                    : without(options[key], name)
            }))
        },
        [updateOptions]
    )

    const isFilterChecked = (prop, name) => {
        if (prop === 'regions') {
            return options.regions.indexOf(name) !== -1
        } else if (prop === 'countries') {
            return options.countries.indexOf(name) !== -1
        }
        return options.filters.indexOf(name) !== -1
    }

    const classes = useStyles()
    return (
        <>
            <FilterLayout title="Filter Dashboard by Topic">
                <FormGroup>
                    {data.filters.map((x, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    name={x.key}
                                    onChange={e =>
                                        onChange(
                                            e.target.checked,
                                            e.target.name,
                                            'filters'
                                        )
                                    }
                                    checked={isFilterChecked('filters', x.key)}
                                    size="small"
                                    color="primary"
                                //classes={{ root: classes.root }}
                                />
                            }
                            label={x.label}
                            classes={{ label: classes.customLabel }}
                        />
                    ))}
                </FormGroup>
            </FilterLayout>
            <FilterLayout title="Filter Dashboard by Region">
                <FormGroup>
                    {data.drilldowns_hierarchy.map(x => (
                        <FormControlLabel
                            key={x.key}
                            control={
                                <Checkbox
                                    name={x.key}
                                    onChange={e =>
                                        onChange(
                                            e.target.checked,
                                            e.target.name,
                                            'regions'
                                        )
                                    }
                                    checked={isFilterChecked('regions', x.key)}
                                    size="small"
                                    color="primary"
                                    classes={{ root: classes.root }}
                                />
                            }
                            label={x.label}
                        />
                    ))}
                </FormGroup>
            </FilterLayout>

            <FilterLayout title="Filter Dashboard by Country">
                <FormGroup>
                    {data.drilldowns_hierarchy.map(
                        x =>
                            options.regions.indexOf(x.key) !== -1 &&
                            x.children.map(children => (
                                <FormControlLabel
                                    key={children.key}
                                    control={
                                        <Checkbox
                                            name={children.key}
                                            onChange={e =>
                                                onChange(
                                                    e.target.checked,
                                                    e.target.name,
                                                    'countries'
                                                )
                                            }
                                            checked={isFilterChecked(
                                                'countries',
                                                children.key
                                            )}
                                            size="small"
                                            color="primary"
                                            classes={{
                                                root: classes.root
                                            }}
                                        />
                                    }
                                    label={children.label}
                                />
                            ))
                    )}
                </FormGroup>
            </FilterLayout>
        </>
    )
}
