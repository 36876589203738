import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { Divider } from 'components'

const useStyles = makeStyles({
    pageHeaderText: {
        alignSelf: 'center',
        fontWeight: 'bold',
        color: ({ palette }) => palette?.title
    },
    dividerResponses: {
        borderColor: ({ palette }) => palette?.divider
    }
})

export default function PageHeader({
    title,
    palette,
    hasDivider,
    variant = 'subtitle1'
}) {
    const classes = useStyles({
        palette
    })

    return (
        <Typography variant={variant} className={classes.pageHeaderText}>
            {title}
            {hasDivider && <Divider className={classes.dividerResponses} />}
        </Typography>
    )
}
