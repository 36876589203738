import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    title: {
        borderBottom: '1px solid #dee2e6'
    },
    content: {
        padding: '16px 24px',
        minWidth: 600,
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        }
    }
}))

export default function AdminModal({ title, children, open, onClose }) {
    const classes = useStyles()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.title}>
                <Typography variant="subtitle1">{title}</Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
