import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { PercentProgress, SimpleCard, usePrintLayout } from 'components'

const useStyles = makeStyles({
    root: {
        height: 275,
        padding: '1.25rem',
        textAlign: 'center'
    },
    rootPrint: {
        padding: '1.25rem',
        textAlign: 'center'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    newHiresLabelPercentage: {
        color: ({ palette }) => palette.newHiresLabelPercentage
    },
    newHiresBar: {
        backgroundColor: ({ palette }) => palette.newHiresBar
    },
    existingEmployeesLabelPercentage: {
        color: ({ palette }) => palette.existingEmployeesLabelPercentage
    },
    existingEmployeesBar: {
        backgroundColor: ({ palette }) => palette.existingEmployeesBar
    }
})

export default function SurveyCompletionByHireChart({ data }) {
    const { isPrinting } = usePrintLayout()
    const percentNewHiresCompleted =
        (data.new_hires_completed * 100) / data.surveys_issued
    const percentExistingEmployeesHires =
        (data.exist_employee_complete * 100) / data.surveys_issued
        
    const classes = useStyles({
        palette: data.surveyCompletionByHireChartPalette
    })
    return React.useMemo(
        () => (
            <SimpleCard
                classes={{
                    root: isPrinting ? classes.rootPrint : classes.root,
                    body: classes.body
                }}
            >
                <PercentProgress
                    value={percentNewHiresCompleted}
                    label="of New Hire Surveys completed"
                    classes={{
                        labelPercentage: classes.newHiresLabelPercentage,
                        bar: classes.newHiresBar
                    }}
                />
                <PercentProgress
                    value={percentExistingEmployeesHires}
                    label="of Existing Employees completed"
                    classes={{
                        labelPercentage:
                            classes.existingEmployeesLabelPercentage,
                        bar: classes.existingEmployeesBar
                    }}
                />
            </SimpleCard>
        ),
        [data]
    )
}
