import React from 'react'
import {
    Button,
    DialogActions,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core'

import { Auth } from 'aws-amplify'

import AdminModal from '../components/admin/AdminModal'
import ResetPasswordModal from './ResetPasswordModal'
import { useValidation } from 'components'

const useStyles = makeStyles({
    root: {
        padding: 20
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    sendCodeButton: {
        color: '#fff'
    },
    closeButton: {
        color: '#ef8c25'
    }
})

function ActionButtons({ close, onSubmit }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.sendCodeButton}
                    >
                        Send me a code
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function ForgotPasswordModal(props, ref) {
    const resetPasswordDialogRef = React.useRef()
    const [open, setOpen] = React.useState(false)

    const [values, setValues] = React.useState({
        email: ''
    })

    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            email: ['required', 'email']
        }
    )

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
        },
        [values, resetValidation]
    )

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }
            try {
                await Auth.forgotPassword(values.email)
                setValues({
                    email: ''
                })
                resetPasswordDialogRef.current.open(values.email)
            } catch (error) {
                alert(error.message)
                console.warn({error: error.message})
                throw error
            }
        },
        [values, validate]
    )

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="Forgot Password">
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        label="Email Address"
                        name="email"
                        value={values.email}
                        onChange={onChange}
                        error={errors.email}
                        helperText={ifError(
                            'email',
                            'Please fill in your email'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onSubmit} close={close} />
            <ResetPasswordModal ref={resetPasswordDialogRef} setOpenForgotPwModal={setOpen} />
        </AdminModal>
    )
}

export default React.forwardRef(ForgotPasswordModal)
