import { useState } from "react"
import { Box, Grid, Button, Typography } from "@material-ui/core"
import { send_command } from '../../components/api'

export const ClientLogs = ({ tenant_id }) => {
    const [logs, setLogs] = useState('  ')
    const [logsReceived, setLogsReceived] = useState(null)
    const latest_logs = (tenant_id) => {
        send_command('ADMIN_GET_LATEST_CLIENT_LOGS',{tenant_id})
            .then(res => {
                const rec_time = Date.parse(res['2018-04-01'])
                setLogsReceived(Date(rec_time))
                setLogs(JSON.parse(res['logs'])[0])
            })
            .catch(ex => {
                if (ex.message === "Request failed with status code 404") {
                    setLogs("No logs found")
                    setLogsReceived("None")
                    return
                }
                console.error({ ex })
                alert(`{ex.message} <--`)
            })
    }

    const fetch_logs = (tenant_id) => {
        send_command('ADMIN_GET_CLIENT_LOGS', {tenant_id}).then(res => {
            console.log({ res })
            alert('command set')
        })
            .catch(ex => {                
                console.error({ ex })
                alert(ex.message)
            })
    }

    return (

        <Box  >
            <Grid container>

                <Grid item sm={6}>
                    Last received: {logsReceived}
                </Grid>
            </Grid>

                <Box height={600} component="section" sx={{fontFamily: 'Monospace', fontSize: 'h6.fontSize', m: 1, p: 2, border: '1px solid gray', overflow: "scroll" }}>
                    <pre>{logs}</pre>
                </Box>
            <Button variant="contained" color="primary" size="small" onClick={() => fetch_logs(tenant_id)}>request logs</Button>&nbsp;
            <Button variant="contained" color="primary" size="small" onClick={() => latest_logs(tenant_id)}>latest logs</Button>

        </Box>
    )
}