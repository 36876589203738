import { Button, Box, Card, CardActions, CardContent, Dialog, DialogTitle, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { ADMIN_TENANT_GET, ADMIN_GET_APP_CLIENTS, ADMIN_GET_CLIENT_SPIDS } from '../../components/api'
import { Emails } from "./Emails"
import { AppClient, AppClientCommands } from './AppClient'
import { ClientLogs } from './ClientLogs'
import { UserPoolClients } from './UserPoolClients'
import React from 'react'
import { useState } from 'react'
import SpidsPage from './Spids'
import ManageUsers from 'pages/superadmin/ManageUsers'
import { BackdropProgress } from 'components';
import { SuperAdminFold } from './SuperAdminFold';
import Surveys from './Surveys'
import { Locale } from './Locale';
import { TheChart } from './TheChart'
import { Receivers } from './Receivers'
import { JsonEditor } from "react-jsondata-editor"
import { DashboardCard } from './DashboardCard'
import { ClientApps } from './ClientApps'
import { UserManager } from './UserManager'


export const TenantDashboard = ({ close, tenant_id }) => {

    const [showSpids, setShowSpids] = useState(false)
    const [clientIds, setClientIds] = useState([])

    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const fetchClientKeys = () => {
        setLoading(true)
        ADMIN_GET_APP_CLIENTS(tenant_id)
            .then((keys) => {
                setLoading(false)
                setClientIds(keys)
            }
            )
            .catch(er => {
                setLoading(false)
                console.error(er)

            }
            )
    }

    const sync_spids = (tenant_id) => {
        console.log({ tenant_id })
        ADMIN_GET_CLIENT_SPIDS(tenant_id)
            .then(res => {
                alert("commmand set")
            })
            .catch(ex => {
                console.error(ex)
                alert(ex.message)
            })
    }

    const getDetails = () => {
        setLoading(true)
        ADMIN_TENANT_GET(tenant_id).then(tenant => {


            const languages = new Set()
            tenant.tenant_details.locations.map(location => { languages.add(location.language) })

            setDetails(tenant)
            setLoading(false)
        }).catch(error => {
            console.log({ error })
            setDetails({ "yeah": "no" })
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getDetails()
    }, [])
    return (<Grid container>

        <Grid item xs={12}>
            <Button variant="contained" color="primary" size="small" onClick={() => sync_spids(tenant_id)}>SYNCHRONIZE</Button>&nbsp;
            <Button variant="contained" color="primary" size="small" onClick={getDetails}>Reload</Button>&nbsp;
            <Button variant="contained" color="primary" size="small" onClick={close}>Close</Button>
        </Grid>
        {loading ? <BackdropProgress /> :
            <Grid item xs={12}>
                <Grid container spacing={2} padding={2}>
                    <Grid item sm={12}>
                        <Typography gutterBottom variant="h5" component="div">
                            {details?.tenant_details?.tenant_name}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography gutterBottom variant="h5" component="div">
                            {details?.tenant_name}
                        </Typography>
                    </Grid>

                    <DashboardCard title={"Client App"} xs={12} >
                        <ClientApps tenant_id={tenant_id} collapsed_size={3} />
                    </DashboardCard>



                    <Grid item sm={12}>
                        <SuperAdminFold title={"Emails"}>
                            <Emails tenant_id={tenant_id} logo={details?.tenant_details.branding?.logo} />
                        </SuperAdminFold>
                    </Grid>

                    {/* <Grid item sm={12}>
                            <SuperAdminFold title={"Tenant Users"}>
                                <ManageUsers tenant_id={tenant_id} />
                            </SuperAdminFold>
                        </Grid> */}
                    <Grid item sm={12}>
                        <SuperAdminFold title={"Client App"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <SuperAdminFold title={"Logs"}>
                                        <ClientLogs tenant_id={tenant_id} />
                                    </SuperAdminFold>
                                </Grid>
                                <Grid item xs={6}>
                                    <SuperAdminFold title={"AppClientId Converter"}>
                                        <AppClient />
                                    </SuperAdminFold>
                                </Grid>
                                <Grid item xs={6}>
                                    <SuperAdminFold title={"Client Commands"}>
                                        <AppClientCommands tenant_id={tenant_id} />
                                    </SuperAdminFold>
                                </Grid>
                                <Grid item xs={12}>
                                    <SuperAdminFold title={"ClientKeys"}>
                                        <Button variant="contained" onClick={fetchClientKeys}>Fetch</Button>
                                        <Box>
                                            <pre>{JSON.stringify(clientIds, null, 3)}</pre>
                                        </Box>
                                    </SuperAdminFold>
                                    <SuperAdminFold title={"UserPool Clients"}>
                                        <UserPoolClients />
                                    </SuperAdminFold>
                                </Grid>
                            </Grid>
                        </SuperAdminFold>
                    </Grid>
                    <Grid item xs={12}>
                        <SuperAdminFold title={"Locations"}>
                            <Locale tenant={details} />
                        </SuperAdminFold>
                    </Grid>
                    <Grid item xs={12}>
                        <SuperAdminFold title={"Surveys"}>
                            <Surveys tenant_id={tenant_id} />
                        </SuperAdminFold>
                    </Grid>
                    <Grid item xs={12}>
                        <SuperAdminFold title={"tenant record"}>
                            <pre>
                                {JSON.stringify(details, null, 3)}
                            </pre>
                        </SuperAdminFold>
                    </Grid>
                    <Grid item xs={12}>
                        <SuperAdminFold title={"TheChart"}>
                            <TheChart tenant_name={details?.tenant_details?.tenant_name} tenant_id={tenant_id} />
                        </SuperAdminFold>
                    </Grid>
                    <Grid item xs={12}>
                        <SuperAdminFold title={"Survey Receivers"}>
                            <Receivers tenant_id={tenant_id} />
                        </SuperAdminFold>
                    </Grid>

                </Grid>

            </Grid>
        }

        <Dialog onClose={() => setShowSpids(false)} open={showSpids}>
            <DialogTitle>SPIDs</DialogTitle>
            <DialogContent>
                <SpidsPage></SpidsPage>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
        {/* </Card> */}
    </Grid>
    )
}
