export function formatNumeral(n, options) {
    return n.toLocaleString('en-US', options)
}

export function formatPercentage(n, decimals = 0) {
    return n.toFixed(decimals)
}

export function multiplex({ data, labels }) {
    const result = []
    for (let i = 0; i < data.length; ++i) {
        result.push({
            label: labels[i],
            value: data[i]
        })
    }
    return result
}
