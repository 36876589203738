import React, { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogTitle, Grid, Typography, TextField, Button, DialogActions } from "@material-ui/core"

import { send_command } from '../../components/api'

export const NewEmailDialog = ({closeDialog, showDialog, tenant_id}) => {
    const [newType, setNewType] = useState()
    const [newLanguage, setNewLanguage] = useState()
    const [newSubject, setNewSubject] = useState()
    const [newBody, setNewBody] = useState()
    const closeAddEmail = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;

        setNewType("")
        setNewLanguage("")
        setNewSubject("")
        setNewBody("")
        closeDialog()
    }
    const create_email = () => {
        send_command(
            'ADMIN_ADD_EMAIL',
            {
                tenant_id: tenant_id,
                type: newType,
                language: newLanguage,
                subject: newSubject,
                body: newBody
        }).then (res => {
            closeAddEmail()
        }).catch(error => {
            alert(error.response.data)
        })
        
    }

    return (
        <Dialog open={showDialog} onClose={closeDialog}>
            <DialogTitle className={"Add new Email"}>
                <Typography variant="subtitle1">{"New Email"}</Typography>
            </DialogTitle>
            <DialogContent >
                <Grid container >
                    <Grid item xs={12} style={{ padding: "3px" }}>
                        <TextField
                            value={newType}
                            onChange={(e) => setNewType(e.target.value)}
                            variant="filled"
                            required
                            id="type"
                            label="Type"
                        />&nbsp;
                        <TextField
                            value={newLanguage}
                            onChange={(e) => setNewLanguage(e.target.value)}
                            variant="filled"
                            required
                            id="language"
                            label="Language"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: "3px" }}>
                        <TextField
                            value={newSubject}
                            onChange={(e) => setNewSubject(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                            id="subject"
                            label="Subject"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: "3px" }}>
                        <TextField
                            value={newBody}
                            onChange={(e) => setNewBody(e.target.value)}
                            variant="filled"
                            fullWidth
                            multiline
                            rows={12}
                            style={{ height: "300px" }}
                            required
                            id="body"
                            label="Body"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeAddEmail}>Cancel</Button>&nbsp;
                <Button variant="contained" color="primary" onClick={create_email} >Create</Button>
            </DialogActions>
        </Dialog>
    )
}