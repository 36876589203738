import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles({
    divider: {
        width: 100,
        borderTop: 'none',
        borderLeft: 'none',
        borderColor: '#74C086',
        borderWidth: 'medium',
        marginTop: '1rem',
        marginBottom: '1rem'
    }
})

export default function Divider({ className }) {
    const classes = useStyles()
    return <hr className={clsx(classes.divider, className)} />
}
