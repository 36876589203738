import React from 'react'
import FusionCharts from 'fusioncharts'
import charts from 'fusioncharts/fusioncharts.charts'
import ReactFusionCharts from 'react-fusioncharts'

// Resolves charts dependency
charts(FusionCharts)

const FusionChart = ({ type, width, height, dataSource }) => {
    return (
        <>
            <ReactFusionCharts
                type={type}
                width={width} // Adjust the width of the chart
                height={height}
                dataFormat="JSON"
                dataSource={dataSource}
            />
        </>
    )
}

export default FusionChart
