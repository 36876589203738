import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        height: 50,
        padding: '12px 24px',
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'space-between'
    },
    link: {
        textDecoration: 'none',
        alignSelf: 'center',
        color: '#03989E',
        paddingRight: 10,
        fontSize:18,
        fontWeight:600,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    copyright: {
        alignSelf: 'center',
        color:'#8A8A8A',
        fontSize:18,
        fontWeight:600,
        fontStyle: 'normal'
    }
}))

export default function Footer() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div>
                <a
                    href="https://www.safeportersecure.com/privacy-and-security"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                >
                    Privacy
                </a>
                <a
                    href="https://www.safeportersecure.com/cookie-policy"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                >
                    Cookie Policy
                </a>
            </div>
            <p className={classes.copyright}>&copy; 2021 - SafePorter</p>
        </div>
    )
}
