import { Auth, Hub } from 'aws-amplify'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import BackdropProgress from './BackdropProgress'
import { useDispatch } from 'react-redux'
import { fetchTenantStats } from 'redux/slices/tenantStatsSlice'
import { fetchBranding } from 'redux/slices/brandingSlice'

const AuthenticationContext = React.createContext()

export function AuthenticationContextProvider({ children }) {
    const history = useHistory()
    const [authState, setAuthState] = React.useState()

    React.useEffect(
        function registerHubListener() {
            const listener = ({ payload }) => {
                switch (payload.event) {
                    case 'signIn':
                        Auth.currentAuthenticatedUser()
                            .then(user => {
                                setAuthState(user)
                                history.push('/')
                            })
                            .catch(() => {
                                setAuthState(false)
                                console.log("Signin Failed")
                            })
                        break

                    case 'signIn_failure':
                        console.log("Signin Failed 2")
                        console.log({ payload })
                        setAuthState(false)
                        break
                    case 'signOut':
                        setAuthState(false)
                        break

                    default:
                        break
                }
            }

            Hub.listen('auth', listener)

            return () => Hub.remove('auth', listener)
        },
        [history]
    )

    const dispatch = useDispatch()

    React.useEffect(function initializeAuthState() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                // Refresh data on page load
                dispatch(fetchBranding());
                dispatch(fetchTenantStats());

                setAuthState(user)
            })
            .catch(() => setAuthState(false))
    }, [])
    return (<>
        <AuthenticationContext.Provider value={authState}>
            {children}
        </AuthenticationContext.Provider>
        </>
    )
}

export function useAuthentication() {
    return React.useContext(AuthenticationContext)
}

export function Protected({ children }) {
    const authState = useAuthentication()
    if (authState === undefined) {
        return <BackdropProgress />
    }

    if (authState === false) {
        return <Redirect to="/login" />
    }

    return children
}
