import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Card, CardHeader } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        height: 450,
        display: 'flex',
        flexFlow: 'column',
        [theme.breakpoints.down('xs')]: {
            height: 490
        }
    },
    body: {
        padding: 0,
        flex: '1 1 auto'
    },
    text: {
        textAlign: 'center'
    },
    footer: {
        flex: '0 1 auto'
    }
}))

export function ChartCard({
    title,
    classes: classesOverride = {},
    children,
    footer
}) {
    const classes = useStyles()
    return (
        <Card className={clsx(classes.root, classesOverride.root)}>
            {title && (
                <CardHeader className={classesOverride.title}>
                    {title}
                </CardHeader>
            )}
            <div className={classes.body}>{children}</div>
            {footer && <div className={classes.footer}>{footer}</div>}
        </Card>
    )
}

export function ChartCardFooter({ children }) {
    const classes = useStyles()
    return <div className={classes.text}>{children}</div>
}

export default ChartCard
