import { Grid, Typography, makeStyles } from '@material-ui/core'

import React from 'react'

import { ProgressButton, useAuthentication } from 'components'

import {
    useSaveSurveyMutation
} from 'components/api'

import { useQueryClient } from 'react-query'

import { CheckCircleOutline as SuccessIcon } from '@material-ui/icons'

import styles from './ThankYou.styles'

const useStyles = makeStyles(styles)

export default function ThankYou({ values, alreadySubmitted }) {
    const { mutateAsync: submitNewSurveyAsync, isLoading: newSurveyLoading } = useSaveSurveyMutation()
    const user = useAuthentication()
    const queryClient = useQueryClient()

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()
            try {
                await submitNewSurveyAsync({
                    vendor_id: user.attributes['custom:vendor_id'],
                    survey: values
                })
                await queryClient.invalidateQueries('survey-data')
            } catch (error) {
                throw error
            }
        },
        [submitNewSurveyAsync, values]
    )

    const classes = useStyles()
    return (
        <Grid container item xs={12} className={classes.root}>
            <Grid item xs={12} className={classes.iconContainer}>
                <SuccessIcon className={classes.icon} />
            </Grid>
            <Grid item xs={12} className={classes.text}>
                <Typography variant="h6">
                    {alreadySubmitted
                        ? 'You have already submitted a survey!'
                        : 'Thank you for your submission!'}
                </Typography>
            </Grid>

            {alreadySubmitted && (
                <Grid item xs={12} className={classes.buttonContainer}>
                    <ProgressButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={onSubmit}
                            isLoading={newSurveyLoading}
                        >
                            Submit a new Survey
                        </ProgressButton>
                </Grid>
            )}
        </Grid>
    )
}
