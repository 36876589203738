import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.main,
        color: theme.palette.info.main
    }
}))

export default function BackdropProgress({ isBusy = true }) {
    const classes = useStyles()
    return (
        <Backdrop className={classes.backdrop} open={isBusy}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
