import { Grid, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'

import { PageHeader } from 'components'
import { Print as PrintIcon } from '@material-ui/icons'

const useStyles = makeStyles({
    container: {
        height: 750
    },
    pageHeaderContainer: {
        textAlign: 'center',
        padding: 32
    },
    printButtonContainer: {
        marginBottom: 20
    },
    printIcon: {
        color: ({ palette }) => palette?.printIcon
    }
})

export default function Wrapper({
    handlePrint,
    printIcon,
    hasDivider,
    isPrinting,
    children,
    palette,
    title,
    variant
}) {
    const classes = useStyles({ palette })

    if (isPrinting) {
        return <div>{children}</div>
    }

    return (
        <Grid container>
            {printIcon ? (
                <>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="flex-end"
                        className={classes.printButtonContainer}
                    >
                        <IconButton
                            color="primary"
                            variant="contained"
                            onClick={handlePrint}
                            className={classes.printIcon}
                        >
                            <PrintIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            classNames={classes.container}
                        >
                            {children}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} className={classes.pageHeaderContainer}>
                    <PageHeader
                        title={title}
                        palette={palette}
                        hasDivider={hasDivider}
                        variant={variant}
                    />

                    {children}
                    </Grid>
            )}
        </Grid>
    )
}
