import {
    Button,
    DialogActions,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core'
import React from 'react'

import { useQueryClient } from 'react-query'

import { useAddUserMutation } from 'components/api'

import AdminModal from '../admin/AdminModal'
import { useValidation } from 'components'

const useStyles = makeStyles({
    root: {
        padding: 20
    },
    textfield: {
        marginTop: 10
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    inviteButton: {
        color: '#fff'
    },
    closeButton: {
        color: '#ef8c25'
    }
})

function ActionButtons({ onSubmit, close }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.inviteButton}
                    >
                        Invite User
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

const defaultValues = {
    given_name: '',
    family_name: '',
    email: ''
}

function AddUserModal(props, ref) {
    const queryClient = useQueryClient()
    const { mutateAsync: addUserAsync } = useAddUserMutation()

    const [open, setOpen] = React.useState(false)
    const [values, setValues] = React.useState(defaultValues)
    const [error, setError] = React.useState(false)

    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            given_name: 'required',
            family_name: 'required',
            email: ['required', 'email']
        }
    )

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
        },
        [values, resetValidation]
    )

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }
            try {
                await addUserAsync(values)

                await queryClient.invalidateQueries('user-data')

                close()
            } catch (error) {
                alert(error.message)
                if (error.response && error.response.status === 409) {
                    setError(true)                    
                    return
                }
                throw error
            }
        },
        [values, addUserAsync, error, queryClient, validate]
    )

    const close = React.useCallback(() => {
        setValues(defaultValues)
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="New User">
            <Grid container className={classes.root}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        First Name
                        <TextField
                            placeholder="First name"
                            name="given_name"
                            value={values.given_name}
                            onChange={onChange}
                            error={errors.given_name}
                            helperText={ifError(
                                'given_name',
                                'Please fill in the first name of the user'
                            )}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            className={classes.textfield}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Last Name
                        <TextField
                            placeholder="Last name"
                            name="family_name"
                            value={values.family_name}
                            onChange={onChange}
                            error={errors.family_name}
                            helperText={ifError(
                                'family_name',
                                'Please fill in the last name of the user'
                            )}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            className={classes.textfield}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        Email
                        <TextField
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={onChange}
                            error={errors.email || error}
                            helperText={
                                ifError(
                                    'email',
                                    'Please fill in the email of the user'
                                ) ||
                                (error &&
                                    'Failed to invite!. A user with that email alread exists.')
                            }
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            className={classes.textfield}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onSubmit} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(AddUserModal)
