import { makeStyles } from '@material-ui/core'
import React from 'react'

import PercentProgress from '../PercentProgress'
import SimpleCard from '../SimpleCard'
import { formatNumeral } from '../formatting'

const useStyles = makeStyles({
    root: {
        height: 275,
        padding: '1.25rem',
        textAlign: 'center'
    },
    completedLabelPercentage: {
        color: ({ palette }) => palette.completedLabelPercentage
    },
    completedBar: {
        backgroundColor: ({ palette }) => palette.completedBar
    },
    overdueLabelPercentage: {
        color: ({ palette }) => palette.overdueLabelPercentage
    },
    overdueBar: {
        backgroundColor: ({ palette }) => palette.overdueBar
    }
})

export default function SurveyCompletionChart({ data }) {
    const percentCompleted =
        (data.surveys_completed * 100) / data.surveys_issued
    const percentOverdue = (data.surveys_overdue * 100) / data.surveys_issued
    const palette = data.surveyCompletionChartPalette

    const classes = useStyles({ palette })

    return React.useMemo(
        () => (
            <SimpleCard classes={{ root: classes.root }}>
                <p>
                    <b>{formatNumeral(data.surveys_issued)}</b> Surveys Issued
                </p>
                <p>
                    <b>{formatNumeral(data.surveys_completed)}</b> Surveys
                    Completed
                </p>
                <PercentProgress
                    value={percentCompleted}
                    label="of Assigned Surveys completed"
                    large
                    classes={{
                        labelPercentage: classes.completedLabelPercentage,
                        bar: classes.completedBar
                    }}
                />
                <PercentProgress
                    value={percentOverdue}
                    label="of Assigned Surveys are overdue"
                    classes={{
                        labelPercentage: classes.overdueLabelPercentage,
                        bar: classes.overdueBar
                    }}
                />
            </SimpleCard>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    )
}
