
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}))

export default function Loader({ isBusy = true }) {
    const classes = useStyles()
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress color="inherit" />
        </div>
    )
}