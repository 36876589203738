import {
    Badge,
    Button,
    DialogActions,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core'

import React from 'react'

import AdminModal from '../admin/AdminModal'

import { dateFormatter } from '../../components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20
    },
    buttonContainer: {
        borderTop: '1px solid #dee2e6',
        paddingTop: 20
    },
    details: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    closeButton: {
        color: '#ef8c25'
    },
    typeUser: {
        backgroundColor: '#ebbf44',
        color: 'white'
    },
    badgeEnabled: {
        backgroundColor: '#4CAF50',
        color: 'white'
    },
    badgeDisabled: {
        backgroundColor: '#ff9800',
        color: 'white'
    },
    badgeContainer: {
        paddingLeft: 24,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            paddingLeft: 0
        }
    }
}))

function ActionButtons({ close }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function UserInfoModal(props, ref) {
    const [open, setOpen] = React.useState(false)
    const [userInfo, setUserInfo] = React.useState({})
    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: userInfo => {
            setUserInfo(userInfo)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="User Info">
            <Grid container xs={12} spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {userInfo.family_name} {userInfo.given_name}
                    </Typography>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        User Type
                    </Grid>
                    <Grid item xs={6} className={classes.badgeContainer}>
                        <Badge
                            badgeContent={'User'}
                            classes={{
                                badge: classes.typeUser
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={12} sm={6}>
                        Email
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {userInfo.email}
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        Created
                    </Grid>
                    <Grid item xs={6} className={classes.details}>
                        {dateFormatter(userInfo.UserCreateDate)}
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        Modified
                    </Grid>
                    <Grid item xs={6} className={classes.details}>
                        {dateFormatter(userInfo.UserLastModifiedDate)}
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        User Status
                    </Grid>
                    <Grid item xs={6} className={classes.badgeContainer}>
                        <Badge
                            badgeContent={
                                userInfo.Enabled ? 'Enabled' : 'Disabled'
                            }
                            classes={{
                                badge: userInfo.Enabled
                                    ? classes.badgeEnabled
                                    : classes.badgeDisabled
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        Password Status
                    </Grid>
                    <Grid item xs={6} className={classes.badgeContainer}>
                        <Badge
                            badgeContent="CONFIRMED"
                            classes={{ badge: classes.badgeEnabled }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ActionButtons close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(UserInfoModal)
