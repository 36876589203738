import { useState } from "react"
import { Card, Collapse, Grid, Typography } from '@material-ui/core'


export const SuperAdminFold = ({ default_show, title, children, count}) => {
    const [show, setShow] = useState(false)
    return (
        <Card elevation={10} style={{ margin: '1em' }}>
            <Grid container style={{ padding: '1em' }}>
                <Grid item sm={12} >
                    <Typography variant="h5" onClick={() => setShow(!show)} >
                        {title} {count&&<>({count})</>}
                    </Typography>                    
                </Grid>

                <Grid item sm={12}>
                    <Collapse in={show}>
                        {children}
                    </Collapse>
                </Grid>
            </Grid>
        </Card>

            )
}