import React from 'react'

export const PrintContext = React.createContext()

function getAppziButton() {
    return document.querySelector('[id^=appzi-launch-button]')
}

export function PrintContextProvider({ children }) {
    const [isPrinting, setIsPrinting] = React.useState(false)
    const [renderedCharts, setRenderedCharts] = React.useState([])

    const onPrintClose = React.useCallback(() => {
        const appzi = getAppziButton()
        appzi.style.display = 'block'
        setIsPrinting(false)
    }, [])

    const onPagePrint = React.useCallback(() => {
        const appzi = getAppziButton()
        appzi.style.display = 'none'
        setIsPrinting(true)
    }, [])

    React.useEffect(() => {
        window.addEventListener('beforeprint', onPagePrint)
        window.addEventListener('afterprint', onPrintClose)
        return () => {
            window.removeEventListener('beforeprint', onPagePrint)
            window.removeEventListener('afterprint', onPrintClose)
        }
    }, [])

    const onRenderComplete = React.useCallback(e => {
        setRenderedCharts(completed => [...completed, e.sender.id])
    }, [])

    React.useEffect(() => {
        setRenderedCharts([])
    }, [isPrinting])

    const values = React.useMemo(() => {
        return {
            isPrinting,
            setIsPrinting,
            onRenderComplete,
            renderedCharts
        }
    }, [isPrinting, setIsPrinting, renderedCharts])

    return (
        <PrintContext.Provider value={values}>{children}</PrintContext.Provider>
    )
}

export function usePrintLayout() {
    return React.useContext(PrintContext)
}
