import { makeStyles } from '@material-ui/core'
import React from 'react'

import { PercentProgress, SimpleCard } from '../../components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1.25rem',
        textAlign: 'center',
        height: 450,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('xs')]: {
            height: 490
        }
    },
    otherAbledLabelPercentage: {
        color: ({ palette }) => palette.otherAbledLabelPercentage
    },
    otherAbledBar: {
        backgroundColor: ({ palette }) => palette.otherAbledBar
    },
    moreThanOneLabelPercentage: {
        color: ({ palette }) => palette.moreThanOneLabelPercentage
    },
    moreThanOneBar: {
        backgroundColor: ({ palette }) => palette.moreThanOneBar
    },
    olderLabelPercentage: {
        color: ({ palette }) => palette.olderLabelPercentage
    },
    oldersBar: {
        backgroundColor: ({ palette }) => palette.oldersBar
    },
    youngerLabelPercentage: {
        color: ({ palette }) => palette.youngerLabelPercentage
    },
    youngerBar: {
        backgroundColor: ({ palette }) => palette.youngerBar
    }
}))

export default function InclusionFeedbackDiversitySummary({ data }) {
    const classes = useStyles({palette: data.palette})
    return (
        <SimpleCard classes={{ root: classes.root }}>
            <PercentProgress
                value={data.otherAbled}
                label="of respondents are other-abled"
                classes={{
                    labelPercentage: classes.otherAbledLabelPercentage,
                    bar: classes.otherAbledBar
                }}
            />
            <PercentProgress
                value={data.moreThanOneIssue}
                label="of respondents have submitted more than one issue"
                classes={{
                    labelPercentage: classes.moreThanOneLabelPercentage,
                    bar: classes.moreThanOneBar
                }}
            />
            <PercentProgress
                value={data.over40}
                label="of respondents are 40 or older"
                classes={{
                    labelPercentage: classes.olderLabelPercentage,
                    bar: classes.oldersBar
                }}
            />
            <PercentProgress
                value={data.under25}
                label="of respondents are 25 or younger"
                classes={{
                    labelPercentage: classes.youngerLabelPercentage,
                    bar: classes.youngerBar
                }}
            />
        </SimpleCard>
    )
}
