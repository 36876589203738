import { send_command } from '../../components/api'
import { useEffect, useState } from 'react';
import { Button, Box, Card, CardContent, CardHeader, CircularProgress, Collapse, IconButton, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, responsiveFontSizes, CardActions } from '@material-ui/core'
import { CloudDownloadOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons'

import { BackdropProgress } from 'components';
import SyntaxHighlighter from 'react-syntax-highlighter'

const Surveys = ({ tenant_id }) => {
    const [loading, setLoading] = useState(false)
    const [surveys, setSurveys] = useState([]);

    const list_surveys = () => {
        setLoading(true)
        send_command("ADMIN_GET_TENANT_SURVEYS", { 'tenant_id': tenant_id })
            .then(surveys_results => {
                setSurveys(surveys_results)
                setLoading(false)
            })
            .catch(ex => {
                alert("bang")
                setLoading(false)
            })
    }

    const SurveyCard = ({ survey_data }) => {
        const [survey, setSurvey] = useState({})
        const [unmodifiedSurvey, setUnmodifiecSurvey] = useState()
        const [expand, setExpand] = useState(false)
        const [loading, setLoading] = useState(false)
        const [surveyChanged, setSurveyChanged] = useState(false)
        const [surveyBody, setSurveyBody] = useState("")
        const [parseError, setParseError] = useState("")


        const load_survey = () => {
            setLoading(true)
            send_command("ADMIN_GET_SURVEY", { "tenant_id": tenant_id, "survey_id": survey_data.survey_id })
                .then(survey_response => {
                    setSurvey(survey_response)
                    setSurveyBody(JSON.stringify(survey_response, null, 3))
                    setUnmodifiecSurvey(survey_response)
                    setLoading(false)
                })
                .catch(ex => {
                    console.error({ ex })
                    setSurvey({ "oops": "exception", "survey_id": survey_data.survey_id })
                    setLoading(false)
                })
        }
        useEffect(() => {
            load_survey()
        }, [])

        if (surveyBody.survey_type == "inclusion") {
            return (<Card elevation={3} style={{ margin: '1em' }} key={survey.survey_id}>
                <CardHeader title={survey.title} onClick={() => setExpand(!expand)}></CardHeader>
                <CardContent>
                    <Collapse in={expand}>
                        <SyntaxHighlighter language="json" >
                            {JSON.stringify(survey, null, 3)}
                        </SyntaxHighlighter>
                    </Collapse>
                </CardContent>
            </Card>)
        } else {

            const Category = ({ category }) => {

                const [showOptions, setShowOptions] = useState()
                return (
                    <>
                        <Typography variant='h5' onClick={() => setShowOptions(!showOptions)}>
                            {category.title}
                        </Typography>
                        <Collapse in={showOptions}>
                            <ul>
                                {category.options.map(option => {
                                    return (<li key={option.title}><div>
                                        <Typography>{`${option.title}: ${option.description}`}</Typography>
                                    </div></li>)
                                })}
                            </ul>
                        </Collapse>
                    </>
                )
            }

            const parse_survey = (body) => {
                return new Promise((resolve, reject) => {
                    try {
                        const parsed = JSON.parse(body)
                        resolve(parsed)
                    } catch (e) {
                        reject(e.message)
                    }
                })
            }

            const onChangeSurvey = (e) => {                
                setSurveyBody(e.target.value)

                parse_survey(e.target.value)
                    .then(res => {
                        setSurveyChanged(true)
                        console.error({ res })
                        setParseError("parsed OK")
                        setSurvey(res)
                    })
                    .catch(error => {
                        setSurveyChanged(false)
                        setParseError(error)
                    })

            }
            function download() {
                const blob = new Blob([JSON.stringify(survey, null, 3)], { type: "text/plain" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = `${survey.survey_id}.json`;
                link.href = url;
                link.click();
            }
            const save = () => { 
                send_command("ADMIN_SAVE_SURVEY", {survey})
                .then(() => setSurveyChanged(false))
                .catch((error) => {
                    console.error({error})
                    alert("failed to save")
                }
                )
                
            }
            const cancel = () => {
                setSurvey(unmodifiedSurvey)
                setSurveyBody(JSON.stringify(unmodifiedSurvey, null, 3))
                setSurveyChanged(false)
            }
            return (
                <Card elevation={3} style={{ margin: '1em' }}>
                    {loading ? <CircularProgress /> : <>
                    <CardHeader title={survey.title} onClick={() => setExpand(!expand)}></CardHeader>
                    
                        <Collapse in={expand}>
                            <CardActions>
                                <IconButton aria-label="download" onClick={download}>
                                    <CloudDownloadOutlined />
                                </IconButton>&nbsp;
                                <IconButton aria-label="save" onClick={save} disabled={!surveyChanged} >
                                    <SaveOutlined />
                                </IconButton>
                                <IconButton aria-label="cancel" onClick={cancel} disabled={!surveyChanged} >
                                    <CloseOutlined/>
                                </IconButton>
                            </CardActions>
                            <CardContent>
                                <Grid container style={{ height: "500px" }}>
                                    <Grid item xs={12}>
                                        {parseError}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box component="section" style={{ p: 2, border: '1px solid gray', overflow: 'hidden' }} height={540}>
                                            <TextField fullWidth multiline rows={30} onChange={onChangeSurvey} value={surveyBody}></TextField>
                                            {/* <JsonEditor jsonObject={surveyBody} onChange={onChangeSurvey} /> */}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box component="section" style={{ p: 2, border: '1px solid gray', overflow: 'hidden', overflowY: "scroll" }} height={540}>
                                            <ul>
                                                {survey?.categories && survey.categories.map(category => {
                                                    return (
                                                        <li key={category.key}>
                                                            <Category category={category} />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Collapse></>}


                </Card>
            )
        }

    }
    useEffect(() => {
        list_surveys()
        //console.log("in useEffect")
    }, [])
    return (
        <>{loading ? <BackdropProgress /> :
            <Card elevation={3}>
                <Button variant={"contained"} onClick={list_surveys}>Fetch</Button>&nbsp;
                <Typography>There are {surveys.length} surveys</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {surveys.map(survey => {
                            return (<SurveyCard survey_data={survey} />)
                        })}

                    </Grid>
                </Grid>

            </Card>}
        </>
    )
};

export default Surveys;