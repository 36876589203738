import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { LinearProgress } from '@material-ui/core'

import { formatPercentage } from './formatting'

const useStyles = makeStyles({
    root: {
        marginBottom: 10
    },
    label: {
        marginBottom: 10
    },
    labelPercentage: {
        height: '0.5rem',
        borderRadius: '1rem',
        marginBottom: '1px',
        fontWeight: 'bold'
    },
    largeLabelPercentage: {
        fontSize: '1.4rem'
    },
    rootBar: {
        marginBottom: 10,
        height: '1rem',
        borderRadius: '0.3rem'
    },
    barColor: {}
})

export default function PercentProgress(props) {
    const { value, label, classes: classesOverride = {}, large } = props
    const classes = useStyles()
    return React.useMemo(
        () => (
            <div className={classes.root}>
                <p className={clsx(classes.label, classesOverride.label)}>
                    <span
                        className={clsx(
                            classes.labelPercentage,
                            classesOverride.labelPercentage,
                            large && classes.largeLabelPercentage
                        )}
                    >
                        {formatPercentage(value)}%
                    </span>{' '}
                    <span>{label}</span>
                </p>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    classes={{
                        root: classes.rootBar,
                        barColorPrimary: classesOverride.bar
                    }}
                />
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, label]
    )
}
