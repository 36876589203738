import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tenantStatsData: {},
    tenantStatsError: null,
    isLoadingTenantStats: false,
    pieData: {},
    selectedCountry: [],
    selectedRegion: [],
    drilldownsData: [],
    drilldownsHierarchy: [],
    declinedAnswerData: [],
    engagement_survey_result: {}
}

export const tenantStatsSlice = createSlice({
    name: 'tenantStats',
    initialState,
    reducers: {
        fetchTenantStats: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            return {
                ...state,
                isLoadingTenantStats: true
            }
        },
        fetchTenantStatsSuccess: (state, action) => {
            return {
                ...state,
                isLoadingTenantStats: false,
                tenantStatsData: action.payload,
                pieData: action.payload.diversity?.pieData,
                selectedCountry: [],
                selectedRegion: [],
                drilldownsData: action.payload.diversity?.drilldowns_data,
                drilldownsHierarchy:
                    action.payload.diversity?.drilldowns_hierarchy,
                declinedAnswerData: action.payload.diversity?.declinedAnswers,
                employeeDiversityData:
                    action.payload.diversity?.employeeDiversity,
                hiringDiversityData: action.payload.diversity?.hiringDiversity,
                engagement_survey_result:
                    action.payload.engagement_survey?.engagement_survey_result,
                counts: action.payload.engagement_survey?.counts,
                hide: action.payload.engagement_survey?.hide
            }
        },
        fetchTenantStatsFailed: (state, action) => {
            return {
                ...state,
                isLoadingTenantStats: false,
                tenantStatsError: action.error
            }
        },
        setSelectedFilters: (state, action) => {
            let pieData = {}
            let declinedAnswerData = {
                ...action.payload.declinedAnswerData,
                caption: action.payload.declinedAnswerData.caption,
                data: [],
                insights: [],
                labels: [],
                palette: []
            }
            let hiringDiversityData = {
                ...action.payload.hiringDiversityData,
                DummyData: [
                    {
                        ...action.payload.hiringDiversityData.DummyData[0],
                        dataset: []
                    }
                ]
            }
            let employeeDiversityData = {
                ...action.payload.employeeDiversityData,
                DummyData: [
                    {
                        ...action.payload.employeeDiversityData.DummyData[0],
                        dataset: []
                    }
                ]
            }
            action.payload.filter.map(item => {
                pieData[item] = action.payload.pieData[item]
                declinedAnswerData[item] = action.payload.pieData[item]

                // Find the index of the label in the "labels" array
                const labelIndex =
                    action.payload.declinedAnswerData.labels.indexOf(
                        declinedAnswerData[item].caption
                    )
                if (labelIndex !== -1) {
                    declinedAnswerData.data.push(
                        action.payload.declinedAnswerData.data[labelIndex]
                    )
                    declinedAnswerData.labels.push(
                        action.payload.declinedAnswerData.labels[labelIndex]
                    )

                    declinedAnswerData.palette.push(
                        action.payload.declinedAnswerData.palette[labelIndex]
                    )
                }

                const indexHiring =
                    action.payload.hiringDiversityData.DummyData[0].dataset.find(
                        i => i.seriesname === item
                    )
                if (indexHiring) {
                    hiringDiversityData.DummyData[0].dataset.push(indexHiring)
                }

                const indexEmployee =
                    action.payload.employeeDiversityData.DummyData[0].dataset.find(
                        i => i.seriesname === item
                    )
                if (indexEmployee) {
                    employeeDiversityData.DummyData[0].dataset.push(
                        indexEmployee
                    )
                }
            })

            if (action.payload.filter.length === 0) {
                pieData = action.payload.pieData
                declinedAnswerData = action.payload.declinedAnswerData
                hiringDiversityData = action.payload.hiringDiversityData
                employeeDiversityData = action.payload.employeeDiversityData
            }
            if (action.payload.declinedAnswerData.length) {
            }
            return {
                ...state,
                pieData,
                declinedAnswerData,
                hiringDiversityData,
                employeeDiversityData
            }
        },
        countrySelectedFilters: (state, action) => {
            const { country, regionNoCountries } = action.payload
            // Filter the data again based on the country array
            const selectedData = {}
            country.forEach(countryKey => {
                selectedData[countryKey] =
                    action.payload.drilldownsData[countryKey]
            })
            regionNoCountries.forEach(region => {
                selectedData[region] = action.payload.drilldownsData[region]
            })
            return {
                ...state,
                selectedCountry: selectedData,
                selectedRegion: selectedData
            }
        },

        regionSelectedFilters: (state, action) => {
            const { country, region, regionNoCountries } = action.payload
            // Filter the data again based on the country array
            const selectedData = {}
            country.forEach(countryKey => {
                selectedData[countryKey.key] =
                    action.payload.drilldownsData[countryKey.key]
            })
            regionNoCountries.forEach(region => {
                selectedData[region] = action.payload.drilldownsData[region]
            })
            return {
                ...state,
                selectedRegion: region === null ? {} : selectedData,
                selectedCountry: {}
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    fetchTenantStats,
    fetchTenantStatsSuccess,
    fetchTenantStatsFailed,
    setSelectedFilters,
    countrySelectedFilters,
    regionSelectedFilters
} = tenantStatsSlice.actions

export default tenantStatsSlice.reducer
