import React from 'react'
import {
    Button,
    DialogActions,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Auth } from 'aws-amplify'

import AdminModal from '../components/admin/AdminModal'

import { useValidation } from 'components'

const useStyles = makeStyles({
    root: {
        padding: 20
    },
    text: {
        textAlign: 'center'
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    resetButton: {
        color: '#fff'
    },
    closeButton: {
        color: '#ef8c25'
    },
    alert: {
        margin: '20px 0px'
    }
})

function ActionButtons({ close, onSubmit }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.resetButton}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function ResetPasswordModal({ setOpenForgotPwModal }, ref) {
    const [open, setOpen] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const [values, setValues] = React.useState({
        code: '',
        newPassword: ''
    })

    const {
        validate,
        errors,
        ifError,
        reset: resetValidation
    } = useValidation({
        code: 'required',
        newPassword: 'required'
    })
    const [user, setUser] = React.useState('')

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
            setErrorMessage('')
        },
        [values, resetValidation]
    )

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }
            try {
                await Auth.forgotPasswordSubmit(
                    user,
                    values.code,
                    values.newPassword
                )

                close()
                setOpenForgotPwModal(false)
            } catch (error) {
                setErrorMessage(error.message)
            }
        },
        [values, validate, setOpenForgotPwModal]
    )

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: user => {
            setUser(user)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="Forgot Password">
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} className={classes.text}>
                    A reset code has been sent to the provided address.
                </Grid>
                {!!errorMessage && (
                    <Alert severity="error" className={classes.alert}>
                        {errorMessage}
                    </Alert>
                )}
                <Grid item xs={12}>
                    <TextField
                        label="Reset Code"
                        name="code"
                        value={values.code}
                        onChange={onChange}
                        error={errors.code}
                        helperText={ifError('code', 'Please fill in your code')}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="New Password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={onChange}
                        type="password"
                        error={errors.newPassword}
                        helperText={ifError(
                            'newPassword',
                            'Please fill in your new password'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onSubmit} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(ResetPasswordModal)
