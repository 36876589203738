import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Chart from './Chart'
import SimpleCard from '../SimpleCard'
import { multiplex } from '../formatting'

const useStyles = makeStyles({
    root: {
        backgroundColor: '#b2ebd9',
        boxShadow: 'none'
    },
    body: {
        padding: '0px 5px'
    },
    chart: {
        height: 350
    },
    title: {
        color: 'rgb(102, 0, 101)'
    }
})

export default function SuppliersOverviewChart({ data }) {
    const classes = useStyles()
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                labelFontColor: data.labelFontColor,
                numbersuffix: '%',
                decimals: '0',
                bgColor: data.bgColor,
                paletteColors: data.palette
            },
            data: multiplex(data)
        }

        return (
            <SimpleCard
                classes={{
                    root: classes.root,
                    body: classes.body,
                    title: classes.title
                }}
            >
                <div className={classes.chart}>
                    <Chart type="pie2d" dataSource={dataSource} />
                </div>
            </SimpleCard>
        )
    }, [data])
}
