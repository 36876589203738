import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Chart from './Chart'
import ChartCard from './ChartCard'

const useStyles = makeStyles({
    root: {
        height: '275px !important'
    },
    body: {
        padding: '0 5px'
    }
})

export default function SurveyResponseTimeChart({ data }) {
    const classes = useStyles()
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                decimals: '0',
                captionFontBold: data.captionFontBold,
                numberSuffix: '%',
                yAxisValuesStep: data.yAxisValuesStep,
                paletteColors: data.palette,
                plotSpacePercent: data.plotSpacePercent
            },
            data: data.response_times
        }
        return (
            <ChartCard classes={{ root: classes.root, body: classes.body }}>
                <Chart type="bar2d" dataSource={dataSource} />
            </ChartCard>
        )
    }, [data])
}
