import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { PercentProgress, SimpleCard } from '../../components'
import { FeedbackEngagementRateChart } from '../../components/charts'
import Divider from '../../components/Divider'

const useStyles = makeStyles(theme => ({
    root: {
        height: 700,
        padding: '1.25rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    comparisonByRegionLabelPercentage: {
        color: ({ palette }) => palette.comparisonByRegionLabelPercentage
    },
    comparisonByRegionBar: {
        backgroundColor: ({ palette }) => palette.comparisonByRegionBar
    },
    providedFeedbackLabelPercentage: {
        color: ({ palette }) => palette.providedFeedbackLabelPercentage
    },
    providedFeedbackBar: {
        backgroundColor: ({ palette }) => palette.providedFeedbackBar
    },
    divider: {
        borderColor: ({ palette }) => palette.divider
    }
}))

export default function InclusionFeedbackSummary({
    inclusionSummary,
    inclusionEngagement
}) {
    const classes = useStyles({ palette: inclusionSummary.palette })
    return (
        <SimpleCard classes={{ root: classes.root }}>
            <PercentProgress
                value={inclusionSummary.percentPositive}
                label="of inclusion feedback is positive"
                large
                classes={{
                    labelPercentage: classes.comparisonByRegionLabelPercentage,
                    bar: classes.comparisonByRegionBar
                }}
            />
            <section>
                <p>
                    On average, reporting companies in your region have{' '}
                    <strong>
                        {inclusionSummary.percentComparisonByRegion}%
                    </strong>{' '}
                    positive feedback.
                </p>
                <Divider className={classes.divider} />
                <p>
                    On average, reporting companies in your size cohort have{' '}
                    <strong>
                        {inclusionSummary.percentComparisonByCohort}%
                    </strong>{' '}
                    positive feedback.
                </p>
                <Divider className={classes.divider} />
                <p>
                    On average, reporting companies in your industry have{' '}
                    <strong>
                        {inclusionSummary.percentComparisonByIndustry}%
                    </strong>{' '}
                    positive feedback.
                </p>
            </section>
            <PercentProgress
                value={inclusionSummary.percentProvidedFeedback}
                label="of respondents provided inclusion feedback"
                large
                classes={{
                    labelPercentage: classes.providedFeedbackLabelPercentage,
                    bar: classes.providedFeedbackBar
                }}
            />
            <p>
                On average,{' '}
                <strong>
                    {inclusionSummary.percentAverageProvidedFeedback}%
                </strong>{' '}
                provide inclusion feedback.
            </p>

            <FeedbackEngagementRateChart data={inclusionEngagement} />
        </SimpleCard>
    )
}
