import { Button, Grid, Switch, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { useQueryClient } from 'react-query'

import {
    AdminTable,
    AddNewVendorModal,
    EditVendorModal,
    VendorSurveyModal
} from '../../components/admin'

import {
    useDeleteVendorMutation,
    useEnableVendorMutation,
    useTenantQuery,
    useVendorTableQuery
} from 'components/api'
import { BackdropProgress, dateFormatter } from '../../components'

import {
    CreateOutlined as EditIcon,
    DeleteOutlined as DeleteIcon,
    VisibilityOutlined as ViewIcon
} from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
    titleContainer: {
        paddingBottom: 24
    },
    addButtonContainer: {
        textAlign: 'end',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'start',
            marginTop: 8
        }
    },
    button: {
        marginRight: 4,
        color: '#fff'
    },
    icon: {
        marginRight: 8
    }
}))

function setVendorActiveInQueryData(queryClient, vendor, active) {
    const vendorData = queryClient.getQueryData(['vendor-data'])

    const record = vendorData.find(x => x.vendor_id === vendor.vendor_id)

    if (record) {
        record.active = active
    }

    queryClient.setQueryData(['vendor-data'], vendorData)
}

function SwitchFormatter({ value }) {
    const queryClient = useQueryClient()

    const {
        mutateAsync: enableVendorAsync,
        isLoading
    } = useEnableVendorMutation()

    const onChangeEnabled = React.useCallback(
        async e => {
            e.stopPropagation()
            e.preventDefault()

            const active = !e.target.checked

            setVendorActiveInQueryData(queryClient, value, active)

            try {
                await enableVendorAsync({
                    vendor_id: value.vendor_id,
                    active
                })
            } catch (error) {
                setVendorActiveInQueryData(queryClient, value, !active)
                throw error
            }
        },
        [queryClient, value, enableVendorAsync]
    )

    return (
        <Grid container>
            <Switch
                checked={value.active}
                onClick={onChangeEnabled}
                size="small"
                disabled={isLoading}
            />
        </Grid>
    )
}

function ActionFormatter({ value }) {
    const editVendorDialogRef = React.useRef()
    const { mutateAsync: deleteVendorAsync } = useDeleteVendorMutation()
    const queryClient = useQueryClient()

    const onDeleteVendor = React.useCallback(
        async e => {
            e.preventDefault()

            if (!window.confirm('Are you sure you want to delete?')) {
                return
            }
            try {
                await deleteVendorAsync(value)

                await queryClient.invalidateQueries('vendor-data')
            } catch (error) {
                throw error
            }
        },
        [value, deleteVendorAsync, queryClient]
    )

    const classes = useStyles()

    return (
        <div>
            <Button
                onClick={() => {
                    editVendorDialogRef.current.open(value)
                }}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <EditIcon fontSize="small" />
            </Button>

            <Button
                onClick={onDeleteVendor}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={value.active}
            >
                <DeleteIcon className={classes.svg} fontSize="small" />
            </Button>

            <EditVendorModal ref={editVendorDialogRef} />
        </div>
    )
}

function VendorSurvey({ vendor }) {
    const viewVendorSurveyDialogRef = React.useRef()
    const classes = useStyles()
    return (
        <div>
            <Button
                onClick={() => viewVendorSurveyDialogRef.current.open(vendor)}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <ViewIcon fontSize='small' />
            </Button>
            <VendorSurveyModal ref={viewVendorSurveyDialogRef} />
        </div>
    )
}

export default function ManageVendors() {
    const { data: vendorData, isLoading: vendorLoading } = useVendorTableQuery()
    const { data: tenantData, isLoading: tenantLoading } = useTenantQuery()
    const addVendorDialogRef = React.useRef()

    const columnData = [
        {
            field: 'vendor_name',
            headerName: 'Name',
            flex: 1,
            sortable: true
        },
        {
            field: 'created_datetime',
            headerName: 'Created',
            width: 120,
            sortable: true,
            valueFormatter: params => dateFormatter(params.value)
        },
        {
            field: 'vendor_info',
            headerName: 'Vendor Info',
            width: 120,
            sortable: true,
            renderCell: ({ row }) => <VendorSurvey vendor={row} />
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 100,
            sortable: true,
            renderCell: ({ row }) => <SwitchFormatter value={row} />
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 160,
            sortable: false,
            renderCell: ({ row }) => <ActionFormatter value={row} />
        }
    ]

    const classes = useStyles()

    if (vendorLoading || tenantLoading) {
        return <BackdropProgress />
    }

    const { tenant_name } = tenantData

    return (
        <Grid container spacing={4}>
            <Grid item container xs={12}>
                <Grid item container xs={12} className={classes.titleContainer}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" gutterBottom>
                            {tenant_name} Vendors and Service Providers
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addButtonContainer}
                    >
                        <Button
                            onClick={() => {
                                addVendorDialogRef.current.open()
                            }}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                        >
                            New Vendor
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <AdminTable columnData={columnData} rowData={vendorData} />
                </Grid>
            </Grid>
            <AddNewVendorModal ref={addVendorDialogRef} />
        </Grid>
    )
}
