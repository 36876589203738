import { Auth, API } from 'aws-amplify'
import { useMutation, useQuery } from 'react-query'

// Structure of data needed to support charts. Data should come from DB

function apiQuery(type, endpoint, payload) {
    return async function () {
        const session = await Auth.currentSession()
        const token = await session.getIdToken().getJwtToken()

        return await API.post('safedata', endpoint, {
            headers: {
                Authorization: token
            },
            body: {
                type,
                payload: payload
            }
        })
    }
}

function apiMutation(type, endpoint, payload) {
    return async function (callTimePayload) {
        const session = await Auth.currentSession()
        const token = await session.getIdToken().getJwtToken()

        return await API.post('safedata', endpoint, {
            headers: {
                Authorization: token
            },
            body: {
                type,
                payload: payload || callTimePayload
            }
        })
    }
}

function useApiQuery({ key, type, payload, endpoint }, options) {
    return useQuery(key, apiQuery(type, endpoint, payload), options)
}

function useApiMutation({ type, payload, endpoint }, options) {
    return useMutation(apiMutation(type, endpoint, payload), options)
}

function filterTenantData(result, filters) {
    function filterItem({ data, ...props }) {
        return {    
            ...props,
            data: data.filter(({ key }) => {
                return filters.length === 0 || filters.indexOf(key) !== -1
            })
        }
    }
    if (!result.data) {
        return result
    }
    var ret = {
        ...result,
        data: {
            ...result.data,
            employeeDiversity: filterItem(result.data.diversity.employeeDiversity),
            hiringDiversity: filterItem(result.data.diversity.hiringDiversity)
        }
    }
    return ret
} 
export function useTenantQuery() {
    const result = useApiQuery({
        key: 'tenant-data',
        type: 'TENANT_GET',
        endpoint: '/tenant'
    })
    console.log({result})
    return result
}

export function useTenantStatsQuery(filters) {
    const result = useApiQuery({
        key: ['tenant-stats-data', filters],
        type: 'TENANT_GET_STATS',
        endpoint: '/tenant',
        payload: {
            filters
        }
    })
    return filterTenantData(result, filters || [])    
}
export const TENANT_GET_STATS = (filters) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken

        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'VENDOR_LIST',
                payload: {filters: []}
            }
        })
    })
}

export function useTenantBrandingQuery() {
    const result = useApiQuery({
        key: 'tenant-branding-data',
        type: 'TENANT_GET_BRANDING',
        endpoint: '/tenant'
    })
    return result    
}

// admin/spid

export function useAddSpidMutation() {
    return useApiMutation({
        type: 'SPID_NEW',
        endpoint: '/tenant',
        payload: {
            count: 1
        }
    })
}

export function useMarkInvitedSpidMutation() {
    return useApiMutation({
        type: 'SPID_MARK_SENT',
        endpoint: '/tenant'
    })
}

export function useDeactivateSpidMutation() {
    return useApiMutation({
        type: 'SPID_DEACTIVATE',
        endpoint: '/tenant'
    })
}

export function useSpidTableQuery() {
    return useApiQuery({
        key: ['spid-data'],
        type: 'SPID_LIST',
        endpoint: '/tenant'
    })
}

// survey

export function useGetSurveyQuery(vendorId) {
    // TODO: Convert the survey to json and up it in the database
    // return useApiQuery(
    //     {
    //         key: ['survey-data'],
    //         type: '',
    //         endpoint: '/tenant',
    //         payload: {
    //             vendor_id: vendorId
    //         }
    //     },
    //     {
    //         retry: error => !error.response || error.response.status !== 410,
    //         refetchOnWindowFocus: false
    //     }
    // )
    return {}
}

export function useSubmitSurveyMutation() {
    return useApiMutation({
        type: 'THIRD_PARTY_SUBMIT_SURVEY',
        endpoint: '/tenant'
    })
}

export function useSaveSurveyMutation() {
    return useApiMutation({
        type: 'THIRD_PARTY_SAVE_SURVEY',
        endpoint: '/tenant'
    })
}

// admin/vendor

export function useAddVendorMutation() {
    return useApiMutation({
        type: 'VENDOR_ADD',
        endpoint: '/tenant'
    })
}

export function useEditVendorMutation() {
    return useApiMutation({
        type: 'VENDOR_EDIT',
        endpoint: '/tenant'
    })
}

export function useDeleteVendorMutation() {
    return useApiMutation({
        type: 'VENDOR_DELETE',
        endpoint: '/tenant'
    })
}

export function useEnableVendorMutation(options) {
    return useMutation(({ vendor_id, active }) => {
        const getMutate = apiMutation(
            active ? 'VENDOR_ENABLE' : 'VENDOR_DISABLE',
            '/tenant'
        )
        return getMutate({ vendor_id })
    }, options)
}

export function useVendorTableQuery() {
    return useApiQuery({
        key: ['vendor-data'],
                type: 'VENDOR_LIST',
                endpoint: '/tenant'
    })
}

// admin/user

export function useAddUserMutation(options) {
    return useApiMutation(
        {
            type: 'AUTH_ADD_USER',
            endpoint: '/tenant'
        },
        options
    )
}

export const adminWorkWithTenant = (tenant_id, username) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken

        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_WORK_WITH_TENANT',
                payload: {tenant_id, username}
            }
        })
    })
}

export const adminListTenants = () => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_LIST_TENANTS',
                payload: {}
            }
        })
    })
}

export const authDisableUser = (email) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken

        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'AUTH_DISABLE_USER',
                payload: {email}
            }
        })
    })
}
export const authEanbleUser = (email) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken

        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'AUTH_ENABLE_USER',
                payload: {email}
            }
        })
    })
}

export const authDeleteUser = (email) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'AUTH_DELETE_USER',
                payload: {email}
            }
        })
    })
}
export const authListUsers = () => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'AUTH_LIST_USERS',
                payload: {}
            }
        })
    })
}

export const TENANT_GET = () => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'TENANT_GET',
                payload: {}
            }
        })
    })
}

export const ADMIN_TENANT_GET = (tenant_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_TENANT_GET',
                payload: {'tenant_id': tenant_id}
            }
        })
    })
}

export const ADMIN_GET_CLIENT_SPIDS = (tenant_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_GET_CLIENT_SPIDS',
                payload: {'tenant_id': tenant_id}
            }
        })
    })
}

export const ADMIN_GET_APP_CLIENTS = (tenant_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_GET_APP_CLIENTS',
                payload: {'tenant_id': tenant_id}
            }
        })
    })
}
export const send_command = (command, payload) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: command,
                payload: payload
            }
        })
    })
}

export const ADMIN_SAVE_EMAIL = (tenant_id, email) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_SAVE_EMAIL',
                payload: {tenant_id, email}
            }
        })
    })
}

export const ADMIN_GET_LATEST_CLIENT_LOGS = (tenant_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_GET_LATEST_CLIENT_LOGS',
                payload: {'tenant_id': tenant_id}
            }
        })
    })
}



export const ADMIN_GET_CLIENT_LOGS = (tenant_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_GET_CLIENT_LOGS',
                payload: {'tenant_id': tenant_id}
            }
        })
    })
}

export const ADMIN_SPID_GET = (sp_id) => {
    return Auth.currentSession().then(session => {
        const token = session.getIdToken().jwtToken
        return API.post('safedata', '/tenant', {
            headers: {
                Authorization: token
            },
            body: {
                type: 'ADMIN_SPID_GET',
                payload: {'sp_id': sp_id}
            }
        })
    })
}
export function useEditUserMutation() {
    return useApiMutation({
        type: 'AUTH_EDIT_USER',
        endpoint: '/tenant'
    })
}

export function useDeleteUserMutation() {
    return useApiMutation({
        type: 'AUTH_DELETE_USER',
        endpoint: '/tenant'
    })
}

export function useEnableUserMutation(options) {
    return useMutation(({ email, enabled }) => {
        const getMutate = apiMutation(
            enabled ? 'AUTH_DISABLE_USER' : 'AUTH_ENABLE_USER' ,
            '/tenant'
        )
        return getMutate({ email })
    }, options)
}

export function useUserTableQuery() {
    return useApiQuery({
        key: ['user-data'],
        type: 'AUTH_LIST_USERS',
        endpoint: '/tenant'
    })
}
