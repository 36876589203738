import { makeStyles } from '@material-ui/styles'
import React from 'react'

import Chart from './Chart'

import { multiplex } from '../formatting'
import { DATA_GRID_PROPTYPES } from '@material-ui/data-grid'

const useStyles = makeStyles({
    root: {
        height: 200
    }
})

export default function FeedbackEngagementRateChart({ data }) {
    const classes = useStyles()
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                decimals: '0',
                xAxisName: data.xAxisName,
                showLegend: 0
            },
            data: multiplex(data)
        }

        return (
            <div className={classes.root}>
                <Chart type="line" dataSource={dataSource} />
            </div>
        )
    }, [data])
}
