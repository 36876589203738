import { styled } from '@mui/system';
import { Box, Grid, Button } from '@material-ui/core';
import "./scss/colors.scss";
import { createTheme } from '@material-ui/core/styles';
import { customColor } from './globalComponent';
export const theme = createTheme();

export const ChartMainGrid = styled(Box)({
    '& > .MuiGrid-root > .MuiGrid-grid-lg-8 + .MuiGrid-grid-lg-4': {
        '& .MuiTypography-h4': {
            textAlign: 'center',
        },
    },
    '.MuiGrid-container': {
        '& + .MuiGrid-container': {
            marginTop: 10
        },
        '& .MuiGrid-root > .MuiPaper-root': {
            minHeight: '320px',
        },
        "&:nth-child(5n+1)": {
            '.MuiGrid-grid-lg-8, .MuiGrid-grid-lg-4': {
                '.MuiPaper-root': {
                    borderTopColor: '#3D9D83',
                }
            },
        },
        "&:nth-child(5n+3)": {
            '.MuiGrid-grid-lg-8, .MuiGrid-grid-lg-4': {
                '.MuiPaper-root': {
                    borderTopColor: '#8465B7',
                }
            },
        },
        "&:nth-child(5n+4)": {
            '.MuiGrid-grid-lg-8, .MuiGrid-grid-lg-4': {
                '.MuiPaper-root': {
                    borderTopColor: '#42539F',
                }
            },
        },
        "&:nth-child(5n+5)": {
            '.MuiGrid-grid-lg-8, .MuiGrid-grid-lg-4': {
                '.MuiPaper-root': {
                    borderTopColor: '#E143E0',
                }
            },
        },
        '&.TWoColumnGrid': {
            '.MuiPaper-root': {
                height: '350px',
                [theme.breakpoints.down('md')]: {
                    height: 'auto',
                },
                '*': {
                    scrollbarWidth: 'none',
                    scrollbarColor: '#c9c9c9 #ffffff',
                },

                '*::-webkit-scrollbar': {
                    width: '5px',
                },

                '*::-webkit-scrollbar-track': {
                    background: '#ffffff',
                },

                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#03989e',
                    borderRadius: '5px',
                    border: '0px solid #ffffff',
                },
            }
        },
        '&.ThreeColumnGrid': {
            '.MuiPaper-root': {
                height: '427px',
                [theme.breakpoints.down('md')]: {
                    height: 'auto',
                },
                '.ChartInfo': {
                    height: 85,
                    overflowX: 'hidden',
                    minWidth: '60%',
                    [theme.breakpoints.down('md')]: {
                        height: 'auto',
                        minWidth: '80%',
                    },
                    [theme.breakpoints.down('sm')]: {
                        minWidth: '100%',
                    },
                },
            }
        }
    }
});

export const OverviewGrid = styled(Box)({
    '.MuiPaper-root': {
        height: '446px',
        '*': {
            scrollbarWidth: 'none',
            scrollbarColor: '#e4f1ed #ffffff',
        },

        '*::-webkit-scrollbar': {
            width: '5px',
        },

        '*::-webkit-scrollbar-track': {
            background: '#ffffff',
        },

        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#03989e',
            borderRadius: '5px',
            border: '0px solid #ffffff',
        },
    },
});

export const GridChart = styled(Grid)({
    padding: '0 25px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
        padding: 0
    },
});

export const DiversityPage = styled(Box)({
    '> .MuiBox-root': {

        '& > .MuiGrid-container': {
            marginBottom: 12,
        },
    },
    '& .MuiBox-root': {
        '& > .MuiGrid-container': {
            '& > .MuiGrid-grid-lg-4': {
                '& .fusioncharts-container': {
                },
                '& .MuiBox-root': {
                }
            },

        }
    },
    '.MuiCardHeader-root': {
        paddingTop: 15,
        '.MuiCardHeader-title': {
            '&.MuiTypography-h4': {
                fontSize: 18
            }
        }
    }
});

export const Overviewanswer = styled(Box)({
    marginBottom: 20,
    '& .MuiBox-root': {
        height: 'auto !important',
    }
});

export const Exportbtn = styled(Button)({

    border: 'none !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    backgroundColor: customColor.DataProtected3,
    color: customColor.White,
    '&:hover': {
        backgroundColor: customColor.DataProtected3,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        fontWeight: '500'
    },
    [theme.breakpoints.down('xs')]: {
    },
});

export const Nodata = styled(Box)({
    flexGrow: '1',
    alignItems: 'center',
    display: 'flex'
})