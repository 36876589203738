import { ThemeProvider, CssBaseline } from '@material-ui/core'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import Amplify from 'aws-amplify'
import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion.js'
import React from 'react'
import ReactFC from 'react-fusioncharts'
import { Switch, Route, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { TenantDashboard } from './pages/superadmin/TenantDashboard'

import {
    AuthenticationContextProvider,
    Layout,
    PrintContextProvider,
    Protected,
    UserOptionsContextProvider,
    useAuthentication
} from 'components'
import theme from 'components/theme'
import { Admin, SuperAdmin, Home, Inclusion, Login, Vendors, Survey, Diversity } from './pages'
import { UserManager } from 'pages/superadmin/UserManager'
import ManageUsers from 'pages/superadmin/ManageUsers'

if (process.env.NODE_ENV === 'development') {
    whyDidYouRender(React, {
        trackAllPureComponents: true
    })
}

Amplify.configure(window.app_config)

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)

FusionCharts.options.license({
    key: window.app_config.REACT_APP_FUSIONCHARTS_LICENSE_KEY,
    creditLabel: false
})

const queryClient = new QueryClient()

function isAppziLoaded() {
    return document.getElementById('appzi-script')
}

function loadAppzi() {
    const script = document.createElement('script')
    script.id = 'appzi-script'
    script.src = 'https://w.appzi.io/w.js?token=J0Pxf'
    script.async = true
    document.head.appendChild(script)
}

function toggleAppziButton(visible) {
    const appzi = document.querySelector('[id^=appzi-launch-button]')

    if (appzi) {
        appzi.style.display = visible ? 'block' : 'none'
    }
}

function AuthenticatedApp() {
    const user = useAuthentication()
    const isVendor =
        user.attributes && user.attributes['custom:vendor'] === 'true'

    const showAppzi = React.useCallback(() => {
        const shouldBeVisible =
            !isVendor || (isVendor && window.innerWidth >= 600)

        if (shouldBeVisible) {
            if (!isAppziLoaded()) {
                loadAppzi()
            } else {
                toggleAppziButton(true)
            }
        } else {
            toggleAppziButton(false)
        }
    }, [isVendor])

    const isAttributeTrue = (user, attribute) => {
        return user.attributes.hasOwnProperty(attribute) ? user.attributes[attribute] === "True" : false
    }
    React.useEffect(() => {
        showAppzi()

        window.addEventListener('DOMContentLoaded', showAppzi)
        window.addEventListener('resize', showAppzi)

        return () => {
            toggleAppziButton(false)
            window.removeEventListener('DOMContentLoaded', showAppzi)
            window.removeEventListener('resize', showAppzi)
        }
    }, [])
    return (
        <UserOptionsContextProvider>
            <PrintContextProvider>
                {isVendor ? (
                    <>
                        <Route exact path="/survey">
                            <Survey />
                        </Route>
                        <Redirect to="/survey" />
                    </>
                ) : (
                    <>

                        <Layout showAdmin={isAttributeTrue(user, "custom:is_admin")} showSuperAdmin={isAttributeTrue(user, "custom:is_super_admin")}>
                            <Route exact path="/">
                                <Diversity />
                            </Route>
                            <Route exact path="/inclusion">
                                <Inclusion />
                            </Route>
                            <Route exact path="/vendors">
                                <Vendors />
                            </Route>
                            <Route exact path="/admin">
                                <Admin />
                            </Route>
                            <Route path="/superadmin">
                                <SuperAdmin />
                            </Route>
                            <Route path="/users">
                                <ManageUsers />
                            </Route>

                            <Route exact path="/diversity">
                                <Home />
                            </Route>

                        </Layout>
                    </>
                )}
            </PrintContextProvider>
        </UserOptionsContextProvider >
    )
}

function App() {
    return (
        <AuthenticationContextProvider>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Switch>
                        <Route exact path="/login">
                            <Login />
                        </Route>

                        <Protected>
                            <AuthenticatedApp />
                        </Protected>
                    </Switch>
                </ThemeProvider>
            </QueryClientProvider>
        </AuthenticationContextProvider>
    )
}

export default App
