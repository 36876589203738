import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import PercentProgress from '../PercentProgress'
import SuppliersOverviewChart from '../charts/SuppliersOverviewChart'
import Divider from '../Divider'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    top: {
        marginTop: 16
    },
    mid: {
        maxWidth: '80%',
        margin: '0 auto'
    },
    chiefDiversityOfficerPercentance: {
        color: ({ palette }) => palette.chiefDiversityOfficerPercentance
    },
    chiefDiversityOfficerBar: {
        backgroundColor: ({ palette }) => palette.chiefDiversityOfficerBar
    },
    diversityProgramPercentage: {
        color: ({ palette }) => palette.diversityProgramPercentage
    },
    diversityProgramBar: {
        backgroundColor: ({ palette }) => palette.diversityProgramBar
    },
    vendorAssessmentPortalPercentage: {
        color: ({ palette }) => palette.vendorAssessmentPortalPercentage
    },
    vendorAssessmentPortalBar: {
        backgroundColor: ({ palette }) => palette.vendorAssessmentPortalBar
    },
    divider: {
        borderColor: ({ palette }) => palette.divider
    }
})

export default function VendorSurveysSummary({ data, supplierOverviewData }) {
    const classes = useStyles({ palette: data.palette })
    return React.useMemo(
        () => (
            <div className={classes.root}>
                <Typography variant="body1" align="center" gutterBottom>
                    <strong> VENDOR/SUPPLIER DIVERSITY</strong>
                </Typography>
                <div className={classes.top}>
                    <Typography variant="subtitle1" align="center">
                        <strong>{data.invited}</strong> Surveys Issued
                    </Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        <strong>{data.completed}</strong> Surveys Completed
                    </Typography>
                </div>
                <div className={classes.mid}>
                    <PercentProgress
                        value={data.percentHaveChiefDiversityOfficer}
                        label="of our Vendors have a Chief Diversity Officer or equivalent role"
                        large
                        classes={{
                            labelPercentage:
                                classes.chiefDiversityOfficerPercentance,
                            bar: classes.chiefDiversityOfficerBar
                        }}
                    />
                    <PercentProgress
                        value={data.percentHaveInclusionDiversityProgram}
                        label="of our Vendors have an Inclusion and Diversity Program"
                        large
                        classes={{
                            labelPercentage: classes.diversityProgramPercentage,
                            bar: classes.diversityProgramBar
                        }}
                    />
                    <PercentProgress
                        value={data.percentHaveDiversityGoals}
                        label="of our Vendors have set diversity goals in the Vendor Assessment Portal"
                        large
                        classes={{
                            labelPercentage:
                                classes.vendorAssessmentPortalPercentage,
                            bar: classes.vendorAssessmentPortalBar
                        }}
                    />
                </div>
                <Divider className={classes.divider} />
                <SuppliersOverviewChart data={supplierOverviewData} />
            </div>
        ),
        [data, supplierOverviewData]
    )
}
