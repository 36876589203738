import React from 'react'
import { Grid } from '@material-ui/core'
import styles from '../../assets/scss/app.module.scss'
import ChartLabels from './ChartLables'
import { GridChart } from 'assets/diversityStyle'
import FusionChart from './FusionChart'

const DonutChart = ({ data }) => {
    const response = { ...data }

    const transformedData = response.labels.map((label, index) => ({
        // label: label,
        value: response.data[index].toFixed(2), // Rounding to 2 decimal places
        //tooltext: `${label}: $percentValue` //show percentage on hover
        tooltext: response.multiselect ? `${label}` : `${label}: $percentValue`
    }))

    const dataSource = {
        chart: {
            aligncaptionwithcanvas: '0',
            captionpadding: '0',
            decimals: '0',
            showpercentvalues: '0',
            showlabels: '0',
            showvalues: '0',
            // defaultCenterLabel: `Diversity Index-${response.indexValue}% Goal Index-${response.goalIndex}%`,
            // centerLabel: '$value',
            centerLabelBold: '1',
            labelPosition: 'inside',
            labelFontColor: '#ffffff',
            theme: 'fusion',
            paletteColors: response.palette.join(), // Set the custom color palette
            chartLeftMargin: '0',
            chartRightMargin: '0',
            chartTopMargin: '0',
            chartBottomMargin: '0',
            showBorder: '0',
            showCanvasBorder: '0',
            labelWidth: '100',
            xAxisName: 'X-Axis',
            yAxisName: 'Y-Axis',
            borderwidth: '0',
            exportEnabled: '0', // Enable export
            exportFileName: 'ChartReport', // Specify the filename
            exportFormats: 'PNG;JPG;PDF', // Supported export formats
            pieRadius: '98',
            doughnutRadius: '70'
        },
        data: transformedData
    }

    return (
        <>
            <GridChart container alignItems="center" spacing={3}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    // lg={response?.insights.length ? 12 : 4}
                    lg={6}
                >
                    <FusionChart
                        type="doughnut2d"
                        width="100%" // Adjust the width of the chart
                        height="218"
                        dataFormat="JSON"
                        dataSource={dataSource}
                    />
                </Grid>
                <ChartLabels response={response} styles={styles} />
            </GridChart>
        </>
    )
}

export default DonutChart
