import {
    Button,
    Checkbox,
    DialogActions,
    Grid,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core'
import React from 'react'

import { useQueryClient } from 'react-query'

import { useAddVendorMutation } from 'components/api'

import AdminModal from '../admin/AdminModal'
import { useValidation } from 'components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    submitButton: {
        color: '#fff'
    },
    closeButton: {
        color: '#ef8c25'
    },
    invitation: {
        alignSelf: 'center'
    }
}))

function ActionButtons({ onSubmit, close }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                    >
                        Create
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

const defaultValues = {
    vendor_name: '',
    contact_name: '',
    email: ''
}

function AddNewVendorModal(props, ref) {
    const queryClient = useQueryClient()
    const { mutateAsync: addVendorAsync } = useAddVendorMutation()

    const [open, setOpen] = React.useState(false)
    const [values, setValues] = React.useState(defaultValues)

    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            vendor_name: 'required',
            contact_name: 'required',
            email: ['required', 'email']
        }
    )

    const onChange = React.useCallback(
        e => {
            const value =
                e.target.type === 'checkbox' ? e.target.checked : e.target.value

            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
        },
        [values, resetValidation]
    )

    const close = React.useCallback(() => {
        setValues(defaultValues)
        setOpen(false)
    }, [])

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }

            try {
                await addVendorAsync(values)

                await queryClient.invalidateQueries('vendor-data')

                close()
            } catch (error) {
                throw error
            }
        },
        [values, addVendorAsync, queryClient, close, validate]
    )

    React.useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="New Vendor">
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        label="Vendor"
                        name="vendor_name"
                        value={values.vendor_name}
                        onChange={onChange}
                        error={errors.vendor_name}
                        helperText={ifError(
                            'vendor_name',
                            'Please supply a vendor name.'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Contact"
                        name="contact_name"
                        value={values.contact_name}
                        onChange={onChange}
                        error={errors.contact_name}
                        helperText={ifError(
                            'contact_name',
                            'Please fill in the name of the contact'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={onChange}
                        error={errors.email}
                        helperText={ifError(
                            'email',
                            'You must specify and email to invite a vendor.'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>

                <Grid item container xs={12}>
                    <Grid item xs={2} sm={1}>
                        <Checkbox
                            name="send"
                            onChange={onChange}
                            color="primary"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={10} sm={11} className={classes.invitation}>
                        <Typography variant="subtitle1">
                            Send invitation now?
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onSubmit} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(AddNewVendorModal)
