import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    ListItem,
    ListItemText,
    Typography,
    makeStyles,
    useMediaQuery
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        padding: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 20
        }
    },
    content: {
        padding: '16px 24px'
    },
    textfield: {
        verticalAlign: 'bottom',
        paddingLeft: 8,
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px 8px 0px'
        }
    },
    buttonContainer: {
        paddingTop: 20
    },
    closeButton: {
        color: '#ef8c25'
    },
    saveButton: {
        color: '#fff'
    }
}))

function SurveyResponseModal(props, ref) {
    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('xs'))
    const [open, setOpen] = React.useState(false)
    const [vendorInfo, setVendorInfo] = React.useState({})

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: (vendor) => {
            setVendorInfo(vendor)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
            <Dialog open={open} onClose={close} fullWidth>
                <DialogTitle className={classes.title}>
                    <Typography variant="h5" gutterBottom>
                        <strong>{vendorInfo.contact_name}</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>

                    <Typography variant="body1">
                        <strong>
                            Do you comply with all local laws and
                            regulations,including those related to social
                            environmental performance?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>Yes</ListItemText>
                    </ListItem>   

                    <Typography variant="body1">
                        <strong>
                            Does your company have a code of ethics or code of
                            conduct?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>No</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Does your company have an environmental policy or
                            sustainability program?
                        </strong>
                    </Typography>
        
                    <ListItem>
                        <ListItemText>No</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Does your company have any positive practices beyond
                            what is required by local regulations?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>
                            Yes: (Please describe)
                        </ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do you track and report on any of the following
                            metrics?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>Energy usage</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Water usage</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            Hazardous / toxic air or water emissions
                        </ListItemText>
                    </ListItem>

                    <ListItem>
                        <ListItemText>
                            Generation / recycling / reduction of hazardous
                            waste
                        </ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Have you achieved any of the following?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>
                            We use at least 10% renewable energy at our
                            facilities
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            We have reduced our greenhouse gas emissions or use
                            of ozone-depleting substances by at least 10% in the
                            past two years
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            We have implemented initiatives to reduce waste at
                            the source or divert waste from
                            landfills/incineration by at least 10% in the past
                            two years
                        </ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do any of the materials and/or products we purchase
                            from you have a third-party social or environmental
                            certification?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>Yes</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do you have a third-party social or environmental
                            certification at the company level?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>No</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do your materials and/or products contain any
                            chemicals of concern?
                        </strong>
                    </Typography>

                    <ListItem>
                        <ListItemText>Yes</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Is your company located in a low-income community?
                        </strong>
                    </Typography>
                    
                    <ListItem>
                        <ListItemText>
                            Other
                        </ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do you create employment opportunities for any of
                            the following chronically underemployed populations?
                        </strong>
                    </Typography>

                    <ListItem>
                        <ListItemText>At-risk youth</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>Formerly incarcerated</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Is your company majority owned by women, people of
                            color, or individuals from other underrepresented
                            populations? *
                        </strong>
                    </Typography>

                    <ListItem>
                        <ListItemText>Yes</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            Do you have a Diversity and Inclusion Program?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>Yes</ListItemText>
                    </ListItem>

                    <Typography variant="body1">
                        <strong>
                            If yes- Do you have someone whose full time job is
                            to oversee Inclusion and Diversity?
                        </strong>
                    </Typography>
                    <ListItem>
                        <ListItemText>No</ListItemText>
                    </ListItem>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        className={classes.buttonContainer}
                        justify="flex-end"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                onClick={close}
                                className={classes.closeButton}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
    )
}

export default React.forwardRef(SurveyResponseModal)
