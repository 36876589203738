import React, { useState } from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'

import { BackdropProgress, Wrapper, usePrintLayout } from 'components'
import {
    InclusionFeedbackDiversityChart,
    InclusionInsightsChart
} from 'components/charts'
import { useReactToPrint } from 'react-to-print'

import InclusionFeedbackDiversitySummary from './InclusionFeedbackDiversitySummary'
import InclusionFeedbackSummary from './InclusionFeedbackSummary'

import { Print as PrintIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    pageHeaderText: {
        fontWeight: 'bold',
        color: ({ palette }) => palette?.pageHeaderText
    },
    inclusionFeedbackContainer: {
        paddingTop: 32
    },
    printInclusionInsightsContainer: {
        marginTop: '0.5cm'
    },
    printInclusionFeedbackContainer: {
        marginTop: '2cm'
    },
    printCenterMainContainer: {
        marginTop: '3cm'
    },
    printBreakPage: {
        margin: 'auto',
        width: 800,
        height: 600,
        pageBreakBefore: 'always'
    },
    printIcon: {
        color: ({ palette }) => palette?.printIcon
    }
})

function ContainerWrapper({ isPrinting, children }) {
    if (!isPrinting) {
        return (
            <Grid item container xs={12} spacing={3}>
                {children}
            </Grid>
        )
    }
    return children
}

function SectionWrapper({ isPrinting, md, children, className }) {
    const classes = useStyles()
    if (isPrinting) {
        return (
            <div className={className}>
                <div className={classes.printBreakPage}>{children}</div>
            </div>
        )
    }

    return (
        <Grid item xs={12} md={md}>
            {children}
        </Grid>
    )
}

export default function Inclusion() {
    const { brandingData, isLoadingBranding } = useSelector((state) => state.branding)
    const { tenantStatsData: statsData, isLoadingTenantStats: isLoadingStats } = useSelector((state) => state.tenantStats)

    const { isPrinting, setIsPrinting, renderedCharts } = usePrintLayout()

    const componentRef = React.useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setIsPrinting(false)
    })

    const onPrint = React.useCallback(() => {
        setIsPrinting(true)
    }, [])

    React.useEffect(() => {
        if (isPrinting && renderedCharts.length === 3) {
            setTimeout(() => {
                handlePrint()
            }, 1)
        }
    }, [renderedCharts])

    const classes = useStyles({
        palette: brandingData?.pages.inclusionFeedback
    })

    if (isLoadingStats || isLoadingBranding) {
        return <BackdropProgress />
    }
    const inclusionDataReady = ('inclusion' in statsData) && (Object.keys(statsData.inclusion).length > 0)
    return (
        <>
        {!inclusionDataReady&&<>
            <Wrapper
                isPrinting={isPrinting}
                handlePrint={handlePrint}
                title="Inclusion Feedback"
                variant="h5"
                palette={{
                    title: brandingData.pages.inclusionFeedback.pageHeaderText
                }}
            >
                No inclusion survey data has been processed at this time.
            </Wrapper>
        </>}
        {inclusionDataReady&&<div ref={componentRef}>
            {!isPrinting && (
                <Grid
                    item
                    container
                    xs={12}
                    justify="flex-end"
                    className={classes.printButtonContainer}
                >
                    <IconButton
                        color="primary"
                        variant="contained"
                        onClick={onPrint}
                        className={classes.printIcon}
                    >
                        <PrintIcon />
                    </IconButton>
                </Grid>
            )}

            <Wrapper
                isPrinting={isPrinting}
                handlePrint={handlePrint}
                title="Inclusion Feedback"
                variant="h5"
                palette={{
                    title: brandingData.pages.inclusionFeedback.pageHeaderText
                }}
            >
                {Object.keys(statsData.inclusion).length > 0 &&
                    <ContainerWrapper isPrinting={isPrinting}>
                        <SectionWrapper
                            isPrinting={isPrinting}
                            md={5}
                            className={
                                isPrinting &&
                                classes.printInclusionFeedbackContainer
                            }
                        >
                            <InclusionFeedbackSummary
                                inclusionSummary={statsData.inclusion.inclusionSummary}
                                inclusionEngagement={
                                    statsData.inclusion.inclusionEngagement
                                }
                            />
                        </SectionWrapper>
                        <SectionWrapper
                            isPrinting={isPrinting}
                            md={7}
                            className={
                                isPrinting &&
                                classes.printInclusionInsightsContainer
                            }
                        >
                            <InclusionInsightsChart
                                data={statsData.inclusion.inclusionFeedback}
                            />
                        </SectionWrapper>
                    </ContainerWrapper>
                }
            </Wrapper>
            <Wrapper isPrinting={isPrinting} handlePrint={handlePrint}>
                {
                    Object.keys(statsData.inclusion).length > 0 &&
                    <ContainerWrapper isPrinting={isPrinting}>
                        <SectionWrapper
                            isPrinting={isPrinting}
                            md={9}
                            className={
                                isPrinting && classes.printCenterMainContainer
                            }
                        >
                            <InclusionFeedbackDiversityChart
                                data={statsData.inclusion.inclusionFeedbackDiversity}
                            />
                        </SectionWrapper>
                        <SectionWrapper
                            isPrinting={isPrinting}
                            md={3}
                            className={
                                isPrinting && classes.printCenterMainContainer
                            }
                        >
                            <InclusionFeedbackDiversitySummary
                                data={
                                    statsData.inclusion
                                        .inclusionFeedbackDiversitySummary
                                }
                            />
                        </SectionWrapper>
                    </ContainerWrapper>
                }
            </Wrapper>
        </div>}
        </>
    )
}
