import React from 'react'

import Chart from './Chart'
import ChartCard from './ChartCard'

export default React.memo(function MultiSeriesLineChart({ data }) {
    const { data: chart } = data

    const dataSource = {
        chart: {
            caption: data.caption,
            decimals: '0',
            paletteColors: chart.map(({ key }) => data.colorPallete[key]),
            xAxisName: data.xAxisName
        },
        categories: [
            {
                category: data.labels.map(label => ({ label }))
            }
        ],
        dataset: data.data.map(x => ({
            seriesname: x.label,
            data: x.data.map(value => ({ value }))
        }))
    }

    return (
        <ChartCard>
            <Chart type="msline" dataSource={dataSource} />
        </ChartCard>
    )
})
