import React, { useEffect, useRef } from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';

// Resolves charts dependency
charts(FusionCharts);

const HiringDiversityChart = ({ data }) => {
    const chartContainerRef = useRef(null);

    useEffect(() => {
        if (data) {
            const datasetColors = data.DummyData[0].dataset.map(
                (dataset) => dataset.colorPallete
            );

            // Create a new array with modified seriesname
            const modifiedDataset = data.DummyData[0].dataset.map((dataset, index) => {
                if (index > -1) {
                    return { ...dataset, seriesname: dataset.topic };
                }
                return dataset;
            });

            const dataSource = {
                chart: {
                    theme: 'fusion',
                    xAxisName: 'Filter By Topic',
                    paletteColors: datasetColors.join(','),
                    lineThickness: '3',
                },
                categories: data.DummyData[0].categories,
                dataset: modifiedDataset,
            };

            const chartConfig = {
                type: 'msline',
                renderAt: chartContainerRef.current,
                width: '100%',
                height: '380',
                dataFormat: 'json',
                dataSource: dataSource,
            };

            const chartInstance = new FusionCharts(chartConfig);
            chartInstance.render();

            return () => {
                chartInstance.dispose();
            };
        }
    }, [data]);

    return <div ref={chartContainerRef}></div>;
};

export default HiringDiversityChart;
