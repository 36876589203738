import React from "react";
import { Grid, Box } from "@material-ui/core";

const ChartLabels = ({ response, styles, page, position, dataKey }) => {
  if (page === "stack_bar") {
    return (
      <Grid item xs={12} sm={12} md={6}
        // lg={position[dataKey] ? 12 : 6}
        lg={6}
        className={styles.DounutChartRightGrid}>
        <Box className={`${styles.ChartInfo} ChartInfo`} >
          <Box>
            {response?.map((label, index) => (
              <div key={label}>
                <span
                  className={styles.Color}
                  style={{
                    backgroundColor: label.color,
                  }}
                ></span>
                <label>{label.label}</label>
              </div>
            ))}
          </Box>
        </Box>
      </Grid>
    )
  }
  else {
    return (
      <Grid item xs={12} sm={12} md={6}
        // lg={response?.insights.length ? 12 : 8}
        lg={6}
        style={{ justifyContent: "center", display: "flex" }} className={styles.DounutChartRightGrid}>
        <Box className={`${styles.ChartInfo} ChartInfo`} >
          <Box>
            {response.labels.map((label, index) => (
              <div key={label}>
                <span className={styles.Color}
                  style={{
                    backgroundColor: response.palette[index],

                  }}
                ></span>
                <label>{label}</label><span className={styles.Percent}>{response.data[index].toFixed(2)}%</span>
              </div>
            ))}
          </Box>
        </Box>
      </Grid>
    );
  }
}

export default ChartLabels;
