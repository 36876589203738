import { useState } from "react"
import { Button, Card, Dialog, DialogActions, DialogContent, Box, Grid, TextField, IconButton, CardHeader, CardContent, Avatar } from "@material-ui/core"
import { CloseOutlined, CloudDownloadOutlined, EditTwoTone as EditIcon } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { container } from "aws-amplify";
import { Field, Form, Formik, useFormikContext } from "formik";
import { textAlign } from "@mui/system";

export const Locale = ({ tenant }) => {
    const Location = ({ location }) => {
        const [edit, setEdit] = useState(false)
        const [saveit, setSaveit] = useState({})
        const formik = useFormikContext()
        const save = (fields) => {
            setSaveit(fields)
        }
        return (
            <Card elevation={8} style={{ margin: '1em', padding: "1em" }} >
                <CardHeader
                    title={location.id}
                    action={
                        <IconButton aria-label="settings" onClick={() => setEdit(!edit)}>
                            <EditIcon size="sm"/>
                        </IconButton>
                    }
                >
                </CardHeader>
                <CardContent>
                    {edit ?
                        < Formik
                            initialValues={location}
                            onSubmit={save}
                        >
                            <Form>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Field as={TextField} fullWidth={true} variant="outlined" label="Destination" id="Destination" name="Destination" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field as={TextField} fullWidth={true} variant="outlined" label="Region" id="Region" name="Region" />
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Field as={TextField} fullWidth={true} variant="outlined" label="id" id="id" name={"id"} />
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Field as={TextField} fullWidth={true} variant="outlined" label="Language" id="Language" name="Language" />
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Field as={TextField} fullWidth={true} variant="outlined" label="Location" id="Location" name="Location" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" type="submit">Save</Button>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                        : <>
                            <table style={{ width: "100%" }}>
                                <tr><td style={{ textAlign: "right" }}>Destination:</td>
                                <td style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    }}>{location.Destination}</td></tr>
                                <tr><td style={{ textAlign: "right" }}>Region:</td><td>{location.Region}</td></tr>
                                <tr><td style={{ textAlign: "right" }}>id:</td><td>{location.id}</td></tr>
                                <tr><td style={{ textAlign: "right" }}>Language:</td><td>{location.Language}</td></tr>
                                <tr><td style={{ textAlign: "right" }}>Location:</td><td>{location.Location}</td></tr>
                            </table>
                        </>}

                </CardContent>
            </Card >
        )
    }

    return (
        <>
            <Grid container>

                {tenant?.tenant_details &&

                    tenant.tenant_details.locations.map(location => {
                        return (
                            <Grid key={location.id} item xs={4} style={{ padding: '1em' }}>
                                <Location location={location} />
                            </Grid>
                        )
                    }
                    )
                }

            </Grid>
        </>
    )
}



