import React from 'react'
import { Card } from '@material-ui/core'

export default function SimpleCard({ classes = {}, styles = {}, children }) {
    return (
        <Card className={classes.root} style={styles.root}>
            {classes.body ? (
                <div className={classes.body}>{children}</div>
            ) : (
                children
            )}
        </Card>
    )
}
