import { call, put, takeLatest } from 'redux-saga/effects'
import { FETCH_TENANT_STATS_DATA } from '../types'
import { getTenantStats } from 'services/api'
import { fetchTenantStatsSuccess } from 'redux/slices/tenantStatsSlice'
import MockDataNew from '../../../src/AFSPMockData.json'

function* fetchTenantStats(action) {
    try {
        // TODO: Temp commented to use mock data
        const filter = action.payload;
        const data = yield call(getTenantStats, filter);

        // TODO: Need to check response here
        yield put(fetchTenantStatsSuccess(data))
    } catch (e) {
        yield put(fetchTenantStatsSuccess({ message: e.message }))
    }
}

export function* watchFetchTenantStats() {
    yield takeLatest(FETCH_TENANT_STATS_DATA, fetchTenantStats)
}
