import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import { send_command } from "components/api";

ReactFC.fcRoot(FusionCharts, TimeSeries);

const dataSource = {
  chart: {},
  caption: {
    text: "Heartbeats per hour"
  },
  subcaption: {



    text: "'tenant_name'"
  },
  yaxis: [
    {
      plot: {
        value: "Heartbeats per hour"
      },
      title: "Hertbeats per hour"
    }
  ]
};

export class TheChart extends React.Component {
  constructor(props) {
    super(props);
    this.onFetchData = this.onFetchData.bind(this);
    this.state = {
      tenant_name: props.tenant_name,
      tenant_id: props.tenant_id,
      timeseriesDs: {
        type: "timeseries",
        renderAt: "container",
        width: "100%",
        height: "400",
        dataSource
      }
    };
  }

  componentDidMount() {
    this.onFetchData();
  }


  onFetchData() {
    send_command('ADMIN_GET_LATEST_HEARTBEATS', { 'tenant_id': this.state.tenant_id, 'days': 3 }).then(data => {
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        [{
          "name": "Time",
          "type": "date",
          "format": "%Y-%m-%dT%H:%M:%S"
        }, {
          "name": "Count",
          "type": "number"
        }]
      );
      const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
      timeseriesDs.dataSource.data = fusionTable;
      this.setState({
        timeseriesDs
      });
    })

  }

  render() {
    return (
      <div>
        <button onClick={this.onFetchData}>pull</button><div>{this.state.tenant_name}</div>
        {this.state.timeseriesDs.dataSource.data ? (
          <ReactFC {...this.state.timeseriesDs} />
        ) : (
          "loading"
        )}
      </div>
    );
  }
}

