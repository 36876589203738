const BRANDING_SLICE_NAME = "branding";
const TENANT_STATS_SLICE_NAME = "tenantStats";

// Example format
// export const EXAMPLE_TYPE = 'SLICE_NAME/ACTION_NAME`;

// Branding action types
export const FETCH_BRANDING_DATA = `${BRANDING_SLICE_NAME}/fetchBranding`;
export const FETCH_BRANDING_SUCCESS = `${BRANDING_SLICE_NAME}/fetchBrandingSuccess`;
export const FETCH_BRANDING_FAILED = `${BRANDING_SLICE_NAME}/fetchBrandingFailed`;

// TenantStats action types
export const FETCH_TENANT_STATS_DATA = `${TENANT_STATS_SLICE_NAME}/fetchTenantStats`;
export const FETCH_TENANT_STATS_SUCCESS = `${TENANT_STATS_SLICE_NAME}/fetchTenantStatsSuccess`;
export const FETCH_TENANT_STATS_FAILED = `${TENANT_STATS_SLICE_NAME}/fetchTenantStatsFailed`;
export const FETCH_TENANT_STATS_FILTER = `${TENANT_STATS_SLICE_NAME}/setSelectedFilters`;
export const FETCH_TENANT_STATS_COUNTRY_FILTER = `${TENANT_STATS_SLICE_NAME}/countrySelectedFilters`;
