import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Collapse, Grid, IconButton, Tooltip, Typography } from "@material-ui/core"
import { styled } from '@mui/material/styles';
import axios from "axios"
import { send_command } from "components/api"
import { useEffect, useState } from "react"
import { red } from '@mui/material/colors';
import CardActions from '@mui/material/CardActions';
import { ReloadOutlined, DownOutlined } from '@ant-design/icons';
const accounts = {
    "977807095132": "Dev",
    "629078184297": "Prod",
    "942866997229": "Demo",
    "795187495424": "DevRec",
    "340723061215": "EU"
}

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));
export const Receivers = () => {
    const [receivers, setReceviers] = useState([])
    const load = () => {
        const recs = []
        send_command("ADMIN_GET_RECEIVERS", {})
            .then(res => {
                res.map(rec => recs.push({ "url": rec }))
                setReceviers(recs)
            })
            .catch(ex => alert(ex.message))
    }

    useEffect(() => {
        load()
    }, [])
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography >Survey Receiver Status.</Typography>
            </Grid>
            <Button variant="contained" color="primary" onClick={load}>Refresh</Button>

            {
                receivers.map(rec => {
                    return (<Receiver key={rec.url} receiver={rec} />)
                })
            }
        </Grid>)
}

const Receiver = ({ receiver }) => {
    const [state, setState] = useState({ "status": "untested" })
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const check = () => {
        setState('untested')
        console.log('getting...')
        axios.get(receiver.url + "/healthcheck")
            .then(res => { setState(res.data) })
            .catch(setState({ "status": "fail" }))
    }
    const colorForState = (state) => {
        switch (state) {
            case 'success':
                return 'lightgreen'
            case 'untested':
                return "primary"
            case 'fail':
                return "red"
        }
    }
    useEffect(() => {
        check()
    }, [])
    return (
        <Grid item xs={12} sm={3}>
            <Card style={{ margin: "1em", backgroundColor: colorForState(state.status) }}>
                <CardHeader
                    avatar={
                        <Avatar area-label={state.region}>
                            {accounts[state.accountId]}
                        </Avatar>
                    }
                    title={state.region}
                    action={
                        <IconButton aria-label="refresh" onClick={check}>
                          <ReloadOutlined style={{ fontSize: '16px'}}/>
                        </IconButton>
                    }

                    subheader={
                        <Tooltip title={JSON.stringify(state)}>
                            <div>
                                {state.status}
                            </div>
                        </Tooltip>
                    }
                />
                <CardMedia
                    component="img"
                    height="100"
                    image="/Mit.png"
                />

                <CardActions disableSpacing>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <DownOutlined />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>                    
                    <CardContent>
                        <Typography>URL: {receiver.url}</Typography>
                        <Typography>Version: {state.version}</Typography>
                        <Typography>DBKey: TenantID#{state.region}</Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    )
}