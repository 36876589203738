import {
    Button,
    DialogActions,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core'

import React from 'react'

import { useQueryClient } from 'react-query'

import { useEditVendorMutation } from 'components/api'

import AdminModal from '../admin/AdminModal'
import { useValidation } from 'components'

const useStyles = makeStyles({
    root: {
        padding: 20
    },
    buttonContainer: {
        borderTop: '1px solid #dee2e6',
        paddingTop: 20
    },
    closeButton: {
        color: '#ef8c25'
    },
    inviteButton: {
        color: '#fff'
    }
})

function ActionButtons({ onSubmit, close }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.inviteButton}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function EditVendorModal(props, ref) {
    const queryClient = useQueryClient()
    const { mutateAsync: editVendorAsync } = useEditVendorMutation()
    const [open, setOpen] = React.useState(false)

    const [values, setValues] = React.useState({
        vendor_name: '',
        contact_name: '',
        email: ''
    })

    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            vendor_name: 'required',
            contact_name: 'required',
            email: ['required', 'email']
        }
    )

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
        },
        [values, resetValidation]
    )

    const onEditVendor = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }
            try {
                await editVendorAsync({
                    email: values.email,
                    vendor_id: values.vendor_id,
                    vendor_name: values.vendor_name,
                    contact_name: values.contact_name
                })

                await queryClient.invalidateQueries('vendor-data')

                close()
            } catch (error) {
                throw error
            }
        },
        [values, editVendorAsync, queryClient, validate]
    )

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: userInfo => {
            setValues(userInfo)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="Edit Vendor">
            <Grid container xs={12} spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        label="Vendor"
                        name="vendor_name"
                        value={values.vendor_name}
                        onChange={onChange}
                        error={errors.vendor_name}
                        helperText={ifError(
                            'vendor_name',
                            'Please fill in the name of the vendor'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Contact"
                        name="contact_name"
                        value={values.contact_name}
                        onChange={onChange}
                        error={errors.contact_name}
                        helperText={ifError(
                            'contact_name',
                            'Please fill in the name of the contact'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={onChange}
                        error={errors.email}
                        helperText={ifError(
                            'email',
                            'Please fill in the email'
                        )}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onEditVendor} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(EditVendorModal)
