import { API, Auth } from "aws-amplify";

async function query(type, endpoint, payload) {
    const session = await Auth.currentSession()
    const token = await session.getIdToken().getJwtToken()

    return await API.post('safedata', endpoint, {
        headers: {
            Authorization: token
        },
        body: {
            type,
            payload: payload
        }
    })
}

async function mutation(type, endpoint, payload) {
    return async function (callTimePayload) {
        const session = await Auth.currentSession()
        const token = await session.getIdToken().getJwtToken()

        return await API.post('safedata', endpoint, {
            headers: {
                Authorization: token
            },
            body: {
                type,
                payload: payload || callTimePayload
            }
        })
    }
}

function filterTenantData(result, filters) {
    function filterItem({ data, ...props }) {
        return {
            ...props,
            data: data.filter(({ key }) => {
                return filters.length === 0 || filters.indexOf(key) !== -1
            })
        }
    }
    if (!result.data) {
        return result
    }
    var ret = {
        ...result,
        data: {
            ...result.data,
            employeeDiversity: filterItem(result.data.diversity.employeeDiversity),
            hiringDiversity: filterItem(result.data.diversity.hiringDiversity)
        }
    }
    return ret
}

export function getBranding() {
    return query('TENANT_GET_BRANDING', '/tenant').then(response => response)
        .catch((error) => { throw error })
}

export function getTenant() {
    return query('TENANT_GET', '/tenant').then(response => response)
        .catch((error) => { throw error })
}

export function getTenantStats(filters) {
    return query('TENANT_GET_STATS', '/tenant', filters).then(response => {
        return filterTenantData(response, filters || [])
    }).catch((error) => { throw error })
}

export function addSPID() {
    return mutation('SPID_NEW', '/tenant', {
        count: 1
    }).then(response => response)
        .catch((error) => { throw error })
}
