import { Link } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

export default React.forwardRef(
    (
        {
            href,
            activeClassName = 'active',
            className: classNameProps,
            ...other
        },
        ref
    ) => {
        const location = useLocation()
        const pathname = typeof href === 'string' ? href : href.pathname
        const className = clsx(classNameProps, {
            [activeClassName]: location.pathname === pathname && activeClassName
        })

        return (
            <Link
                component={RouterLink}
                className={className}
                ref={ref}
                to={href}
                {...other}
            />
        )
    }
)
