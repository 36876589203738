import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    appData: {},
    loginError: null,
    isLoadingLogin: false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        login: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoadingLogin = true;
        },
        loginFailed: (state, action) => {
            return {
                ...state,
                isLoadingLogin: false,
                loginError: action.error,
            }
        },
        logout: (state, action) => {
            return {
                ...state,
                isLoadingLogin: false,
                appData: action.payload,
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { login, loginFailed, logout } = appSlice.actions

export default appSlice.reducer
