import React, { useEffect, useState } from "react"
import { Box, IconButton, Card, CardHeader, CardContent, CardActions, Collapse, Dialog, DialogContent, DialogTitle, Grid, Typography, TextField, Button, DialogActions } from "@material-ui/core"
import { CloudDownloadOutlined, FileTextOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { ADMIN_SAVE_EMAIL, send_command } from '../../components/api'
import { NewEmailDialog } from "./NewEmailDialog"
import { SuperAdminFold } from "./SuperAdminFold"


const renderEmail = (email_body) => {
    let body = ""
    try {
        body = email_body
        // body = body.replaceAll('<%= it.employee_name %>', '<b>EMPLOYEE NAME</b>')
        // body = body.replaceAll('<%= it.trust_metrics %>', '<b>XxXxXx</b>')
        // body = body.replaceAll('<%= it.spid %>', '<b>ABCD1234abcd123</b>')
        // body = body.replaceAll('<%= it.sp_id || "" %>', '<b>XXXXXXXXXXXX</b>')
        // body = body.replaceAll('<%= it.company_name %>', 'COMPANY NAME')
        body = body.replaceAll('<%= it.tenant_img %>', "https://s3.us-west-2.amazonaws.com/cdn.dataprotected.com/Logo.png")
        //body = body.replaceAll('<%=  %>', '')
    } catch (ex) {
        console.error({ ex })
        body = "!! Email body parse error !!"
    }
    return body

}


export const Emails = ({ tenant_id, logo }) => {
    const [showAddEmail, setShowAddEmail] = useState(false)
    const [emails, setEmails] = useState()
    const download = (body, title_prefix) => {
        const blob = new Blob([body], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${title_prefix}${String(new Date().toISOString())}.txt`;
        link.href = url;
        link.click();
    }

    const backup_emails = () => {
        let backup = ""
        emails.map(email => {
            backup += "\n===================================================\n"
            backup += ` Type: ${email.email_type}\tLanguage: ${email.language}`
            backup += "\n===================================================\n"
            backup += `Subject: ${email.subject}\n`
            const body_text = decodeURIComponent(escape(window.atob((email.body))))
            backup += `----------------Body---------------- \n${body_text}\n`
        })
        download(backup, "backup")
    }
    const export_all = () => {
        const exports = []
        console.log(emails)
        // alert(JSON.stringify(emails))
        emails.map(email => {
            exports.push(email)
        })
        // emails.map(email => {
        //     exports[email.email_type][email.language] = {
        //         body: email.body,
        //         subject: email.subject
        //     }
        // })

        // Object(emails).keys().map(key => {
        //     alert(JSON.stringify(key))
        // })
        download(JSON.stringify(emails, null, 3), "export")
    }

    const add_email = () => {
        setShowAddEmail(true)
    }
    const fetchEmails = () => {
        send_command('ADMIN_GET_TENANT_EMAILS', {"tenant_id": tenant_id})
        .then (emails => {
            const tree = {}
            emails.map(email => {
                tree[email.email_type] = {}
            })
            emails.map(email => {
                tree[email.email_type][email.language] = email
            })
            emails.map(email => {
                tree[email.email_type][email.language] = email
            })
            
            
            setEmails(tree)})
        .catch(er => alert(er.message)) 
    }
    useEffect(() => {
        fetchEmails()
    }, [])
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CardActions disableSpacing>
                    <Button variant="contained" endIcon={<CloudDownloadOutlined />} onClick={backup_emails}>Backup</Button>&nbsp;
                    <Button variant="contained" endIcon={<FileTextOutlined />} onClick={export_all} >Export all</Button>&nbsp;
                    <Button variant="contained" endIcon={<FileTextOutlined />} onClick={add_email} >Add Email</Button>&nbsp;
                </CardActions>
            </Grid>

            {emails ?<>

                <Grid item xs={12}>
                    {Object.keys(emails).map(email_type => {
                        return (
                            <>
                            {<SuperAdminFold title={email_type} count={Object.keys(emails[email_type]).length}>
                                <>
                                    {Object.keys(emails[email_type]).map(language => {
                                        const email = emails[email_type][language]
                                        
                                        return(<EmailCard tenant_id={tenant_id} email={email} logo={logo} />)
                                    })}
                                </>
                            </SuperAdminFold>}
                            </>)
                    })}


{/* <EmailCard tenant_id={tenant_id} email={email} logo={logo} /> */}
                </Grid></>
                : <Grid item xs={12}>No emails found</Grid>}
            <NewEmailDialog showDialog={showAddEmail} closeDialog={setShowAddEmail} tenant_id={tenant_id}/>
        </Grid>
    )
}


export function EmailCard({ tenant_id, email, logo }) {
    const [show, setShow] = React.useState(false)
    const [body, setBody] = React.useState("")
    const [subject, setSubject] = React.useState("")
    const [bodyChanged, setBodyChanged] = React.useState(false)

    const sourceChange = (new_text) => {
        setBodyChanged(true)
        setBody(new_text)
    }
    const subjectChange = (new_text) => {
        setBodyChanged(true)
        setSubject(new_text)
    }
    const save = (email, newbody) => {
        const encoded = window.btoa(unescape(encodeURIComponent(newbody)))
        const email_record = {
            "body": encoded,
            "subject": subject,
            "language": email.language,
            "email_type": email.email_type
        }

        ADMIN_SAVE_EMAIL(tenant_id, email_record)
            .then((res) => {
                setBodyChanged(false)
                alert("saved")
            })
            .catch(exception => {
                alert("failed to save")
            })
    }

    const delete_email = (email) => {
        const ok_delete = window.confirm(`Delete ${email.email_type} : ${email.language}`)
        if (ok_delete) {

            send_command(
                'ADMIN_DELETE_EMAIL',
                {
                    tenant_id: tenant_id,
                    type: email.email_type,
                    language: email.language
            }).then (res => {
                alert("deleted")                
            }).catch(error => {
                alert(error.response.data)
            })
        }
    }

    function download() {
        const blob = new Blob([decodeURIComponent(escape(window.atob((email.body))))], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${email.email_type}_${email.language}.html`;
        link.href = url;
        link.click();
    }
    React.useEffect(() => {
        let thebody = "Email missing or can't be decoded"
        try {
            thebody = decodeURIComponent(escape(window.atob(email.body)))
        } catch (error) { 
            console.error("jonah")
            console.error({error})
        }
        setBody(thebody)
        setSubject(email.subject)
    }, [email])
    return (
        <Card elevation={10} style={{ margin: '1em' }}>
            <CardHeader
                onClick={() => setShow(!show)}                
                title={`Language: ${email.language}`}
                subheader={`Type: ${email.email_type}`}
            />
            <Collapse in={show}>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        <Typography variant="h5" noWrap >
                            Subject: <TextField fullWidth multiline onChange={(e) => subjectChange(e.target.value)} value={subject}></TextField>
                        </Typography><br />

                        <Box component="section" sx={{ p: 2, border: '1px solid gray' }} height={600}>
                            <Grid container>
                                <Grid item lg={6}>
                                    <Box component="section" sx={{ p: 2, border: '1px solid gray', overflow: 'hidden', overflowY: "scroll" }} height={540}>
                                        <TextField fullWidth multiline onChange={(e) => sourceChange(e.target.value)} value={body}></TextField>
                                    </Box>
                                </Grid>
                                <Grid item lg={6}>
                                    <Box component="section" sx={{ p: 2, border: '1px solid gray', overflow: 'hidden', overflowY: "scroll" }} height={540}>
                                        {<div dangerouslySetInnerHTML={{ __html: renderEmail(body) }} />}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Typography>
                </CardContent>
                <CardActions disableSpacing>

                    <IconButton aria-label="download" onClick={download}>
                        <CloudDownloadOutlined />
                    </IconButton>&nbsp;
                    <IconButton aria-label="save" onClick={() => save(email, body)} disabled={!bodyChanged} >
                        <SaveOutlined />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => delete_email(email)} >
                        <DeleteOutlined />
                    </IconButton>
                </CardActions>
            </Collapse>
        </Card>
    );
}

