import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Grid, Typography } from "@material-ui/core";
import { useState, createContext, useContext } from "react";


export const CardContext = createContext()

export const DashboardCard = ({ children, title, xs, sm, md, lg, xl }) => {
    const [condenced, setCondenced] = useState(true)

    return (<>
        <CardContext.Provider value={condenced}>
            <Grid item sm={condenced ? 3 : 12}>

                <Card elevation={2} style={{ padding: "1em" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="textSecondary" onClick={() => setCondenced(!condenced)}>
                                {title} {condenced?<UpOutlined/>:<DownOutlined/>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {children}
                        </Grid>

                    </Grid>
                </Card>
            </Grid>
        </CardContext.Provider>
    </>)
}

const Foo = () => {
    const user = useContext(CardContext)
    return (
        <>hi {JSON.stringify(user)}</>
    )
}