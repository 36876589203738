import { Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core'
import { TabPanel, TabContext } from '@material-ui/lab'
import React from 'react'

import ManageVendors from './ManageVendors'
import ManageUsers from './ManageUsers'
import Statistics from './Statistics'
import SPIDs from './SPIDs'

const useStyles = makeStyles(theme => ({
    tab: {
        padding: 0
    },
    tabContainer: {
        paddingRight: 24
    },
    hoverTab: {
        "&:hover": {
            color: theme.palette.primary.main,
            cursor: 'pointer'
          }
    },
    activeTab: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
}))

const menuItems = [
    'Overview',
    'Manage IDs',
    'Manage Vendors',
    'Manage Users' /*"Settings"*/
]

export default function Overview() {
    const [activeTab, setActiveTab] = React.useState('Overview')
    const classes = useStyles()
    return (
        <>            
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">Admin Home</Typography>
                </Grid>
                <Grid item md={2} className={classes.tabContainer}>
                    <List>
                        {menuItems.map((item, key) => {
                            return (
                                <ListItem
                                    key={key}
                                    className={
                                        item === activeTab
                                            ? classes.activeTab
                                            : classes.hoverTab
                                    }
                                    onClick={() => {
                                        setActiveTab(item)
                                    }}
                                >
                                    {item}
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
                <Grid item md={10}>
                    <Pages activeTab={activeTab} />
                </Grid>
            </Grid>
        </>
    )
}

function Pages({ activeTab }) {
    const classes = useStyles()
    return (
        <>
            <TabContext value={activeTab}>
                <TabPanel value="Overview" className={classes.tab}>
                    <Statistics />
                </TabPanel>
                <TabPanel value="Manage IDs" className={classes.tab}>
                    <SPIDs />
                </TabPanel>
                <TabPanel value="Manage Vendors" className={classes.tab}>
                    <ManageVendors />
                </TabPanel>
                <TabPanel value="Manage Users" className={classes.tab}>
                    <ManageUsers />
                </TabPanel>
                <TabPanel value="Settings">{/* <Settings /> */}</TabPanel>
            </TabContext>
        </>
    )
}
