import React from 'react'

import Chart from './Chart'
import ChartCard, { ChartCardFooter } from './ChartCard'
import { multiplex } from '../formatting'

export default React.memo(function PieChart({ data, rootClass }) {
    const dataSource = {
        chart: {
            caption: data.caption,
            decimals: '0',
            numbersuffix: '%',
            captionFontColor: data.captionFontColor,
            paletteColors: data.palette
        },
        data: multiplex(data)
    }

    const footer = (
        <ChartCardFooter>
            {data.insights?.map((insight, index) => {
                return (
                    <p key={index}>
                        <strong>{insight.value}</strong>&nbsp;
                        {insight.description}
                    </p>
                )
            })}
        </ChartCardFooter>
    )

    return (
        <ChartCard footer={footer} classes={{ root: rootClass }}>
            <Chart type="pie2d" dataSource={dataSource} />
        </ChartCard>
    )
})
