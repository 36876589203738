import { call, put, takeLatest } from 'redux-saga/effects'
import { FETCH_BRANDING_DATA } from './../types'
import { getBranding } from 'services/api';
import { fetchBrandingFailed, fetchBrandingSuccess } from 'redux/slices/brandingSlice';

function* fetchBranding(action) {
    try {
        const data = yield call(getBranding);
        // TODO: Need to check response here
        yield put(fetchBrandingSuccess(data));
    } catch (e) {
        yield put(fetchBrandingFailed({ error: e.message }));
    }
}

export function* watchFetchBranding() {
    yield takeLatest(FETCH_BRANDING_DATA, fetchBranding)
}
