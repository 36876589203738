import { combineReducers } from 'redux'
import appReducer from './appSlice'
import brandingReducer from './brandingSlice'
import tenantStatsReducer from './tenantStatsSlice'

const rootReducer = combineReducers({
    branding: brandingReducer,
    tenantStats: tenantStatsReducer,
    app: appReducer,
})

export default rootReducer;
