import React from 'react'
import { Box } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core'
import { Overviewanswer } from 'assets/diversityStyle'
import { AnsBox, AnsTotal, SfLabel } from 'assets/globalComponent'
import { withStyles } from '@material-ui/core/styles'

/* const useStyles = makeStyles(theme => ({
    customLinearProgress: {
        // backgroundColor: 'white', // Background color for .MuiLinearProgress-root
        color: 'blue',
        height: '9px', // Height for .MuiLinearProgress-root
        overflow: 'hidden', // Overflow property for .MuiLinearProgress-root
        position: 'relative' // Position property for .MuiLinearProgress-root
    },
    customColorPrimary: {
        backgroundColor: 'white' // Updated background color
    }
}))

const data = {
    caption: 'Declined Answers',
    palette: [
        '#FF724F',
        '#D477DF',
        '#5A7B96',
        '#763B84',
        '#DF3177',
        '#3277BF',
        '#3D9D83',
        '#48C66B',
        '#58C1BB'
    ], //need more back ground color for this currently used dummy colors for test
    data: [80, 80, 10, 70, 70, 50, 80, 80, 70, 90, 60, 90],
    insights: [],
    labels: [
        'Gender Identity',
        'Race | Ethnicity | Cultural Heritage',
        'Standardized Race Categories',
        'Sexual Orientation',
        'Age',
        'Caregiver Status',
        'Disability',
        'Partnership Status',
        'Veteran Status',
        'Please indicate which location are you affiliated with',
        'When was your last promotion at our organization?',
        'What organizational group do you work in?'
    ]
} */

export const SfProgressBarOne = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '100%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: props => props.backgroundColor
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

const DeclineAnswersChart = ({ data }) => {
    return (
        <div style={{ width: '100%', height: '350px', overflowY: 'auto' }}>
            {/* Adjust the width and height as needed */}
            <Overviewanswer>
                {data.labels.map((label, index) => (
                    <Box key={label}>
                        <AnsBox>
                            <SfProgressBarOne
                                variant="determinate"
                                value={data.data[index]}
                                backgroundColor={data.palette[index]}
                            />
                            <AnsTotal>{data.data[index]}</AnsTotal>
                        </AnsBox>
                        <SfLabel>{label}</SfLabel>
                    </Box>
                ))}
            </Overviewanswer>
        </div>
    )
}

export default DeclineAnswersChart
