import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    ListItem,
    TextField
} from '@material-ui/core'

import React from 'react'

function isChecked(array, opt) {
    return array.indexOf(opt) !== -1
}

export default function CheckBoxSelection({
    values,
    textfieldValues,
    value,
    isSmallDevice,
    onChange,
    onMultipleChange,
    name,
    classes,
    ifError,
    errors
}) {
    return (
        <FormGroup>
            <ListItem>
                <FormControlLabel
                    value={values}
                    control={
                        <Checkbox
                            name={name}
                            value={value.value}
                            checked={isChecked(values, value.value)}
                            onChange={onMultipleChange}
                            size={isSmallDevice ? 'small' : 'medium'}
                        />
                    }
                    label={value.label}
                />
                {name === 'employmentOpportunities' &&
                    value.value === 'other' && (
                        <TextField
                            name="employmentOpportunitiesDescription"
                            value={textfieldValues}
                            onChange={onChange}
                            disabled={!isChecked(values, 'other')}
                            className={classes.textfield}
                            error={
                                isChecked(values, 'other') &&
                                errors.employmentOpportunitiesDescription
                            }
                            helperText={
                                isChecked(values, 'other') &&
                                ifError(
                                    'employmentOpportunitiesDescription',
                                    'Please describe'
                                )
                            }
                        />
                    )}
            </ListItem>
        </FormGroup>
    )
}