import React from 'react'
import {
    CardHeader,
    CardContent,
    IconButton,
    Typography
} from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import { CardOne, OutputText } from 'assets/globalComponent'
import { Nodata } from 'assets/diversityStyle'

const InsightsCard = data => {
    return (
        <CardOne redbdr minheight hasborder>
            <CardHeader
                action={
                    <IconButton>
                        <LaunchIcon />
                    </IconButton>
                }
                titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                title={
                    data?.title === 'Gender'
                        ? 'Gender Identity and Sexual Orientation Insights'
                        : `${`${data?.title} Insights`}`
                }
            />
            <CardContent>
                <OutputText spanorange>
                    {data?.data.map((item, index) => (
                        <React.Fragment key={index}>
                            <Typography>
                                <span
                                    style={{
                                        color: item.color
                                    }}
                                >
                                    {item.value}
                                </span>{' '}
                                {item.description}
                            </Typography>
                        </React.Fragment>
                    ))}
                    {data?.data.length === 0 && (
                        <Nodata key="NODATA">
                            <Typography>Not applicable</Typography>
                        </Nodata>
                    )}
                </OutputText>
            </CardContent>
        </CardOne>
    )
}

export default InsightsCard
