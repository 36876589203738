import { CloseCircleOutlined } from "@ant-design/icons"
import { IconButton, Snackbar } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useState } from "react"
import React from 'react'



export const ResultNotification = ({ initial_success, initial_error }) => {
    const [error, setError] = useState(initial_error)
    const [success, setSuccess] = useState(initial_success)
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setError(null)}
            >
                <CloseCircleOutlined fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (<>
        {error ?
            <Snackbar
                open={error != null}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setError(null)}
                action={action}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Whoa!</AlertTitle>
                    {error}
                </Alert>

            </Snackbar> :
            <Snackbar
                open={success != null}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setSuccess(null)}
                action={action}
            >
                <Alert
                    onClose={() => setSuccess(null)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>Yay!</AlertTitle>
                    {error}
                </Alert>

            </Snackbar>}</>)
}