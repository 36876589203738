import { Typography } from '@material-ui/core'

import React from 'react'

export default function Question({ title, subtitle, required }) {
    return (
        <>
            <Typography variant="body1">
                <strong>
                    {title}
                    {required ? '*' : null}
                </strong>
            </Typography>
            <Typography variant="body2">{subtitle}</Typography>
        </>
    )
}
