const styles = theme => ({
    root: {
        paddingTop: 250
    },
    iconContainer: {
        textAlign: 'center'
    },
    icon: {
        width: 200,
        height: 200,
        color: 'limegreen'
    },
    text: {
        textAlign: 'center'
    },
    buttonContainer: {
        textAlign: 'center',
        marginTop: 20
    },
    button: {
        color: '#fff',
    }
})

export default styles