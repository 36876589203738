import {
    Button,
    DialogActions,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core'
import React from 'react'

import { useQueryClient } from 'react-query'

import { useEditUserMutation } from 'components/api'

import AdminModal from '../admin/AdminModal'
import { useValidation } from 'components'

const useStyles = makeStyles({
    root: {
        padding: 20
    },
    textfield: {
        marginTop: 10
    },
    disabledInput: {
        backgroundColor: '#e9ecef'
    },
    buttonContainer: {
        paddingTop: 20
    },
    closeButton: {
        color: '#ef8c25'
    },
    saveButton: {
        color: '#fff'
    }
})

function ActionButtons({ onSubmit, close }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.saveButton}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function EditUserModal(props, ref) {
    const queryClient = useQueryClient()
    const { mutateAsync: editVendorAsync } = useEditUserMutation()

    const [open, setOpen] = React.useState(false)
    const [values, setValues] = React.useState({
        given_name: '',
        family_name: ''
    })

    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            given_name: 'required',
            family_name: 'required'
        }
    )

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            setValues({
                ...values,
                [e.target.name]: value
            })
            resetValidation()
        },
        [values, resetValidation]
    )

    const onEditUser = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }
            try {
                await editVendorAsync({
                    user: values,
                    fields: {
                        given_name: values.given_name,
                        family_name: values.family_name,
                        email: values.email
                    }
                })

                await queryClient.invalidateQueries('user-data')

                close()
            } catch (error) {
                throw error
            }
        },
        [values, editVendorAsync, queryClient, validate]
    )

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: userInfo => {
            setValues(userInfo)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="Edit User">
            <Grid container className={classes.root}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        First Name
                        <TextField
                            name="given_name"
                            value={values.given_name}
                            onChange={onChange}
                            error={errors.given_name}
                            helperText={ifError(
                                'given_name',
                                'Please fill in the first name of the user'
                            )}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            className={classes.textfield}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Last Name
                        <TextField
                            name="family_name"
                            value={values.family_name}
                            onChange={onChange}
                            error={errors.family_name}
                            helperText={ifError(
                                'family_name',
                                'Please fill in the last name of the user'
                            )}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            className={classes.textfield}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        Email
                        <TextField
                            name="email"
                            value={values.email}
                            onChange={onChange}
                            type="email"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            disabled
                            className={classes.disabledInput}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ActionButtons onSubmit={onEditUser} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(EditUserModal)
