import { styled } from '@mui/system'
import { Card, Box } from '@material-ui/core'
import './scss/colors.scss'
import { createTheme, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Typography } from '@material-ui/core'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#ff0000',
            light: '#9a0036'
        },
        secondary: {
            main: '#d8e7e2'
        },
        primaryLight: {
            default: '#dbece2'
        }
    }
})

export const customColor = createTheme({
    DataProtected1: '#70d9b8',
    DataProtected2: '#ef8c25',
    DataProtected3: '#fa6c51',
    DataProtected4: '#ebbf44',
    DataProtected5: '#6cbf84',
    SafePorter: '#03989e',
    DarkGrey: '#5e5e5e',
    Gray: '#8a8a8a',
    DarkGrey2: '#494949',
    BlueGray: '#ccccd1',
    Mint1: '#e4f1ed',
    Mint2: '#d8e7e2',
    Mint3: '#c8f0e5',
    LightGray: '#f0f0f0',
    BlueLightGray: '#eef0f4',
    LightLightGray: '#ededed',
    Black: '#2e2e2e',
    White: '#ffffff',
    DgRace: '#3D9D83',
    DgGen: '#E46C52',
    DgAge3: '#42539F',
    DgDisability: '#8465B7',
    DgVolenteer: '#EA2C2C',
    DgBoardMember: '#640BA8',
    DgOficerPosition: '#74C9DC',
    DgOcupation: '#4C7F46',
    DgPrimaryLanguage: '#BCEFA1',
    DgAdditionalLanguage: '#009AA2',
    DgHieghestLevel: '#009AA2',
    DgReligiousAffiliation: '#4C7F46',
    DgMilitryStatus: '#D9C408',
    DgTiesToMission: '#4C7F46',
    DgProunoun: '#F89125',
    DgInvert1: '#E143E0',
    Purple: '#8465B7',
    Red: '#D40A0A',
    Red2: '#FA6C51',
    StronglyPositive: '#207C5F',
    Positive: '#55B496'
})

export const AppSideBar = styled('aside')({
    backgroundColor: customColor.White,
    display: 'flex',
    '&.FrontPage': {
        display: 'inherit',
        '.LinkContainer': {
            height: 'auto'
        }
    },
    '.MuiListItemText-root': {},
    '.MuiListItem-root': {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        padding: 10,
        textTransform: 'inherit',
        borderRadius: 8,
        color: customColor.DarkGrey,
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: customColor.DataProtected1
        },
        '&.Mui-selected': {
            backgroundColor: customColor.DataProtected1,
            color: '#000000'
        },
        svg: {
            marginRight: 10
        }
    },
    '& .MuiAccordion-root': {
        borderBottom: '1px solid',
        borderBottomColor: customColor.BlueGray,
        borderRadius: 0,
        '&:last-child': {
            borderBottom: 0
        },
        '.MuiAccordionSummary-root': {
            padding: '0 16px',
            '.MuiAccordionSummary-content': {
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                color: customColor.Gray,
                '&.Mui-expanded': {
                    margin: '12px 0'
                }
            }
        },
        '.MuiAccordionDetails-root': {
            padding: 0,
            whiteSpace: 'normal',
            '& .MuiFormControlLabel-root': {
                margin: 0,
                '&.StaticColor': {
                    '&:nth-child(1n)': {
                        '& .MuiSvgIcon-root': { color: customColor.DgRace }
                    },
                    '&:nth-child(2n)': {
                        '& .MuiSvgIcon-root': { color: customColor.DgGen }
                    },
                    '&:nth-child(3n)': {
                        '& .MuiSvgIcon-root': { color: customColor.DgAge3 }
                    },
                    '&:nth-child(4n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgDisability
                        }
                    },
                    '&:nth-child(5n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgVolenteer
                        }
                    },
                    '&:nth-child(6n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgBoardMember
                        }
                    },
                    '&:nth-child(7n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgOficerPosition
                        }
                    },
                    '&:nth-child(8n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgOcupation
                        }
                    },
                    '&:nth-child(9n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgPrimaryLanguage
                        }
                    },
                    '&:nth-child(10n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgAdditionalLanguage
                        }
                    },
                    '&:nth-child(11n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgHieghestLevel
                        }
                    },
                    '&:nth-child(12n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgReligiousAffiliation
                        }
                    },
                    '&:nth-child(13n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgMilitryStatus
                        }
                    },
                    '&:nth-child(14n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgTiesToMission
                        }
                    },
                    '&:nth-child(15n)': {
                        '& .MuiSvgIcon-root': {
                            color: customColor.DgProunoun
                        }
                    }
                },
                '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: customColor.Gray
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
                '.MuiAccordionSummary-expandIcon': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        '&.Mui-expanded': {
            paddingBottom: 10,
            margin: 0
        },
        '&:before': {
            opacity: '1 !important',
            display: 'none !important'
        }
    }
})

export const Heading = styled(Typography)(({ }) => ({
    fontStyle: 'normal',
    fontWeight: 600,
    color: customColor.Gray,
    lineHeight: 1,
    display: 'block',
    marginBottom: 27,
    fontFamily: '"Lato", sans-serif !important',
    [theme.breakpoints.down('md')]: {
        lineHeight: 1.2
    },
    '&.MuiTypography-h3': {
        fontSize: 20,
        color: customColor.Black,
        marginBottom: 24,
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            marginBottom: 20
        }
    },
    '&.MuiTypography-h5': {
        fontSize: 20
    },
    '&.MuiTypography-h6': {
        fontSize: 18,
        marginBottom: 18,
        [theme.breakpoints.down('md')]: {
            fontSize: 16
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: 16,
        lineHeight: 1.2
    }
}))

export const NumHeading = styled(Typography)(({ green, red }) => ({
    fontSize: 42,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 0.75,
    marginTop: 18,
    color: green
        ? customColor.SafePorter
        : red
            ? customColor.DataProtected3
            : customColor.Black,
    [theme.breakpoints.down('md')]: {
        fontSize: 35,
        marginBottom: 17
    },
    [theme.breakpoints.down('sm')]: {
        marginBottom: 15
    }
}))

export const CardOne = styled(Card)(
    ({
        hasborder,
        minheight,
        greenbdr,
        redbdr,
        purplebdr,
        bluebdr,
        pinkbdr,
        fixedheight
    }) => ({
        backgroundColor: customColor.White,
        boxShadow: 'none',
        borderRadius: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        borderTopWidth: hasborder ? 8 : '0',
        borderColor: greenbdr
            ? customColor.DgRace
            : redbdr
                ? customColor.DgGen
                : purplebdr
                    ? customColor.Purple
                    : bluebdr
                        ? customColor.DgAge3
                        : pinkbdr
                            ? customColor.DgInvert1
                            : customColor.Black,
        borderTopStyle: 'solid',
        [theme.breakpoints.down('sm')]: {
            //minHeight: minheight ? '350px' : '',
        },
        '& .MuiCardHeader-root': {
            padding: '24px 24px 0 24px',
            marginBottom: 15,
            [theme.breakpoints.down(1366)]: {
                padding: '20px 20px 0 20px'
            },
            [theme.breakpoints.down('md')]: {
                padding: '15px 15px 0 15px',
                marginBottom: 10
            },
            [theme.breakpoints.down('md')]: {
                padding: '15px 15px 0 15px',
                marginBottom: 15
            },
            '& .MuiCardHeader-content': {
                marginRight: 25
            },
            '& .MuiCardHeader-title': {
                fontStyle: 'normal',
                fontWeight: 500,
                color: customColor.DarkGrey2,
                lineHeight: 1.3,
                display: 'block',
                [theme.breakpoints.down('md')]: {
                    lineHeight: 1.2,
                    fontSize: 18
                },
                [theme.breakpoints.down('sm')]: {
                    lineHeight: 1.4,
                    fontSize: 18
                },
                '&.MuiTypography-h4': {
                    fontSize: 20,
                    [theme.breakpoints.down(1366)]: {
                        fontSize: 18,
                        lineHeight: 1.3
                    },
                    [theme.breakpoints.down('md')]: {
                        fontSize: 16
                    }
                },
                '&.MuiTypography-h5': {
                    fontSize: 18
                }
            }
        },
        '& .MuiCardContent-root': {
            padding: '0px 24px 24px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                padding: '0px 15px 15px',
                marginBottom: 10
            }
        },
        '& .MuiCardHeader-action': {
            position: 'absolute',
            right: 15,
            top: 15
        }
    })
)

export const Week = styled(Typography)({
    fontSize: 18,
    lineHeight: 0.8,
    fontWeight: 600,
    color: customColor.Gray,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
        fontSize: 16
    }
})

export const WeekPercent = styled(Typography)({
    fontSize: 18,
    lineHeight: 0.75,
    fontWeight: 700,
    color: customColor.Black,
    marginBottom: 22
})

export const SfProgressBar = withStyles(() => ({
    root: {
        height: 10,
        borderRadius: 8
    },
    colorPrimary: {
        backgroundColor: customColor.BlueLightGray
    },
    bar: {
        borderRadius: 5,
        backgroundColor: customColor.SafePorter
    }
}))(LinearProgress)

export const SfProgressBarOne = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '100%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: customColor.DgAge3
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

export const SfProgressBarTwo = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '50%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: customColor.DgGen
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

export const SfProgressBarThree = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '70%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: customColor.DgRace
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

export const SfProgressBarFour = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '60%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: customColor.Purple
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

export const SfProgressBarFive = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 0,
        width: '90%',
        marginRight: 8
    },
    colorPrimary: {
        backgroundColor: 'transparent'
    },
    bar: {
        borderRadius: 0,
        backgroundColor: customColor.DgInvert1
    },
    '& .MuiLinearProgress-root': {
        width: '100%'
    }
}))(LinearProgress)

export const ProgressBox = styled(Box)({
    '& > div': {
        '& + div': {
            marginTop: 7
        }
    },
    '& h3': {
        margin: ' 0 24px 0 0',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 0.75,
        [theme.breakpoints.down('md')]: {
            fontSize: 24
        }
    }
})

export const TimeGrid = styled(Box)({
    marginTop: 10,
    '& > div': {
        '& + div': {
            marginTop: 7
        }
    },
    p: {
        marginTop: 25,
        [theme.breakpoints.down('md')]: {
            marginTop: 23
        }
    },
    '& > .MuiGrid-root': {
        ' &:first-child': {
            p: {
                marginTop: 0
            }
        }
    }
})

export const OutputText = styled(Box)(({ spanorange, spanpurple }) => ({
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start !important',
    maxWidth: '95%',
    overflowY: 'auto',
    flex: ' 1 1 0',
    wordBreak: 'break-word',
    '& .MuiBox-root': {
        '&:not(:last-child)': {
            marginBottom: 25
        },
        '&:last-child': {
            marginBottom: 15
        }
    },
    h5: {
        textAlign: 'center',
        marginBottom: 10,
        fontSize: 18,
        '&+p': {
            border: 'none',
            margin: 0,
            padding: 0
        }
    },
    p: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 300,
        borderTopWidth: 1,
        borderColor: customColor.BlueGray,
        borderTopStyle: 'solid',
        paddingTop: 15,
        marginTop: 15,
        span: {
            fontWeight: 700,
            color: spanorange
                ? customColor.DgGen
                : spanpurple
                    ? customColor.Purple
                    : ''
        },
        '&:first-child': {
            border: 'none',
            margin: 0,
            padding: 0
        }
    }
}))

export const SfLabel = styled('span')({
    fontSize: 16,
    fontWeight: 300,
    display: 'inline-block',
    marginTop: 2,
    lineHeight: 'normal',
    color: customColor.Black
})

export const AnsBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
})

export const AnsTotal = styled('span')({
    fontSize: 16,
    fontWeight: 700,
    color: customColor.Black
})

export const Userfooter = styled(Box)({
    marginTop: 0,
    padding: '70px 8px 0',
    marginBottom: 32,
    '.MuiAvatar-colorDefault': {
        backgroundColor: customColor.DataProtected3
    },
    svg: {
        color: customColor.White
    },
    '.MuiListItemText-primary': {
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    '.MuiButtonBase-root': {
        borderColor: customColor.BlueGray,
        width: '100%',
        borderRadius: 8,
        paddingTop: 10,
        paddingBottom: 10,
        svg: {
            color: customColor.Gray,
            fontSize: 32
        },
        '.MuiButton-label': {
            '.MuiListItemText-root': {
                color: customColor.Gray,
                fontSize: 18,
                fontStyle: 'normal',
                fontWeight: '600',
                flex: '0 0 auto'
            }
        }
    }
})
