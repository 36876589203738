import { Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import {
    Divider,
    Wrapper,
    usePrintLayout,
    useUserOptions
} from 'components'
import {
    PieChart
} from 'components/charts'

import { useReactToPrint } from 'react-to-print'
import { useSelector } from 'react-redux'
import Loader from 'components/loader'

const useStyles = makeStyles({
    pageHeaderContainer: {
        textAlign: 'center',
        padding: '2rem'
    },
    dividerResponses: {
        borderColor: ({ palette }) => palette?.dividerResponses
    },
    overviewCharts: {
        marginTop: 12
    },
    printBarMainContainer: {
        marginTop: '3cm',
        padding: '0px 40px'
    },
    printBreakPage: {
        margin: 'auto',
        maxWidth: 800,
        height: 500,
        pageBreakBefore: 'always'
    },
    drilldownTitleContainer: {
        textAlign: 'center',
        margin: '30px 0px'
    },
    drilldownTitle: {
        color: ({ palette }) => palette?.text
    },
    oneDrilldownChartLayout: {
        width: 1280
    },
    printIcon: {
        color: ({ palette }) => palette?.printIcon
    },
    printTitle: {
        textAlign: 'center',
        marginBottom: 16
    },
})

function ContainerWrapper({ isPrinting, children, className }) {
    if (!isPrinting) {
        return (
            <Grid item container xs={12} spacing={3} className={className}>
                {children}
            </Grid>
        )
    }
    return children
}

function SectionWrapper({ isPrinting, children }) {
    const classes = useStyles()
    if (isPrinting) {
        return (
            <div className={classes.printBarMainContainer}>
                <div className={classes.printBreakPage}>{children}</div>
            </div>
        )
    }

    return (
        <Grid item xs={12} md={4}>
            {children}
        </Grid>
    )
}

function ChartsRowsWrapper({ isPrinting, children }) {
    const wrapChildWithContainer = React.useCallback(
        (child, index) =>
            child !== null && (
                <div key={index} className={classes.printBarMainContainer}>
                    <div key={index} className={classes.printBreakPage}>
                        {child}
                    </div>
                </div>
            ),
        [children]
    )

    const classes = useStyles()
    if (isPrinting) {
        return React.Children.map(children, wrapChildWithContainer)
    }

    return children
}

function DrilldownChartsRowsWrapper({ isPrinting, children, stats }) {
    const selectedCharts = children.filter(x => !!x)
    const classes = useStyles()
    if (isPrinting) {
        return (
            <div className={classes.printBarMainContainer}>
                {selectedCharts.map((obj, index) =>
                    obj.props.children.props.result.map(x => (
                        <div
                            key={`${index}-${obj.caption}`}
                            className={classes.printBreakPage}
                        >
                            <Typography className={classes.printTitle}>
                                {obj.props.children.props.title}
                            </Typography>
                            <PieChart
                                data={{
                                    ...obj.props.children.props.chartData
                                        .diversity.drilldowns_data[x][
                                    obj.props.children.props.name
                                    ],
                                    labels:
                                        obj.props.children.props.chartData
                                            .diversity.drilldowns_data[x][
                                            obj.props.children.props.name
                                        ].labels ||
                                        stats.diversity.pieData[
                                            obj.props.children.props.name
                                        ].labels
                                }}
                            />
                        </div>
                    ))
                )}
            </div>
        )
    }

    return children
}

function RenderDrilldownChart({ name, option, chartData, isOnlyOneChart }) {
    const classes = useStyles()
    const diversityData = chartData.diversity.drilldowns_data[option][name]

    if (!diversityData) {
        return null
    }
    const data = {
        ...diversityData,
        labels:
            diversityData.labels || chartData.diversity.pieData[name].labels,
        captionFontColor:
            diversityData.captionFontColor ||
            chartData.diversity.pieData[name].captionFontColor
    }

    return (
        <PieChart
            data={data}
            rootClass={isOnlyOneChart && classes.oneDrilldownChartLayout}
        />
    )
}

function ShowMainChart({ name, chartData, isOnlyOneChart, index, isPrinting }) {
    const classes = useStyles()
    const diversityData = chartData.diversity.pieData[name]

    return (
        <Grid
            key={index}
            item
            container={isOnlyOneChart}
            justify="center"
            xs={isOnlyOneChart || isPrinting ? 12 : diversityData.width}
        >
            <PieChart
                data={diversityData}
                rootClass={isOnlyOneChart && classes.oneDrilldownChartLayout}
            />
        </Grid>
    )
}

function ShowDrillDownChart({
    result,
    title,
    name,
    chartData,
    isOnlyOneChart,
    isPrinting
}) {
    const classes = useStyles({
        palette: {
            text: chartData.diversity.drilldowns_data.headersPalette[name]?.text,
            dividerResponses:
                chartData.diversity.drilldowns_data.headersPalette[name]?.divider
        }
    })

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} className={classes.drilldownTitleContainer}>
                <Typography variant="h6" className={classes.drilldownTitle}>
                    {title}
                </Typography>
                <Divider className={classes.dividerResponses} />
            </Grid>
            {result.map((option, index) => (
                <Grid
                    key={index}
                    item
                    container={isOnlyOneChart}
                    justify="center"
                    xs={isOnlyOneChart || isPrinting ? 12 : 6}
                >
                    <RenderDrilldownChart
                        name={name}
                        option={option}
                        chartData={chartData}
                        isOnlyOneChart={isOnlyOneChart}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default function Index() {
    const { options } = useUserOptions()
    const { isPrinting, setIsPrinting, renderedCharts } = usePrintLayout()
    const { brandingData } = useSelector((state) => state.branding)
    const { tenantStatsData: statsData, isLoadingTenantStats: isLoadingStats } = useSelector((state) => state.tenantStats)
    const componentRef = React.useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setIsPrinting(false)
    })

    const onPrint = React.useCallback(() => {
        setIsPrinting(true)
    }, [])

    React.useEffect(() => {
        if (isPrinting) {
            setTimeout(() => {
                handlePrint()
            }, 1)
        }
    }, [renderedCharts])

    const renderMainCharts = React.useMemo(() => {
        const isOnlyOneChart = options.filters.length === 1

        return statsData.diversity.filters.map(
            (x, index) =>
                (options.filters.length === 0 ||
                    options.filters.indexOf(x.key) !== -1) && (
                    <ShowMainChart
                        name={x.key}
                        chartData={statsData}
                        isOnlyOneChart={isOnlyOneChart}
                        index={index}
                        isPrinting={isPrinting}
                    />
                )
        )
    }, [options])

    const renderDrilldownCharts = React.useMemo(() => {

        const matchChoices = options.regions
            .map(region =>
                options.countries.map(
                    country => country.includes(region) && country
                )
            )
            .flat()

        const matched = matchChoices.filter(x => !!x)

        const result = [...matched, ...options.regions]

        const isOnlyOneChart =
            options.regions.length === 1 && matched.length === 0

        return statsData.diversity.filters.map(
            (x, index) =>
                (options.filters.length === 0 ||
                    options.filters.indexOf(x.key) !== -1) && (
                    <div key={index}>
                        <ShowDrillDownChart
                            title={x.label}
                            name={x.key}
                            chartData={statsData}
                            result={result}
                            isOnlyOneChart={isOnlyOneChart}
                            isPrinting={isPrinting}
                        />
                    </div>
                )
        )
    }, [options])

    const classes = useStyles({
        palette: brandingData.pages.diversity
    })

    if (isLoadingStats) {
        return <Loader />
    }


    return (
        <div ref={componentRef}>
            {statsData.diversity.hide ? <Wrapper
                isPrinting={isPrinting}
                handlePrint={onPrint}
                title="Diversity Feedback"
                variant="h5"
                palette={{
                    title: brandingData.pages.inclusionFeedback.pageHeaderText
                }}
            >
                No diversity results are available at this time.
            </Wrapper> : <>

                <div id="#experience-container"></div>

            </>}
        </div>
    )
}