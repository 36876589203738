import isArray from 'lodash/isArray'
import isDate from 'lodash/isDate'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import React from 'react'

//eslint-disable-next-line
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//eslint-disable-next-line
const usPhoneRegex = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/
//eslint-disable-next-line
const defaultPasswordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/

const validatorFunctions = {
    required: function ({ value }) {
        if (!value) {
            return false
        }

        if (isString(value)) {
            return value.trim() !== ''
        }

        if(isArray(value)) {
            return value.length > 0
        }

        return true
    },
    email: function ({ value }) {
        return !value || value.trim() === '' || emailRegex.test(value)
    },
    'us-phone': function ({ value }) {
        return !value || value.trim() === '' || usPhoneRegex.test(value)
    },
    password: function ({
        value,
        options: { regex } = {
            regex: defaultPasswordRegex
        }
    }) {
        if (!isString(value)) {
            return false
        }

        value = value.trim()

        return regex.test(value)
    },
    date: function ({ value }) {
        if (isString(value)) {
            return !Number.isNaN(new Date(value).getTime())
        }

        if (isDate(value)) {
            return !Number.isNaN(value.getTime())
        }

        return false
    },
    number: function ({ value }) {
        return Number.isInteger(value)
    },
    object: function ({ value, context, validator }) {
        const { type, ...options } = validator
        if (type === 'length') {
            if (!isString(value)) {
                return false
            }

            if (options.minLength && value.length < validator.minLength) {
                return false
            }

            if (options.maxLength && value.length > validator.maxLength) {
                return false
            }

            return true
        }

        if (type === 'match') {
            return value === context[options.property]
        }

        if (type === 'mismatch') {
            return value !== context[options.property]
        }

        throw new Error(
            `Invalid type for object validator; acceptable types: length, match mismatch`
        )
    },
    function: function ({ validator, ...args }) {
        return validator(args)
    }
}

function validateField({ key, validators = [], value, context }) {
    if (!Array.isArray(validators)) {
        validators = [validators]
    }

    return validators.reduce((result, validator) => {
        const args = {
            validator,
            value,
            context
        }

        if (isFunction(validator)) {
            return result && validatorFunctions.function(args)
        }

        if (isObject(validator)) {
            return result && validatorFunctions.object(args)
        }

        if (isString(validator)) {
            const func = validatorFunctions[validator]

            if (!func) {
                throw new Error(
                    `Unknown string validator definition for '${key}': ${validator}`
                )
            }

            return result && func(args)
        }

        throw new Error(
            `Unknown validator definition for '${key}': ${validator}`
        )
    }, true)
}

function getErrors(schema, context, modifier) {
    const errors = {}

    let keys = Array.isArray(modifier) ? modifier : Object.keys(schema)

    if (isFunction(modifier)) {
        keys = modifier(keys)
    }

    for (const key of keys) {
        errors[key] = !validateField({
            key,
            validators: schema[key],
            value: context[key],
            context
        })
    }

    return errors
}

function anyErrors(errors) {
    return Object.values(errors).reduce((result, v) => result || v, false)
}

export default function useValidation(schema) {
    const [errors, setErrors] = React.useState({})

    const validate = React.useCallback(
        (context, modifier) => {
            const errors = getErrors(schema, context, modifier)
            setErrors(errors)
            return !anyErrors(errors)
        },
        [schema]
    )

    const reset = React.useCallback(() => setErrors({}), [])

    const ifError = React.useCallback(
        (key, returnValue) => {
            return !!errors[key] ? returnValue : null
        },
        [errors]
    )

    return {
        errors,
        validate,
        reset,
        ifError,
        anyErrors: anyErrors(errors)
    }
}
