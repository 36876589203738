import React from 'react'

import Chart from './Chart'
import ChartCard from './ChartCard'
import { multiplex } from '../formatting'

export default function InclusionFeedbackDiversityChart({ data }) {
    return React.useMemo(() => {
        const dataSource = {
            chart: {
                caption: data.caption,
                numbersuffix: '%',
                decimals: '0'
            },
            data: multiplex(data)
        }

        return (
            <ChartCard>
                <Chart type="doughnut2d" dataSource={dataSource} />
            </ChartCard>
        )
    }, [data])
}
