import React from 'react'

const UserOptionsContext = React.createContext()

export function UserOptionsContextProvider({ children }) {
    const [options, setOptions] = React.useState({
        filters: [],
        regions: [],
        countries: []
    })

    const value = React.useMemo(
        () => ({
            options,
            update: setOptions
        }),
        [options, setOptions]
    )

    return (
        <UserOptionsContext.Provider value={value}>
            {children}
        </UserOptionsContext.Provider>
    )
}

export function useUserOptions() {
    return React.useContext(UserOptionsContext)
}
