import {
    Button,
    DialogActions,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core'
import React from 'react'

import AdminModal from '../admin/AdminModal'

import copyToClipboard from 'copy-to-clipboard'

import {
    MailOutline as SendToIcon,
    FileCopyOutlined as CopyIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20
    },
    idContainer: {
        height: 96,
        border: '2px dashed rgb(0, 0, 0)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    id: {
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    textId: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14
        }
    },
    copyButtonContainer: {
        alignSelf: 'center',
        textAlign: 'end',
        paddingLeft: 24,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            paddingLeft: 0
        }
    },
    copyButton: {
        backgroundColor: 'transparent',
        color: 'black',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    sendButton: {
        color: '#fff'
    },
    closeButton: {
        color: '#ef8c25'
    },
    icon: {
        marginRight: 8
    }
}))

function ActionButtons({ close, spid }) {
    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Close
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        href="mailto://?subject=Your Survey&body=+ encodeURI(spid)"
                        variant="contained"
                        color="primary"
                        className={classes.sendButton}
                    >
                        <SendToIcon className={classes.icon} fontSize="small" />
                        Send
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function AddNewSpidModal(props, ref) {
    const [open, setOpen] = React.useState(false)
    const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)
    const [newSpid, setNewSpid] = React.useState('')

    const onCopyToClipboard = React.useCallback(
        e => {
            e.preventDefault()
            copyToClipboard(newSpid)
            setCopiedToClipboard(true)
        },
        [newSpid]
    )

    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: id => {
            setNewSpid(id)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal
            open={open}
            onClose={close}
            title="New Id has been created!"
        >
            <Grid container className={classes.root}>
                <Grid item container xs={12} className={classes.idContainer}>
                    <Grid item xs={12} sm={6} className={classes.id}>
                        <Typography className={classes.textId}>
                            {newSpid}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.copyButtonContainer}
                    >
                        <Button
                            onClick={onCopyToClipboard}
                            className={classes.copyButton}
                        >
                            <CopyIcon
                                className={classes.icon}
                                fontSize="small"
                            />
                            Copy
                        </Button>
                        {copiedToClipboard && (
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Copied to clipboard!
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <ActionButtons spid={newSpid} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(AddNewSpidModal)
