import { Button, DialogActions, Grid, makeStyles } from '@material-ui/core'
import React from 'react'

import { useQueryClient } from 'react-query'

import { useDeactivateSpidMutation } from 'components/api'
import AdminModal from '../admin/AdminModal'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20
    },
    idContainer: {
        border: '2px dashed rgb(0, 0, 0)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: 20
    },
    buttonContainer: {
        paddingTop: 20,
        borderTop: '1px solid #dee2e6'
    },
    closeButton: {
        color: '#ef8c25'
    },
    disableButton: {
        color: '#fff'
    }
}))

function ActionButtons({ close, selectedId }) {
    const { mutateAsync: deactivateAsync } = useDeactivateSpidMutation()
    const queryClient = useQueryClient()

    const onDeactivateSpid = React.useCallback(
        async e => {
            e.preventDefault()
            try {
                await deactivateAsync({ sp_id: selectedId })

                await queryClient.invalidateQueries('spid-data')

                close()
            } catch (error) {
                throw error
            }
        },
        [selectedId, deactivateAsync, queryClient, close]
    )

    const classes = useStyles()
    return (
        <DialogActions>
            <Grid
                container
                className={classes.buttonContainer}
                justify="flex-end"
                spacing={2}
            >
                <Grid item>
                    <Button onClick={close} className={classes.closeButton}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onDeactivateSpid}
                        variant="contained"
                        color="primary"
                        className={classes.disableButton}
                    >
                        Disable
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

function DeactivateIdModal(props, ref) {
    const [open, setOpen] = React.useState(false)
    const [selectedId, setSelectedId] = React.useState('')
    const close = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useImperativeHandle(ref, () => ({
        open: id => {
            setSelectedId(id)
            setOpen(true)
        }
    }))

    const classes = useStyles()

    return (
        <AdminModal open={open} onClose={close} title="Deactivate ID?">
            <Grid container className={classes.root}>
                <Grid item xs={6} gutterBottom className={classes.idContainer}>
                    {selectedId}
                </Grid>

                <Grid item xs={12} gutterBottom>
                    Deactivating and ID will remove data from any reports for
                    the current period and will block the subject from making
                    further updates.
                </Grid>
            </Grid>
            <ActionButtons selectedId={selectedId} close={close} />
        </AdminModal>
    )
}

export default React.forwardRef(DeactivateIdModal)
