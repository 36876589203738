import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    Grid,
    ListItem,
    Radio,
    RadioGroup,
    Slide,
    Snackbar,
    Typography,
    makeStyles,
    useMediaQuery,
    TextField
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import React from 'react'

import { without } from 'lodash'

import {
    BackdropProgress,
    ProgressButton,
    Logo,
    useAuthentication,
    useValidation
} from 'components'

import {
    useSubmitSurveyMutation,
    useSaveSurveyMutation,
    useTenantQuery,
    useGetSurveyQuery
} from 'components/api'

import CheckBoxSelection from './CheckBoxSelection'

import Question from './Question'

import ThankYou from './ThankYou'

import styles from './styles'

const useStyles = makeStyles(styles)

function TransitionDown(props) {
    return <Slide {...props} direction="down" />
}

const checkBoxValues = {
    metrics: [
        { value: 'energy-usage', label: 'Energy usage' },
        { value: 'water-usage', label: 'Water usage' },
        {
            value: 'water-emissions',
            label: 'Hazardous / toxic air or water emissions'
        },
        {
            value: 'solid-waste',
            label: 'Generation / recycling / reduction of solid waste'
        },
        {
            value: 'hazardous-waste',
            label: 'Generation / recycling / reduction of hazardous waste'
        },
        { value: 'gas-emissions', label: 'Greenhouse gas emissions' },
        { value: 'none', label: 'None of the above' }
    ],
    achieved: [
        {
            value: 'renewable-energy',
            label: 'We use at least 10% renewable energy at our facilities'
        },
        {
            value: 'greenhouse',
            label:
                'We have reduced our greenhouse gas emissions or use of ozone-depleting substances by at least 10% in the past two years'
        },
        {
            value: 'initiatives',
            label:
                'We have implemented initiatives to reduce waste at the source or divert waste from landfills/incineration by at least 10% in the past two years'
        },
        {
            value: 'recycle-water',
            label:
                'We recycle water on site or use close-loop or other water recovery systems to reduce the use of potable water'
        },
        {
            value: 'dispose',
            label:
                'We responsibly dispose of all hazardous waste generated from production'
        },
        { value: 'none', label: 'None of the above' }
    ],
    employmentOpportunities: [
        { value: 'risk-youth', label: 'At-risk youth' },
        {
            value: 'formerly-incarcerated',
            label: 'Formerly incarcerated'
        },
        { value: 'homeless', label: 'Homeless' },
        { value: 'none', label: 'None of the above' },
        { value: 'other', label: 'Other' }
    ]
}

const defaultValues = {
    complyLaws: '',
    complyLawsDescription: '',
    codeEthics: '',
    enviromentalPolicy: '',
    positivePractises: '',
    positivePractisesDescription: '',
    metrics: [],
    achieved: [],
    thirdPartyProducts: '',
    thirdPartyCertification: '',
    chemicalConcernProducts: '',
    lowIncomeCommunity: '',
    lowIncomeCommunityDescription: '',
    employmentOpportunities: [],
    employmentOpportunitiesDescription: '',
    companyMajorityOwned: '',
    diversityOrInclusionProgram: '',
    overseeInclusionAndDiversity: '',
    name: '',
    companyName: ''
}

const snackBarOptions = {
    open: false,
    vertical: 'top',
    horizontal: 'center',
    transition: TransitionDown
}

export default function SurveyResponseModal() {
    const user = useAuthentication()
    const { data: tenantData } = useTenantQuery()
    const {
        data: surveyData,
        error,
        isLoading: getSurveyLoading
    } = useGetSurveyQuery(user.attributes['custom:vendor_id'])
    const {
        mutateAsync: submitSurveyAsync,
        isLoading: submitLoading
    } = useSubmitSurveyMutation()
    const {
        mutateAsync: saveSurveyAsync,
        isLoading: saveLoading
    } = useSaveSurveyMutation()

    const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('xs'))
    
    const [values, setValues] = React.useState(defaultValues)
    const [
        isSuccessfullySubmitted,
        setIsSuccessfullySubmitted
    ] = React.useState(false)

    const [open, setOpen] = React.useState(false)
    const { validate, errors, ifError, reset: resetValidation } = useValidation(
        {
            complyLaws: 'required',
            complyLawsDescription: function ({ value, context }) {
                if (context.complyLaws !== 'other') {
                    return true
                }

                return value && value.trim() !== ''
            },
            codeEthics: 'required',
            enviromentalPolicy: 'required',
            positivePractises: 'required',
            positivePractisesDescription: function ({ value, context }) {
                if (context.positivePractises !== 'yes') {
                    return true
                }

                return value && value.trim() !== ''
            },
            metrics: 'required',
            achieved: 'required',
            thirdPartyProducts: 'required',
            thirdPartyCertification: 'required',
            chemicalConcernProducts: 'required',
            lowIncomeCommunity: 'required',
            lowIncomeCommunityDescription: function ({ value, context }) {
                if (context.lowIncomeCommunity !== 'other') {
                    return true
                }

                return value && value.trim() !== ''
            },
            employmentOpportunities: 'required',
            employmentOpportunitiesDescription:
                values.employmentOpportunities.indexOf('other') !== -1
                    ? 'required'
                    : undefined,
            companyMajorityOwned: 'required',
            diversityOrInclusionProgram: 'required',
            overseeInclusionAndDiversity: 'required',
            name: 'required',
            companyName: 'required'
        }
    )

    const setValuesConditionaly = React.useCallback(
        (name, value) => {
            setValues({
                ...values,
                [name]: value,
                [name + 'Description']: ''
            })
            resetValidation()
        },
        [values]
    )

    const onChange = React.useCallback(
        e => {
            const value = e.target.value
            const name = e.target.name

            if (name === 'complyLaws' && value !== 'other') {
                setValuesConditionaly(name, value)
                return
            }

            if (name === 'positivePractises' && value !== 'yes') {
                setValuesConditionaly(name, value)
                return
            }

            if (name === 'lowIncomeCommunity' && value !== 'other') {
                setValuesConditionaly(name, value)
                return
            }

            setValues({
                ...values,
                [name]: value
            })
            resetValidation()
        },
        [values]
    )

    const onMultipleChange = React.useCallback(
        e => {
            const checked = e.target.checked
            const value = e.target.value
            const name = e.target.name

            if (checked) {
                values[name].push(value)
            } else {
                values[name] = without(values[name], value)
            }
            if (value === 'none') {
                values[name] = []
                values[name].push(value)
            }
            if (
                values[name].indexOf('none') !== -1 &&
                values[name].length > 1
            ) {
                values[name] = without(values[name], 'none')
            }

            if (name === 'employmentOpportunities' && value === 'none') {
                setValues((values.employmentOpportunitiesDescription = ''))
            }

            setValues({
                ...values
            })
            resetValidation()
        },

        [values]
    )

    const onSubmit = React.useCallback(
        async e => {
            e.preventDefault()

            if (!validate(values)) {
                return
            }

            try {
                await submitSurveyAsync({
                    vendor_id: user.attributes['custom:vendor_id'],
                    tenant_id: tenantData.tenant_id,
                    survey: values
                })
                setIsSuccessfullySubmitted(true)
            } catch (error) {
                throw error
            } finally {
            }
        },
        [values, submitSurveyAsync, validate, isSuccessfullySubmitted]
    )

    const onSave = React.useCallback(
        async e => {
            e.preventDefault()
            try {
                await saveSurveyAsync({
                    vendor_id: user.attributes['custom:vendor_id'],
                    survey: values
                })
                setOpen(true)
            } catch (error) {
                throw error
            }
        },
        [saveSurveyAsync, values]
    )

    const handleClose = React.useCallback(() => {
        setOpen(false)
    }, [])

    React.useEffect(() => {
        if (surveyData) {
            setValues(surveyData)
        }
    }, [surveyData])

    const { vertical, horizontal, transition } = snackBarOptions

    const classes = useStyles()

    if (getSurveyLoading) {
        return <BackdropProgress />
    }

    if (isSuccessfullySubmitted || (error && error.response.status === 410)) {
        return (
            <ThankYou
                values={defaultValues}
                alreadySubmitted={
                    error && error.response.status === 410 ? true : false
                }
            />
        )
    }

    return (
        <Dialog open={true} fullWidth>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={6000}
                onClose={handleClose}
                TransitionComponent={transition}
            >
                <Alert severity="success">Survey saved!</Alert>
            </Snackbar>

            <DialogTitle className={classes.title} disableTypography>
                <Logo condensed />
                <Typography variant="h5" gutterBottom>
                    <strong>Service Provider Questionnaire</strong>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    SafePorter conducts reviews of our suppliers once every two
                    years with regard to Corporate Social Responsibility.
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Question
                    title="Do you comply with all local laws and
                        regulations,including those related to social
                        environmental performance?"
                    required={true}
                />

                <RadioGroup
                    name="complyLaws"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio checked={values.complyLaws === 'yes'} />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio checked={values.complyLaws === 'no'} />
                            }
                            label="No"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="other"
                            control={
                                <Radio
                                    checked={values.complyLaws === 'other'}
                                />
                            }
                            label="Other:"
                        />
                        <TextField
                            name="complyLawsDescription"
                            onChange={onChange}
                            value={values.complyLawsDescription}
                            disabled={values.complyLaws !== 'other'}
                            className={classes.textfield}
                            error={
                                values.complyLaws === 'other' &&
                                errors.complyLawsDescription
                            }
                            helperText={
                                values.complyLaws === 'other' &&
                                ifError(
                                    'complyLawsDescription',
                                    'Please describe'
                                )
                            }
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('complyLaws', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Does your company have a code of ethics or code of
                    conduct?"
                    required={true}
                />

                <RadioGroup
                    name="codeEthics"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio checked={values.codeEthics === 'yes'} />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio checked={values.codeEthics === 'no'} />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('codeEthics', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Does your company have an environmental policy or
                    sustainability program?"
                    required={true}
                />

                <RadioGroup
                    name="enviromentalPolicy"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.enviromentalPolicy === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={values.enviromentalPolicy === 'no'}
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('enviromentalPolicy', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Does your company have any positive practices beyond
                    what is required by local regulations?"
                    required={true}
                />

                <RadioGroup
                    name="positivePractises"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={values.positivePractises === 'yes'}
                                />
                            }
                            label="Yes (Please Describe)"
                        />
                        <TextField
                            name="positivePractisesDescription"
                            value={values.positivePractisesDescription}
                            onChange={onChange}
                            disabled={values.positivePractises !== 'yes'}
                            className={classes.textfield}
                            error={
                                values.positivePractises === 'yes' &&
                                errors.positivePractisesDescription
                            }
                            helperText={
                                values.positivePractises === 'yes' &&
                                ifError(
                                    'positivePractisesDescription',
                                    'Please describe'
                                )
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={values.positivePractises === 'no'}
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('positivePractises', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do you track and report on any of the following metrics?"
                    required={true}
                    subtitle="(Please check all that apply)."
                />

                {checkBoxValues.metrics.map(value => (
                    <CheckBoxSelection
                        values={values.metrics}
                        onMultipleChange={onMultipleChange}
                        isSmallDevice={isSmallDevice}
                        value={value}
                        name="metrics"
                        key={value.value}
                    />
                ))}
                {ifError('metrics', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Have you achieved any of the following?"
                    required={true}
                    subtitle="(Please check all that apply)."
                />

                {checkBoxValues.achieved.map(value => (
                    <CheckBoxSelection
                        values={values.achieved}
                        onMultipleChange={onMultipleChange}
                        isSmallDevice={isSmallDevice}
                        value={value}
                        name="achieved"
                        key={value.value}
                    />
                ))}
                {ifError('achieved', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do any of the materials and/or products we purchase from
                    you have a third-party social or environmental
                    certification?"
                    required={true}
                    subtitle="Examples include Fair Trade,
                    Forest Stewardship Council, Marine Stewardship
                    Council,Cradle to Cradle, USDA Organic, non-GMO, etc."
                />

                <RadioGroup
                    name="thirdPartyProducts"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.thirdPartyProducts === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={values.thirdPartyProducts === 'no'}
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('thirdPartyProducts', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do you have a third-party social or environmental
                    certification at the company level?"
                    required={true}
                    subtitle="Examples
                    include B Corporation, JUST Label, California Green
                    Business, etc."
                />

                <RadioGroup
                    name="thirdPartyCertification"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.thirdPartyCertification === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={
                                        values.thirdPartyCertification === 'no'
                                    }
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('thirdPartyCertification', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do your materials and/or products contain any chemicals
                    of concern?"
                    required={true}
                    subtitle={
                        <>
                            Chemicals of concern include mercury, lead, flame
                            retardants, PCBs, chlorinated pesticides,dioxins,
                            and furans. A full list can be found{' '}
                            <a
                                href="http://www.ecy.wa.gov/programs/eap/toxics/chemicals_of_concern.html"
                                target="_blank"
                            >
                                here
                            </a>
                        </>
                    }
                />
                <RadioGroup
                    name="chemicalConcernProducts"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.chemicalConcernProducts === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={
                                        values.chemicalConcernProducts === 'no'
                                    }
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('chemicalConcernProducts', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Is your company located in a low-income community?"
                    required={true}
                    subtitle={
                        <>
                            Low-income is defined as a geographic area
                            (neighborhood,village, other region) where the
                            median family income is less than 80% of the median
                            family income of the surrounding vicinity. If you
                            are unsure, visit{' '}
                            <a
                                href="http://www.ffiec.gov/geocode"
                                target="_blank"
                            >
                                http://www.ffiec.gov/geocode
                            </a>{' '}
                            and enter your address (for suppliersbased in the
                            USA).
                        </>
                    }
                />

                <RadioGroup
                    name="lowIncomeCommunity"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.lowIncomeCommunity === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={values.lowIncomeCommunity === 'no'}
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="other"
                            control={
                                <Radio
                                    checked={
                                        values.lowIncomeCommunity === 'other'
                                    }
                                />
                            }
                            label="Other:"
                        />
                        <TextField
                            name="lowIncomeCommunityDescription"
                            value={values.lowIncomeCommunityDescription}
                            onChange={onChange}
                            disabled={values.lowIncomeCommunity !== 'other'}
                            className={classes.textfield}
                            error={
                                values.lowIncomeCommunity === 'other' &&
                                errors.lowIncomeCommunityDescription
                            }
                            helperText={
                                values.lowIncomeCommunity === 'other' &&
                                ifError(
                                    'lowIncomeCommunityDescription',
                                    'Please describe'
                                )
                            }
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('lowIncomeCommunity', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do you create employment opportunities for any of the
                    following chronically underemployed populations?"
                    required={true}
                    subtitle="(Please check all that apply)."
                />

                {checkBoxValues.employmentOpportunities.map(value => (
                    <CheckBoxSelection
                        values={values.employmentOpportunities}
                        textfieldValues={
                            values.employmentOpportunitiesDescription
                        }
                        onMultipleChange={onMultipleChange}
                        isSmallDevice={isSmallDevice}
                        value={value}
                        name="employmentOpportunities"
                        onChange={onChange}
                        errors={errors}
                        ifError={ifError}
                        classes={classes}
                        key={value.value}
                    />
                ))}

                {ifError('employmentOpportunities', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Is your company majority owned by women, people of
                    color, or individuals from other underrepresented
                    populations?"
                    required={true}
                />

                <RadioGroup
                    name="companyMajorityOwned"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.companyMajorityOwned === 'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={
                                        values.companyMajorityOwned === 'no'
                                    }
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError('companyMajorityOwned', 'Please select one') && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="Do you have a Diversity and Inclusion Program?"
                    required={true}
                />

                <RadioGroup
                    name="diversityOrInclusionProgram"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.diversityOrInclusionProgram ===
                                        'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={
                                        values.diversityOrInclusionProgram ===
                                        'no'
                                    }
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError(
                    'diversityOrInclusionProgram',
                    'Please select one'
                ) && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <Question
                    title="If yes, do you have someone whose full time job is to
                        oversee Inclusion and Diversity?"
                    required={true}
                />

                <RadioGroup
                    name="overseeInclusionAndDiversity"
                    value={values}
                    onChange={onChange}
                >
                    <ListItem>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    checked={
                                        values.overseeInclusionAndDiversity ===
                                        'yes'
                                    }
                                />
                            }
                            label="Yes"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    checked={
                                        values.overseeInclusionAndDiversity ===
                                        'no'
                                    }
                                />
                            }
                            label="No"
                        />
                    </ListItem>
                </RadioGroup>
                {ifError(
                    'overseeInclusionAndDiversity',
                    'Please select one'
                ) && (
                    <ListItem>
                        <FormHelperText error>Please select one</FormHelperText>
                    </ListItem>
                )}

                <br />
                <Grid container>
                    <Grid item xs={12}>
                        <ListItem>
                            <Typography variant="body1">Your Name: </Typography>
                            <TextField
                                name="name"
                                value={values.name}
                                onChange={onChange}
                                className={classes.textfield}
                                error={errors.name}
                                helperText={ifError(
                                    'name',
                                    'Please fill in your name'
                                )}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ListItem>
                            <Typography variant="body1">
                                Company Name:{' '}
                            </Typography>
                            <TextField
                                name="companyName"
                                value={values.companyName}
                                onChange={onChange}
                                className={classes.textfield}
                                error={errors.companyName}
                                helperText={ifError(
                                    'companyName',
                                    'Please fill in your company name'
                                )}
                            />
                        </ListItem>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid
                    container
                    className={classes.buttonContainer}
                    justify="flex-end"
                    spacing={2}
                >
                    <Grid item>
                        <ProgressButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.saveButton}
                            onClick={onSave}
                            isLoading={saveLoading}
                        >
                            Save
                        </ProgressButton>
                    </Grid>

                    <Grid item>
                        <ProgressButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            onClick={onSubmit}
                            isLoading={submitLoading}
                        >
                            Submit
                        </ProgressButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
