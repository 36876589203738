import React from 'react'
import {
    CardHeader,
    CardContent,
    IconButton,
    Typography,
    Box
} from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import { CardOne, OutputText } from 'assets/globalComponent'
import { Nodata } from 'assets/diversityStyle'

const InsightsMultiCard = data => {
    const insightData = []
    Object.values(data.data.selectedRegion).filter(mapItem => {
        if (
            mapItem[data.data.pupKey] &&
            mapItem[data.data.pupKey]?.insights?.length &&
            mapItem[data.data.pupKey]?.insights
        ) {
            insightData.push(mapItem[data.data.pupKey]?.insights)
            return mapItem[data.data.pupKey]?.insights
        }
        // eslint-disable-next-line no-unused-expressions
    })
    return (
        <CardOne redbdr minheight hasborder>
            <CardHeader
                action={
                    <IconButton>
                        <LaunchIcon />
                    </IconButton>
                }
                titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                title={
                    data?.title === 'Gender'
                        ? 'Gender Identity Insights and Sexual Orientation Insights'
                        : `${`${data?.title} Insights`}`
                }
            />
            <CardContent>
                <OutputText spanorange>
                    {insightData?.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        style={{ color: item[0].color }}
                                    >
                                        {item[0]?.label}
                                    </Typography>{' '}
                                    {/* {item.map((element, subIndex) => (
                                        <>
                                            <Typography>
                                                <span
                                                    style={{
                                                        color: element.color
                                                    }}
                                                >
                                                    {element.value}
                                                </span>{' '}
                                                {element.description}
                                            </Typography>
                                        </>
                                    ))} */}
                                </Box>
                            </React.Fragment>
                        )
                    })}
                    <Typography>
                        Regional insights not available due to privacy controls.
                    </Typography>
                    {insightData.length === 0 && (
                        <Nodata><Typography>Not applicable</Typography></Nodata>
                    )}
                </OutputText>
            </CardContent>
        </CardOne>
    )
}

export default InsightsMultiCard
