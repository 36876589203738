import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const defaultTheme = createTheme()

const theme = createTheme({
    palette: {
        primary: {
            main: '#70d9b8'
        },
        secondary: {
            main: '#ef8c25'
        },
        error: {
            main: red.A400
        },
        background: {
            default: '#fff'
        },
        info: {
            main: 'rgb(250, 108, 81)'
        }
    },
    typography: {
        h5: {
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: 18
            }
        },
        h6: {
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: 18
            }
        }
    },
    overrides: {
        MuiButton: {
            root: {
                color: '#fff',
                textTransform: 'none'
            }
        }
    }
})

export default theme
