import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core"
import { send_command } from "components/api"
import { useState } from "react"
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight"
export const SendCommand = ({ closeHandler }) => {
    const [payload, setPayload] = useState("{}")
    const [command, setCommand] = useState("")
    const [formatted, setFormatted] = useState(JSON.stringify({ "type": "", "payload": {} }, null, 3))
    const [parseError, setParseError] = useState(" ")
    const [response, setResponse] = useState()

    const [fullWidth, setFullWidth] = useState(false)
    const changeFormated = (command, payload) => {
        try {
            const command_body = { "type": command, "payload": JSON.parse(payload) }
            console.error({ command_body })
            setFormatted(JSON.stringify(command_body, null, 3))
            setParseError(" ")
        } catch (e) {
            setParseError(e.message)
        }
    }

    const onChangeCommand = (e) => {
        setCommand(e.target.value.toUpperCase())
        changeFormated(e.target.value.toUpperCase(), payload)
    }
    const onChangePayload = (e) => {
        setPayload(e.target.value)
        changeFormated(command, e.target.value)
    }

    const send = () => {
        setResponse("Loading...")
        console.error({ payload: JSON.parse(payload) })
        send_command(command, JSON.parse(payload))
            .then(res => {
                console.error({ res })
                setResponse(JSON.stringify(res, null, 3))
            }
            ).catch((ex) => {
                console.error({ ex })
                setResponse(`${ex?.response?.status}: ${ex?.response?.data}`)
            })
    }

    return (
        <Dialog open onClose={closeHandler} fullWidth maxWidth={"xl"}>            
            <DialogActions>
                <Button variant="contained" color="primary" onClick={closeHandler}>Close</Button>                
            </DialogActions>
            <DialogTitle>Send Backend Commands</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>

                            <Typography>
                                Use this tool to issue commands to the back end. This uses the current user to
                                execute commands. For superadmin commands, the tenant is usually provided as a
                                part of the payload. Issue an <b style={{fontFamily: 'monospace'}}>LIST_COMMANDS</b> command to see the available commands
                            </Typography>
                    </Grid>
                    <Grid item xs={6} maxwidth spacing={2}>
                        <Grid item xs={12} >
                            <TextField fullWidth label={"COMMAND"} onChange={onChangeCommand} value={command} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth multiline label={"Payload"} value={payload} onChange={onChangePayload} />
                        </Grid>
                    </Grid>
                    <Grid item maxwidth style={{ width: "500px" }} xs={6}>
                        <Grid container>
                            <Grid item xs={12} style={{ color: "red" }}>
                                {parseError}
                            </Grid>
                            <Grid item xs={12} >
                                <SyntaxHighlighter language="json" >
                                    {formatted}
                                </SyntaxHighlighter>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Button autoFocus variant="contained" color="primary" onClick={send}>Send</Button>&nbsp;
                        <Button variant="contained" color="primary" onClick={() => {navigator.clipboard.writeText(response)}}>copy</Button>&nbsp;
                    </Grid>
                    <Grid xs={12}>
                        <Grid item xs={12} style={{ height: "500px", overflowY: "scroll" }}>
                            <SyntaxHighlighter language="json" >
                                {response}
                            </SyntaxHighlighter>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}