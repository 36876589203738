import React, { useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import {
    countrySelectedFilters,
    regionSelectedFilters,
    setSelectedFilters
} from 'redux/slices/tenantStatsSlice'

const FilterCheckbox = () => {
    const { tenantStatsData: statsData, drilldownsHierarchy } = useSelector(
        state => state.tenantStats
    )
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedCountries, setSelectedCountries] = useState([])
    const [expandedAccordion, setExpandedAccordion] = useState(1)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [regionNoCountries, setRegionNoCountries] = useState([])

    const dispatch = useDispatch()

    const handleAccordionChange = panel => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false)
    }

    const handleOptionChange = (event, isRegion) => {
        const value = event.target.value

        if (isRegion === 'filter') {
            const currentIndex = selectedOptions.indexOf(value)
            const newSelected = [...selectedOptions]

            if (currentIndex === -1) {
                newSelected.push(value)
            } else {
                newSelected.splice(currentIndex, 1)
            }

            setSelectedOptions(newSelected)
            dispatch(
                setSelectedFilters({
                    filter: newSelected,
                    pieData: statsData.diversity.pieData,
                    declinedAnswerData: statsData.diversity.declinedAnswers,
                    employeeDiversityData:
                        statsData.diversity.employeeDiversity,
                    hiringDiversityData: statsData.diversity.hiringDiversity
                })
            )
        } else {
            if (isRegion) {
                // Handle selecting or deselecting a region
                const newSelected = selectedRegions.includes(value)
                    ? selectedRegions.filter(region => region !== value)
                    : [...selectedRegions, value]

                setSelectedRegions(newSelected)
                const region = drilldownsHierarchy.find(
                    region => region.key === value
                )

                let regionCountryKeys = []
                let regionNoCountries = []
                for (let index = 0; index < newSelected.length; index++) {
                    const re = drilldownsHierarchy.find(
                        item => item.key === newSelected[index]
                    )

                    if (re?.children?.length > 0) {
                        regionCountryKeys = [
                            ...regionCountryKeys,
                            ...re.children
                        ]
                    } else {
                        regionNoCountries = [
                            ...regionNoCountries,
                            newSelected[index]
                        ]
                    }
                }
                if (event.target.checked) {
                    setSelectedCountries([...regionCountryKeys.map(i => i.key)])

                    dispatch(
                        regionSelectedFilters({
                            region: region.key,
                            country: regionCountryKeys,
                            regionNoCountries,
                            drilldownsData: statsData.diversity.drilldowns_data
                        })
                    )
                } else {
                    const countries = selectedCountries.filter(
                        country => !region.children.find(i => i.key === country)
                    )
                    setSelectedCountries(countries)
                    dispatch(
                        regionSelectedFilters({
                            region: region.key,
                            country: regionCountryKeys,
                            regionNoCountries,
                            drilldownsData: statsData.diversity.drilldowns_data
                        })
                    )
                }
                setRegionNoCountries(regionNoCountries)
            } else {
                // Handle selecting or deselecting a country
                const newSelected = selectedCountries.includes(value)
                    ? selectedCountries.filter(
                        countryKey => countryKey !== value
                    )
                    : [...selectedCountries, value]
                setSelectedCountries(newSelected)

                // Determine the region of the selected country
                const country = drilldownsHierarchy
                    .flatMap(region => region.children)
                    .find(country => country.key === value)

                if (country) {
                    const regionKey = country.key.split('_')[0] // Extract the region key
                    // Check if all countries in the region are deselected, then deselect the region
                    const allRegionCountriesDeselected = drilldownsHierarchy
                        .find(region => region.key === regionKey)
                        .children.every(
                            country => !newSelected.includes(country.key)
                        )

                    setSelectedRegions(prevSelectedRegions => {
                        if (allRegionCountriesDeselected) {
                            return prevSelectedRegions.filter(
                                region => region !== regionKey
                            )
                        } else if (
                            newSelected.includes(value) &&
                            !prevSelectedRegions.includes(regionKey)
                        ) {
                            return [...prevSelectedRegions, regionKey]
                        }
                        return prevSelectedRegions
                    })
                    dispatch(
                        countrySelectedFilters({
                            country: newSelected,
                            region: regionKey,
                            regionNoCountries,
                            drilldownsData: statsData.diversity.drilldowns_data
                        })
                    )
                }
            }
        }
    }

    return (
        <div>
            <Accordion
                expanded={expandedAccordion === 1}
                onChange={handleAccordionChange(1)}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Filter by Topic
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <FormGroup>
                            {statsData?.diversity?.filters.map(filter => (
                                <FormControlLabel
                                    className="StaticColor"
                                    color="#3D9D83"
                                    key={filter.key}
                                    control={
                                        <Checkbox
                                            checked={
                                                selectedOptions.indexOf(
                                                    filter.key
                                                ) > -1
                                            }
                                            onChange={event =>
                                                handleOptionChange(
                                                    event,
                                                    'filter'
                                                )
                                            }
                                            value={filter.key}
                                        />
                                    }
                                    label={filter.label}
                                />
                            ))}
                        </FormGroup>
                    </FormGroup>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedAccordion === 2}
                onChange={handleAccordionChange(2)}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Filter by Region</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        {drilldownsHierarchy.map(region => (
                            <FormControlLabel
                                // className='StaticColor'
                                key={region.key}
                                control={
                                    <Checkbox
                                        style={{ color: region.color }}
                                        checked={selectedRegions.includes(
                                            region.key
                                        )}
                                        onChange={event =>
                                            handleOptionChange(event, true)
                                        }
                                        value={region.key}
                                    />
                                }
                                label={region.label}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedAccordion === 3}
                onChange={handleAccordionChange(3)}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Filter by Country</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        {drilldownsHierarchy
                            .flatMap(region => region.children)
                            .map(country => (
                                <FormControlLabel
                                    key={country.key}
                                    className="staticColor"
                                    control={
                                        <Checkbox
                                            style={{ color: country.color }}
                                            checked={selectedCountries.includes(
                                                country.key
                                            )}
                                            onChange={event =>
                                                handleOptionChange(event, false)
                                            }
                                            value={country.key}
                                        />
                                    }
                                    label={country.label}
                                />
                            ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default FilterCheckbox
