import { Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import {
    BackdropProgress,
    PageHeader,
    Wrapper,
    usePrintLayout
} from 'components'
import {
    VendorAssignedSurveyResponseTimeChart,
    VendorSurveysSummary
} from 'components/charts'
import { useReactToPrint } from 'react-to-print'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    container: {
        height: 750
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    leftChart: {
        flexGrow: 1
    },
    rightContainer: {
        padding: 15,
        backgroundColor: ({ palette }) => palette?.rightContainer
    },
    bottomText: {
        textAlign: 'center',
        fontWeight: 'unset'
    },
    diversityScore: {
        color: ({ palette }) => palette?.diversityScore
    },
    printVendorAssignedSurveyResponseContainer: {
        marginTop: '2cm',
        padding: '0px 40px'
    },
    printVendorSurveysSummaryContainer: {
        marginTop: '0.5cm',
        padding: '0px 40px'
    },
    printBreakPage: {
        margin: 'auto',
        maxWidth: 800,
        height: 600,
        pageBreakBefore: 'always'
    },
    printButtonContainer: {
        marginBottom: 20
    }
})

function SectionWrapper({ isPrinting, md, children, className }) {
    const classes = useStyles()
    if (isPrinting) {
        return (
            <div className={className}>
                <div className={classes.printBreakPage}>{children}</div>
            </div>
        )
    }

    return (
        <Grid item xs={12} md={md} className={className}>
            {children}
        </Grid>
    )
}

const VendorSurveys = () => {
    const { brandingData, isLoadingBranding } = useSelector((state) => state.branding)
    const { tenantStatsData: statsData, isLoadingTenantStats: isLoadingStats } = useSelector((state) => state.tenantStats)

    const { isPrinting, setIsPrinting, renderedCharts } = usePrintLayout()

    const componentRef = React.useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setIsPrinting(false)
    })

    const onPrint = React.useCallback(() => {
        setIsPrinting(true)
    }, [])

    React.useEffect(() => {
        if (isPrinting && renderedCharts.length === 2) {
            setTimeout(() => {
                handlePrint()
            }, 1)
        }
    }, [renderedCharts])

    const classes = useStyles({ palette: brandingData.pages.vendorSurvey })

    if (isLoadingStats || isLoadingBranding) {
        return <BackdropProgress />
    }
    const vendorDataReady = ('vendor' in statsData) && (Object.keys(statsData.vendor).length > 0)
    return (
        <div ref={componentRef}>
            {!vendorDataReady&&

            <Wrapper
                isPrinting={isPrinting}
                handlePrint={onPrint}
                title="Vendor Feedback"
                variant="h5"
                palette={{
                    title: brandingData.pages.inclusionFeedback.pageHeaderText
                }}
            >              
                No vendor survey data has been processed at this time.
            </Wrapper>
            }
            {vendorDataReady&&<Wrapper
                isPrinting={isPrinting}
                handlePrint={onPrint}
                palette={brandingData.pages.vendorSurvey}
                printIcon
            >
                <SectionWrapper
                    isPrinting={isPrinting}
                    md={7}
                    className={
                        isPrinting
                            ? classes.printVendorAssignedSurveyResponseContainer
                            : classes.leftContainer
                    }
                >
                    {!isPrinting ? (
                        <>
                            <PageHeader
                                title=" AGGREGATE VENDOR SURVEY RESULTS"
                                palette={{
                                    title: brandingData.pages.vendorSurvey
                                        .pageHeaderText,
                                    divider:
                                        brandingData.pages.vendorSurvey.divider
                                }}
                                hasDivider
                            />
                            <div className={classes.leftChart}>
                                <VendorAssignedSurveyResponseTimeChart
                                    data={
                                        statsData.vendor.vendorAssignedSurveyResponseTime
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        <VendorAssignedSurveyResponseTimeChart
                            data={statsData.vendor.vendorAssignedSurveyResponseTime}
                        />
                    )}

                    <Typography variant="h6" className={classes.bottomText}>
                        Average CSR Score of our Vendors:{' '}
                        <span className={classes.diversityScore}>
                            {statsData.vendor.vendors.csrScore}
                        </span>
                    </Typography>
                </SectionWrapper>
                <SectionWrapper
                    isPrinting={isPrinting}
                    md={5}
                    className={
                        isPrinting
                            ? classes.printVendorSurveysSummaryContainer
                            : classes.rightContainer
                    }
                >
                    <VendorSurveysSummary
                        data={statsData.vendor.vendors}
                        supplierOverviewData={statsData.vendor.vendorSupplierOverview}
                    />
                </SectionWrapper>
            </Wrapper>}
        </div>
    )
}
export default VendorSurveys
