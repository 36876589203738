const styles = theme => ({
    title: {
        textAlign: 'center',
        padding: '50px 50px 25px 50px',
        [theme.breakpoints.down('xs')]: {
            padding: 20
        }
    },
    content: {
        padding: '16px 24px'
    },
    textfield: {
        verticalAlign: 'bottom',
        paddingLeft: 8,
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px 8px 0px'
        }
    },
    buttonContainer: {
        paddingTop: 20
    },
    closeButton: {
        color: '#ef8c25'
    },
    submitButton: {
        color: '#fff'
    },
    saveButton: {
        color: theme.palette.primary.main,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }
    }
})

export default styles